<template>
  <div class="modal fade" id="modal-default" ref="storeMaterialAddModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">เพิ่มสินค้า(เพิ่มเข้า)</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>Supplier</label>
                  <select class="select form-control" v-model="formData.supplier" @change="selectsupplier">
                    <option v-for="(item) in selectSupplier">{{ item }}</option>
                  </select>
                  <span class="text-danger">{{ validateResult.supplier }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>รหัสสินค้า</label>
                  <select class="select form-control" v-model="formData.mtcode" :disabled="!formData.supplier">
                    <option v-for="(item) in selectMaterialCode" :value="item.seq">{{ item['materialName'] }}</option>
                  </select>
                  <span class="text-danger">{{ validateResult.mtcode }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>เลข PO</label>
                  <input type="text" v-model="formData.po" class="form-control" placeholder="เลข PO">
                  <span class="text-danger">{{ validateResult.po }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>หน้ากว้าง</label>
                  <input
                      type="text"
                      v-model="formData.width"
                      class="form-control"
                      placeholder="หน้ากว้าง"
                      @input="handleInput($event,'width')"
                  >
                  <span class="text-danger">{{ validateResult.width }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>ความยาว(รวม)</label>
                  <input
                      type="text"
                      v-model="formData.length"
                      class="form-control"
                      placeholder="ความยาว(รวม)"
                      @input="handleInput($event,'length')"
                  >
                  <span class="text-danger">{{ validateResult.length }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>ตำแหน่งจัดเก็บ</label>
                  <select class="select form-control" v-model="formData.location">
                    <option value="">ตำแหน่งจัดเก็บ</option>
                    <option v-for="(item) in selectLocation" :value="item.seq">{{ item['slName'] }}</option>
                  </select>
                  <span class="text-danger">{{ validateResult.location }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-success float-right">เพิ่มเข้า</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
    
<script>
import loginStore from "@/store/loginStore";
import * as Yup from "yup";
import masterStore from "@/store/MasterStore";
import storeMaterialStore from "@/store/storeMaterialStore";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "StoreMaterialAddModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        selectHolidayYear: null,
        selectSupplier: null,
        selectLocation: null,
        selectMaterialCode: null,
        selectmtcode: '',
        token: loginStore.getters.token,
        validateResult: {
          supplier: '',
          mtcode: '',
          po: '',
          width: '',
          length: '',
          location: '',
        },
        formData: {
          type: '',
          supplier: '',
          mtcode: '',
          po: '',
          width: '',
          length: '',
          location: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        supplier: Yup.string().required('supplier ห้ามเป็นค่าว่าง'),
        mtcode: Yup.string().required('รหัสสินค้าห้ามเป็นค่าว่าง'),
        po: Yup.string().required('เลข PO ห้ามเป็นค่าว่าง'),
        width: Yup.string().required('หน้ากว้างห้ามเป็นค่าว่าง'),
        length: Yup.string().required('ความยาวห้ามเป็นค่าว่าง'),
        location: Yup.string().required('ตำแหน่งจัดเก็บห้ามเป็นค่าว่าง'),
      });
    }
  },
  mounted() {
    try {
      this.token = loginStore.getters.token
      this.getSelectSupplier()
      this.getSelectLocation()
      this.getSelectMtCode()
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.type = this.item['type'];
            this.formData.seq = this.item['seq'];
            this.formData.supplier = this.item['supplier'];
            this.formData.mtcode = this.item['mtcode'];
            this.formData.po = this.item['po'];
            this.formData.width = this.item['width'];
            this.formData.length = this.item['length'];
            this.formData.location = this.item['location'];
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    },
  },
  methods: {
    handleInput(event,formData) {
      const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
      const match = sanitizedValue.match(/(\d*\.\d{0,2}|\d*)/);
      this.formData[formData] = match ? match[0] : '';
    },
    handleModal() {
      try {
        $(this.$refs.storeMaterialAddModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    selectsupplier() {
      this.getSelectMtCode(this.formData.supplier)
    },
    async submitForm() {
      try {
        this.formSchema.validate(this.formData, { abortEarly: false }).then(async () => {
          this.isLoading = true;
          const token = this.token
          const payload = {
            siSupplier: this.formData.supplier,
            materialSeq: this.formData.mtcode,
            siPo: this.formData.po,
            siWidth: parseFloat(this.formData.width),
            siLength: parseFloat(this.formData.length),
            slSeq: this.formData.location,
            status: '1'
          }
          await storeMaterialStore.dispatch('storeIn', { token, payload })
          const result = storeMaterialStore.getters.saveResult
          this.isLoading = false;
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
        }
        ).catch((err) => {
          this.isLoading = false;
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    async getSelectSupplier() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('storeIn', payload)
        this.selectSupplier = masterStore.getters.supplierForm

      } catch (e) {
        throw new Error(e);
      }
    },
    async getSelectLocation() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('storeIn', payload)
        this.selectLocation = masterStore.getters.storeLocation

      } catch (e) {
        throw new Error(e);
      }
    },
    async getSelectMtCode(sup) {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('storeIn', payload)
        this.selectMaterialCode = []
        const results = masterStore.getters.materialCodeForm
        results.forEach((item, index) => {
          if (item.supplier === sup) {
            item.materialLst.forEach((item, index) => {
              this.selectMaterialCode.push({ seq: item.seq, materialName: item.materialName })
            })
          }
        })

      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.validateResult = {
          supplier: '',
          mtcode: '',
          po: '',
          width: '',
          length: '',
          location: '',
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.storeMaterialAddModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = storeMaterialStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel(){
      $(this.$refs.storeMaterialAddModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>
    
<style scoped></style>
    