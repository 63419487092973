<template>
  <content>
    <div class="content-header">
      <div class="container-fluid">
        <FullCalender item="resourceTimelineWeek" page="Resource View" option="r"/>

      </div>
    </div>
  </content>
</template>

<script>

import Content from "@/components/Content.vue";
import FullCalender from "@/components/resourceCalendar/FullCalender.vue";

export default {
  name: "ResourceView",
  components: {FullCalender, Content},
};
</script>

<style scoped>
</style>
