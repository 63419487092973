<template>
    <SaveProdViewCont v-if="!itemContent" @saveProd-content="handleEmitSaveCont" />
    <SaveProdContent v-if="itemContent" :item="itemContent" @saveProd-content-view="handleEmitSaveContView" />
</template>

<script>
import Content from "@/components/Content.vue";
import Header from "@/components/header/Header.vue";
import SaveProdViewCont from "@/components/saveProd/SaveProdViewComponent.vue";
import SaveProdContent from "@/components/saveProd/SaveProdContent.vue";

export default {
  name: "SaveprodView",
  components: { SaveProdContent, SaveProdViewCont, Header, Content},
  data() {
    try {
      return {
        itemContent:null,
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {

  },
  methods: {
    async handleEmitSaveCont(data) {
      if (data.emitData === 'info') {
        this.itemContent = data.result
      }
    },
    async handleEmitSaveContView(data) {
      if (data.emitData === 'back') {
        this.itemContent = null
      }
    },
  }
}

</script>

<style scoped>

</style>