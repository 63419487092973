<template>
  <section class="content handleErrorCenter">
    <div class="error-page">
      <h2 class="headline text-danger">500</h2>
      <div class="error-content">
        <h3><i class="fas fa-exclamation-triangle text-danger"></i> Oops! Something went wrong.</h3>
        <p>พบปัญหาการเชื่อมต่อกับระบบ Backend โปรดแจ้งกับ Admin เพื่อดำเนินการ Restart Service</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name:"errorExternal",
}

</script>
<style scoped>

</style>