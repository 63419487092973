import {createStore} from 'vuex'
import axios from "axios";
import loginStore from './loginStore';
import environmentStore from "@/store/environmentStore";

interface menu {
    seq: number;
    name: string;
    menuHead: number;
    menuSeq: string
}

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
export default createStore({
    state: {
        // This using for handle menu
        mainMenu: [] as Array<menu>,
        subMenu: [] as Array<Array<menu>>,
        allSubMenu: [] as Array<menu>,
    },
    getters: {
        mainMenu: state => state.mainMenu,
        subMenu: state => state.subMenu,
        allSubMenu: state => state.allSubMenu
    },
    mutations: {
        setMenu(state, menu: menu[]) {
            const permissionData = loginStore.getters.permissions
            const mainMenuData = menu.filter(f => f.menuHead === 0);
            const subMenuData = menu.filter(f => f.menuHead !== 0);
            let subMenuPermission = []
            for (let x = 0; x < subMenuData.length; x++) {
                const result = permissionData.filter((f: {
                    epView: string,
                    menuSeq: number
                }) => f.epView === '1' && f.menuSeq === subMenuData[x].seq);

                if (result.length > 0) {
                    subMenuPermission.push(subMenuData[x])
                }
            }
            const subMenuFilter: Array<Array<menu>> = [];
            for (let x = 0; x < mainMenuData.length; x++) {
                if (subMenuPermission.filter(f => f['menuHead'] === mainMenuData[x]['seq']).length > 0) {
                    subMenuFilter.push(subMenuPermission.filter(f => f['menuHead'] === mainMenuData[x]['seq']))
                } else {
                    subMenuFilter.push([]);
                }
            }
            state.mainMenu = mainMenuData
            state.subMenu = subMenuFilter
            state.allSubMenu = subMenuData
        }
    },
    actions: {
        async fetchMenu({commit}, {token}) {
            const response = await axiosInstance.get(`masterTable/menu`, {
                headers: {
                    'Authorization': `Bearer ${token} `
                }
            })
            const data = await response.data
            commit('setMenu', data);
        }
    },
    modules: {}
})
