<template>
  <section class="hold-transition login-page">
    <div class="login-box">
      <div class="card">
        <div class="card-body login-card-body">
          <div class="login-logo mt-3">
            <img src="../assets/logo.png" alt="AdminLTE Logo" class="imgLoginLogo">
          </div>
          <p class="login-box-msg">ลงชื่อเข้าใช้</p>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="exampleInputEmail1">ชื่อผู้ใช้</label>
              <input list="rememberData" @input="handleInput"  type="text" v-model="formData.username" class="form-control" placeholder="ชื่อผู้ใช้">
              <datalist v-if="showList" id="rememberData">
                <option v-for="(username) in matchingItems" :value="username">{{ username }}</option>
              </datalist>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">รหัสผ่าน</label>
              <input type="password" v-model="formData.password" class="form-control" placeholder="รหัสผ่าน">
            </div>
            <div class="row mt-3">
              <div class="col-8 mb-4">
                <div class="icheck-primary">
                  <input v-model="formData.remember" type="checkbox" id="remember">
                  <span>
                    จดจำการเข้าใช้งาน
                  </span>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block">เข้าสู่ระบบ</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import loginStore from "@/store/loginStore"
import healthCheckStore from "@/store/healthCheckStore";

export default {
  name: "login",
  data() {
    try {
      return {
        usernames:[],
        showList: false,
        matchingItems: [],
        formData: {
          username: '',
          password: '',
          remember: false,
        },
      };
    }catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    try {
      this.getRememberData()
    }catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    handleInput() {
      try{
        if (this.formData.username.length >= 2) {
          this.showList = true;
          this.matchingItems = this.usernames.filter(item => item.startsWith(this.formData.username));
          const data = localStorage.getItem('rememberUser')
          const convertData = JSON.parse(data)
          const getResult = convertData ? convertData.filter(item => item.username === this.formData.username) : []
          if(getResult.length > 0){
            this.formData.username = getResult[0].username
            this.formData.password = getResult[0].password
            this.formData.remember = getResult[0].remember
          }else{
            this.formData.password = ''
            this.formData.remember = false
          }
        } else {
          this.formData.password = ''
          this.formData.remember = false
          this.showList = false;
          this.matchingItems = [];
        }
      }catch (e){
        throw new Error(e);
      }
    },
    selectItem(item) {
      try{
        this.formData.username = item;
        this.showList = false;
      }catch (e) {
        throw new Error(e);
      }
    },
    getRememberData(){
      try {
        const data = localStorage.getItem('rememberUser')
        const convertData = JSON.parse(data)
        if(convertData){
          let usernameData = []
          for(let x = 0; x < convertData.length;x++){
            usernameData.push(convertData[x]['username'])
          }
          this.formData.username = convertData[0].username
          this.formData.password = convertData[0].password
          this.formData.remember = convertData[0].remember
          this.usernames = usernameData
        }
      }catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
        try {

          let validate = true;
          if (this.formData.username.length <= 0 || this.formData.password <= 0) {
            validate = false
            this.alertFunction();
          }
          if (validate && await this.healCheck()) {
            await loginStore.dispatch('login', {username: this.formData.username, password: this.formData.password})
            const loginResult = loginStore.getters.token
            if (loginResult) {
              if(this.formData.remember){
                let createArr = []
                const saveString = {username:this.formData.username,password:this.formData.password,remember: this.formData.remember}
                const toRemember = localStorage.getItem('rememberUser')
                if(toRemember){
                  const convertData = JSON.parse(toRemember)
                  const filterToForSave = convertData.filter(f => f.username === this.formData.username)
                  if(filterToForSave.length > 0){
                    createArr = convertData
                  }else{
                    convertData.push(saveString)
                    createArr = convertData
                  }
                }else{
                  createArr.push(saveString)
                }
                const convertToSave = JSON.stringify(createArr)
                localStorage.setItem('rememberUser',convertToSave)
              }else{
                const rememberRemove = localStorage.getItem('rememberUser')
                const convertData = JSON.parse(rememberRemove)
                if(convertData){
                  const result = convertData.filter(f => f['username'] !== this.formData.username)
                  const convertToSave = JSON.stringify(result)
                  localStorage.setItem('rememberUser',convertToSave)
                }
              }
              this.goTo()
            } else {
              this.alertFunction();
            }
          }else{
            this.goTo()
          }
        }catch (e) {
          throw new Error(e);
        }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
          text: 'ตรวจสอบชื่อผู้ใช้หรือรหัสผ่านอีกครั้ง',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      }catch (e) {
        throw new Error(e);
      }
    },
    goTo() {
      try {
        this.$router.push('/')
      }catch (e) {
        throw new Error(e);
      }
    },
    async healCheck(){
      await healthCheckStore.dispatch('getHealthCheckStatus')
      return healthCheckStore.getters.healthCheckStatus !== 'bad';

    }
  }
}
</script>

<style scoped>

</style>
