<template>
  <div class="loading-overlay">
    <div class="loading-spinner">
      <i class="fas fa-spinner fa-spin"></i> กำลังดำเนินการ...
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Loading"
})
</script>
