<template>
  <div class="modal fade" id="modal-default" ref="customerGroupModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ formData.type === 1 ? 'แก้ไข' : 'เพิ่ม' }}ข้อมูลกลุ่มลูกค้า</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>รหัส</label>
                  <input type="text" v-model="formData.cgCode" class="form-control"
                         placeholder="รหัส">
                  <span class="text-danger">{{ validateResult.cgCode }}</span>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group">
                  <label>ชื่อกลุ่มลูกค้า</label>
                  <input type="text" v-model="formData.cgName" class="form-control"
                         placeholder="ชื่อกลุ่มลูกค้า">
                  <span class="text-danger">{{ validateResult.cgName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="handleCancel()" class="btn btn-default float-right">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import customerGroupStore from "@/store/customerGroupStore";
import loginStore from "@/store/loginStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";
import MachineCalendar from "@/components/productionPlanList/machineCalendar.vue";

export default {
  name: "CustomerGroupModal",
  components: {MachineCalendar, Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        validateResult: {
          cgCode: '',
          cgName: '',
        },
        formData: {
          type: '',
          cgCode: '',
          cgName: '',
          status: '1'
        },
        formSchema: Yup.object().shape({
          cgCode: Yup.string().required(),
          cgName: Yup.string().required(),
        })
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          this.formData.type = this.item['type'];
          this.formData.seq = this.item ? this.item['seq'] : '';
          this.formData.cgCode = this.item ? this.item['cgCode'] : '';
          this.formData.cgName = this.item ? this.item['cgName'] : '';
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  methods: {
    handleModal() {
      try {
        const modalElement = $(this.$refs.customerGroupModal);

        modalElement.modal({
          backdrop: 'static',
          keyboard: false
        });

        modalElement.on('shown.bs.modal', () => {
          this.$refs.calendar?.refreshCalendar(); // Trigger the refresh method
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.formSchema.validate(this.formData, {abortEarly: false}).then(async () => {
              const token = this.token
              if (this.formData.type === 0) {
                const payload = {
                  cgCode: this.formData.cgCode,
                  cgName: this.formData.cgName,
                  status: this.formData.status
                }
                await customerGroupStore.dispatch('createData', {token, payload})
              }
              if (this.formData.type === 1) {
                const payload = {
                  seq: this.formData.seq,
                  cgCode: this.formData.cgCode,
                  cgName: this.formData.cgName,
                  status: this.formData.status
                }
                await customerGroupStore.dispatch('updateData', {token, payload})
              }
              const result = customerGroupStore.getters.saveResult
              if (result) {
                this.successFunction()
              } else {
                this.errorFunction()
              }
            }
        ).catch((err) => {
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.validateResult = {
          cgCode: '',
          cgName: '',
        }
        this.formData.cgCode = this.item ? this.item['cgCode'] : '';
        this.formData.cgName = this.item ? this.item['cgName'] : '';
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่าง',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.customerGroupModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = customerGroupStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel() {
      this.resetFormData();
      $(this.$refs.customerGroupModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>

<style scoped>

</style>
