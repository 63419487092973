import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
axiosInstance.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 499;
};
export default createStore({
    state: {
        tblData: Object,
        saveResult: Boolean,
        handleMessage:String
    },
    getters: {
        tblData: state => state.tblData,
        saveResult: state => state.saveResult,
        handleMessage:state => state.handleMessage
    },
    mutations: {
        setDataTbl(state, payload) {
            state.tblData = payload
        },
        setSave(state, result) {
            state.saveResult = result
        },
        setHandleMessage(state,result){
            state.handleMessage = result
        }
    },
    actions: {
        async getDataTbl({commit}, {sequence,token, page, empCode, empName, empSurName, status, limit}) {
            try {
                if (!limit) {
                    limit = 10
                }
                let customURL = `employee?empCode=${empCode}&empName=${empName}&empSurName=${empSurName}&page=${page}&limit=${limit}&status=${status}`
                if(sequence !== undefined){
                    customURL = `employee?seq=${sequence}&limit=10&status=${status}`
                }
                const result = await axiosInstance.get(customURL, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            } catch (error:any) {
                commit('setUser', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async createData({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`employee/register`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave',false)
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async updateData({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.put(`employee/updateEmployee`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async deleteData({commit}, {token, seq}) {
            try {
                const result = await axiosInstance.delete(`employee?seq=${seq}`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        }

    },
    modules: {}
})
