<template>
  <div class="modal fade" id="modal-default" ref="workOrderModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{
              formData.type === 0 ? 'สร้าง Work Order ใหม่' : `แก้ไข Work Order : #${this.formData.workOrderNumber}`
            }}</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <label class="workOrderLabel">ทั่วไป</label>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>เลขอ้างอิง</label>
                  <input type="text" v-model="formData.woRef" class="form-control"
                         placeholder="เลขอ้างอิง">
                  <span class="text-danger">{{ validateResult.woRef }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>เลขที่ใบสี่งซื้อ</label>
                  <input type="text" v-model="formData.woPrnumber" class="form-control"
                         placeholder="เลขที่ใบสี่งซื้อ">
                  <span class="text-danger">{{ validateResult.woPrnumber }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ชื่องาน</label>
                  <input type="text" v-model="formData.woWcode" class="form-control"
                         placeholder="ชื่องาน">
                  <span class="text-danger">{{ validateResult.woWcode }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ชื่อลูกค้า</label>
                  <select v-model="formData.customerSeq" class="select form-control">
                    <option value="" disabled selected hidden>ชื่อลูกค้า</option>
                    <option v-for="(item) in customerDropdown" :key="item['seq']"
                            :value="item['seq']">
                      {{
                        item['customerName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.customerSeq }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>จำนวนที่ผลิต</label>
                  <input
                      type="text"
                      v-model="formData.woProdAmount"
                      class="form-control"
                      placeholder="จำนวนที่ผลิต"
                      @input="handleInput($event,'woProdAmount')"
                  >
                  <span class="text-danger">{{ validateResult.woProdAmount }}</span>
                </div>
              </div>
              <div class="col-sm-1 align-self-end">
                <div class="form-group">
                  <label>ดวง</label>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>พนักงานขาย</label>
                  <select disabled v-model="formData.employeeSeq" class="select form-control">
                    <option value="" disabled selected hidden>พนักงานขาย</option>
                    <option v-for="(item) in employeeDropdown" :key="item['seq']"
                            :value="item['seq']">
                      {{
                        item['employeeFirstname'] + ' ' + item['employeeLastname']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.employeeSeq }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>ประเภทงาน</label>
                  <select v-model="formData.wotCode" class="select form-control">
                    <option value="" disabled selected hidden>ประเภทงาน</option>
                    <option v-for="(item) in taskDropdown" :key="item['wotCode']"
                            :value="item['wotCode']">{{
                        item['wotName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.wotCode }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>วันที่สั่งงาน</label>
                  <input type="date" v-model="formData.woOrderDate" class="form-control"
                         placeholder="วันที่สั่งงาน">
                  <span class="text-danger">{{ validateResult.woOrderDate }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>วันกำหนดส่งงาน</label>
                  <input type="date" v-model="formData.woDeliverDate" class="form-control"
                         placeholder="วันกำหนดส่งงาน">
                  <span class="text-danger">{{ validateResult.woDeliverDate }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>วิธีการชำระเงิน</label>
                  <select v-model="formData.paymentCode" class="select form-control">
                    <option value="" disabled selected hidden>การชำระเงิน</option>
                    <option v-for="(item) in paymenDropdown" :key="item['paymentCode']"
                            :value="item['paymentCode']">{{
                        item['paymentName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.paymentCode }}</span>
                </div>
              </div>
              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <label>รายละเอียดงาน</label>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ประเภทกระดาษ</label>
                  <select v-model="formData.materialSeq" class="select form-control">
                    <option value="" disabled selected hidden>ประเภทกระดาษ</option>
                    <option v-for="(item) in materialDropdown" :key="item['seq']"
                            :value="item['seq']">
                      {{
                        item['materialName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.materialSeq }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ขนาดโมล</label>
                  <input type="text" v-model="formData.woMole" class="form-control"
                         placeholder="ขนาดโมล"
                         @input="handleInput($event,'woMole')"
                  >
                  <span class="text-danger">{{ validateResult.woMole }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label>หน้ากระดาษ</label>
                  <input
                      type="text"
                      v-model="formData.woPage"
                      class="form-control"
                      placeholder="หน้ากระดาษ"
                      @input="handleInput($event,'woPage')"
                  >
                  <span class="text-danger">{{ validateResult.woPage }}</span>
                </div>
              </div>
              <div class="col-sm-1 align-self-end">
                <div class="form-group">
                  <label>ซม.</label>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label>ขอบข้าง</label>
                  <input
                      type="text"
                      v-model="formData.woSide"
                      class="form-control"
                      placeholder="ขอบข้าง"
                      @input="handleInput($event,'woSide')"
                  >
                  <span class="text-danger">{{ validateResult.woSide }}</span>
                </div>
              </div>
              <div class="col-sm-1 align-self-end">
                <div class="form-group">
                  <label>มม.</label>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ราคา/หน่วย</label>
                  <input
                      type="text"
                      v-model="formData.woPriceUnit"
                      class="form-control"
                      placeholder="ราคา/หน่วย"
                      @input="handleInput($event,'woPriceUnit')"
                  >
                  <span class="text-danger">{{ validateResult.woPriceUnit }}</span>
                </div>
              </div>

              <div class="col-sm-2">
                <div class="form-group">
                  <label>ขนาด (ต x น)</label>
                  <input
                      type="text"
                      v-model="formData.woSizeV"
                      class="form-control"
                      placeholder="ขนาด"
                      @input="handleInput($event,'woSizeV')"
                  >
                  <span class="text-danger">{{ validateResult.woSizeV }}</span>
                </div>
              </div>
              <div class="align-self-end">
                <div class="form-group align-content-center">
                  <label>X</label>
                </div>
              </div>
              <div class="col-sm-2 align-self-end">
                <div class="form-group">
                  <label></label>
                  <input
                      type="text"
                      v-model="formData.woSizeH"
                      class="form-control"
                      placeholder="ขนาด"
                      @input="handleInput($event,'woSizeH')"
                  >
                  <span class="text-danger">{{ validateResult.woSizeH }}</span>
                </div>
              </div>
              <div class="col-sm-2 align-self-end">
                <div class="form-group">
                  <label></label>
                  <select v-model="formData.measureCode" class="select form-control">
                    <option value="" disabled selected hidden>หน่วยขนาด</option>
                    <option v-for="(item) in measureDropdown" :key="item['measureCode']"
                            :value="item['measureCode']">{{
                        item['measureName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.measureCode }}</span>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <label>ช่องไฟ</label>
                  <input
                      type="text"
                      v-model="formData.woGab"
                      class="form-control"
                      placeholder="ช่องไฟ"
                      @input="handleInput($event,'woGab')"
                  >
                  <span class="text-danger">{{ validateResult.woGab }}</span>
                </div>
              </div>
              <div class="align-self-end">
                <div class="form-group">
                  <label>มม.</label>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>แกน</label>
                  <select v-model="formData.axisCode" class="select form-control">
                    <option value="" disabled selected hidden>แกน</option>
                    <option v-for="(item) in axisDropdown" :key="item['axisCode']"
                            :value="item['axisCode']">{{
                        item['axisName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.axisCode }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ปรุฉีก</label>
                  <select v-model="formData.perforationCode" class="select form-control">
                    <option value="" disabled selected hidden>ปรุฉีก</option>
                    <option v-for="(item) in perforationDropdown" :key="item['perforationCode']"
                            :value="item['perforationCode']">{{
                        item['perforationName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.perforationCode }}</span>
                </div>
              </div>


              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>จำนวนดวง/(ม้วน,แผ่น) <span style="color: red">*</span></label>
                      <input
                          type="text"
                          v-model="formData.woStartAmountUnit"
                          class="form-control"
                          placeholder="จำนวนดวง/(ม้วน,แผ่น)"
                          @input="handleInput($event,'woStartAmountUnit')"
                      >
                      <span class="text-danger">{{ validateResult.woStartAmountUnit }}</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>จำนวนดวง/กล่อง</label>
                      <input type="text"
                             v-model="formData.woStarBox"
                             class="form-control"
                             placeholder="กล่อง"
                             @input="handleInput($event,'woStarBox')"
                      >
                      <span class="text-danger">{{ validateResult.woStarBox }}</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>จำนวนแผ่น</label>
                      <input
                          type="text"
                          v-model="formData.woSheetAmount"
                          class="form-control"
                          placeholder="จำนวนแผ่น"
                          @input="handleInput($event,'woSheetAmount')"
                      >
                      <span class="text-danger">{{ validateResult.woSheetAmount }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>จำนวนดวง/แถว <span style="color: red">*</span></label>
                      <input
                          type="text"
                          v-model="formData.woStarRow"
                          class="form-control"
                          placeholder="จำนวนดวง/แถว"
                          @input="handleInput($event,'woStarRow')"
                      >
                      <span class="text-danger">{{ validateResult.woStarRow }}</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>จำนวนแผ่น/กล่อง</label>
                      <input
                          type="text"
                          v-model="formData.woSheetBox"
                          class="form-control"
                          placeholder="กล่อง"
                          @input="handleInput($event,'woSheetBox')"
                      >
                      <span class="text-danger">{{ validateResult.woSheetBox }}</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>จำนวนกล่อง</label>
                      <input
                          type="text"
                          v-model="formData.woBox"
                          class="form-control"
                          placeholder="จำนวน"
                          @input="handleInput($event,'woBox')"
                      >
                      <span class="text-danger">{{ validateResult.woBox }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>จำนวนดวง/พับ</label>
                      <input
                          type="text"
                          v-model="formData.woStarFold"
                          class="form-control"
                          placeholder="พับ"
                          @input="handleInput($event,'woStarFold')"
                      >
                      <span class="text-danger">{{ validateResult.woStarFold }}</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>จำนวนรีม</label>
                      <input
                          type="text"
                          v-model="formData.woReamsAmount"
                          class="form-control"
                          placeholder="จำนวนรีม"
                          @input="handleInput($event,'woReamsAmount')"
                      >
                      <span class="text-danger">{{ validateResult.woReamsAmount }}</span>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                  <label>กรอ</label>
                  <select v-model="formData.spinningCode" class="select form-control">
                    <option value="" disabled selected hidden>กรอ</option>
                    <option v-for="(item) in spinningDropdown" :key="item['spinningCode']"
                            :value="item['spinningCode']">
                      {{
                        item['spinningName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.spinningCode }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>ไดคัท</label>
                  <select v-model="formData.diecutCode" class="select form-control">
                    <option value="" disabled selected hidden>ไดคัท</option>
                    <option v-for="(item) in diecutDropdown" :key="item['diecutCode']"
                            :value="item['diecutCode']">{{
                        item['diecutName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.diecutCode }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label>ราคา</label>
                  <input
                      type="text"
                      v-model="formData.woPricePackage"
                      class="form-control"
                      placeholder="ราคา"
                      @input="handleInput($event,'woPricePackage')"
                  >
                  <span class="text-danger">{{ validateResult.woPricePackage }}</span>
                </div>
              </div>
              <div class="col-sm-2 align-self-end">
                <div class="form-group">
                  <label>กล่อง/รีม/แผ่น</label>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>รูปแบบการกรอ</label>
                  <select v-model="formData.spCode" class="select form-control">
                    <option value="" disabled selected hidden>รูปแบบการกรอ</option>
                    <option v-for="(item) in sinningPetternDropdown" :key="item['spCode']"
                            :value="item['spCode']">{{
                        item['spName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.spCode }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>วานิช</label>
                  <select v-model="formData.varnishCode" class="select form-control">
                    <option value="" disabled selected hidden>วานิช</option>
                    <option v-for="(item) in varnishDropdown" :key="item['varnishCode']"
                            :value="item['varnishCode']">{{
                        item['varnishName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.varnishCode }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>หมายเหตุ</label>
                  <textarea type="text" v-model="formData.woNote" class="form-control"
                            placeholder="หมายเหตุ"></textarea>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>หมายเหตุ(Packing)</label>
                  <textarea type="text" v-model="formData.woNotePacking" class="form-control"
                            placeholder="หมายเหตุ"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import loginStore from "@/store/loginStore";
import workOrderStore from '@/store/workOrderStore';
import * as Yup from "yup";
import moment from "moment";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "WorkOrderModal",
  components: {Loading},
  props: ['item'],
  data() {
    return {
      isLoading: false,
      workOrderNo: null,
      token: loginStore.getters.token,
      customerDropdown: [],
      employeeDropdown: [],
      taskDropdown: [],
      paymenDropdown: [],
      materialDropdown: [],
      measureDropdown: [],
      axisDropdown: [],
      perforationDropdown: [],
      spinningDropdown: [],
      diecutDropdown: [],
      sinningPetternDropdown: [],
      varnishDropdown: [],
      validateResult: {
        woRef: '',
        woWcode: '',
        woPrnumber: '',
        customerSeq: '',
        woProdAmount: '',
        employeeSeq: '',
        wotCode: '',
        paymentCode: '',
        materialSeq: '',
        woMole: '',
        woOrderDate: '',
        woDeliverDate: '',
        woPage: '',
        woSide: '',
        woPriceUnit: '',
        woSheetAmount: '',
        woStartAmountUnit:'',
        woSizeV: '',
        woSizeH: '',
        measureCode: '',
        woGab: '',
        woReamsAmount: '',
        axisCode: '',
        perforationCode: '',
        woBox: '',
        spinningCode: '',
        diecutCode: '',
        woPricePackage: '',
        spCode: '',
        varnishCode: '',
        woStarFold: '',
        woStarBox: '',
        woSheetBox: '',
        woStarRow: '',
      },
      formData: {
        type: '',
        workOrderNumber: '',
        woRef: '',
        woWcode: '',
        woPrnumber: '',
        customerSeq: '',
        woProdAmount: '',
        employeeSeq:'',
        wotCode: '',
        paymentCode: '',
        materialSeq: '',
        woMole: '',
        woOrderDate: '',
        woDeliverDate: '',
        woPage: '',
        woSide: '',
        woPriceUnit: null,
        woSheetAmount: null,
        woStartAmountUnit:null,
        woSizeV: null,
        woSizeH: null,
        measureCode: '',
        woGab: null,
        woReamsAmount: null,
        axisCode: '',
        perforationCode: '',
        woBox: null,
        spinningCode: '',
        diecutCode: '',
        woPricePackage: null,
        spCode: '',
        varnishCode: '',
        woStarFold: null,
        woStarBox: null,
        woSheetBox: null,
        woStarRow: null,
        woNote: '',
        woNotePacking: '',
        wosCode: '',
        status: ''
      }
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.workOrderNumber = this.item['woNumber'];
            this.customerDropdown = this.item['master']['customerLst']
            this.employeeDropdown = this.item['master']['empLst']
            this.taskDropdown = this.item['master']['workOrderTypeLst']
            this.paymenDropdown = this.item['master']['paymentLst']
            this.materialDropdown = this.item['master']['materialLst']
            this.measureDropdown = this.item['master']['measureLst']
            this.axisDropdown = this.item['master']['axisLst']
            this.perforationDropdown = this.item['master']['perforationLst']
            this.spinningDropdown = this.item['master']['spinningLst']
            this.diecutDropdown = this.item['master']['diecutLst']
            this.sinningPetternDropdown = this.item['master']['sinningPetternLst']
            this.varnishDropdown = this.item['master']['varnishLst']
            this.formData.type = this.item['type'];
            this.formData.seq = this.item['seq'];
            this.formData.woRef = this.item['woRef'];
            this.formData.woWcode = this.item['woWcode'];
            this.formData.woPrnumber = this.item['woPrnumber'];
            this.formData.customerSeq = this.item['customerSeq'];
            this.formData.woProdAmount = this.item['woProdAmount'];
            this.formData.employeeSeq = this.item['employeeSeq'];
            this.formData.wotCode = this.item['wotCode'];
            this.formData.paymentCode = this.item['paymentCode'];
            this.formData.materialSeq = this.item['materialSeq'];
            this.formData.woMole = this.item['woMole'];
            this.formData.woOrderDate = this.reversDateFormat(this.item['woOrderDate']);
            this.formData.woDeliverDate = this.reversDateFormat(this.item['woDeliverDate']);
            this.formData.woPage = this.item['woPage'];
            this.formData.woSide = this.item['woSide'];
            this.formData.woPriceUnit = this.item['woPriceUnit'];
            this.formData.woStartAmountUnit = this.item['woStartAmountUnit'] === null || this.item['woStartAmountUnit'] === 0 ? '' : this.item['woStartAmountUnit'];
            this.formData.woSheetAmount = this.item['woSheetAmount'] === null || this.item['woSheetAmount'] === 0 ? '' : this.item['woSheetAmount'];
            this.formData.woSizeV = this.item['woSizeV'];
            this.formData.woSizeH = this.item['woSizeH'];
            this.formData.measureCode = this.item['measureCode'];
            this.formData.woGab = this.item['woGab'];
            this.formData.woReamsAmount = this.item['woReamsAmount'] === null || this.item['woReamsAmount'] === 0 ? '' : this.item['woReamsAmount'];
            this.formData.axisCode = this.item['axisCode'];
            this.formData.perforationCode = this.item['perforationCode'];
            this.formData.woBox = this.item['woBox'] === null || this.item['woBox'] === 0 ? '' : this.item['woBox'];
            this.formData.spinningCode = this.item['spinningCode'];
            this.formData.diecutCode = this.item['diecutCode'];
            this.formData.woPricePackage = this.item['woPricePackage'];
            this.formData.spCode = this.item['spCode'];
            this.formData.varnishCode = this.item['varnishCode'];
            this.formData.woStarFold = this.item['woStarFold'] === null || this.item['woStarFold'] === 0 ? '' : this.item['woStarFold'];
            this.formData.woStarBox = this.item['woStarBox'] === null || this.item['woStarBox'] === 0 ? '' : this.item['woStarBox'];
            this.formData.woSheetBox = this.item['woSheetBox'] === null || this.item['woSheetBox'] === 0 ? '' : this.item['woSheetBox'];
            this.formData.woStarRow = this.item['woStarRow'] === null || this.item['woStarRow'] === 0 ? '' : this.item['woStarRow'];
            this.formData.woNote = this.item['woNote'];
            this.formData.woNotePacking = this.item['woNotePacking'];
            this.formData.wosCode = this.item['wosCode']
            this.formData.status = this.item['status'];
          }

        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        // woRef: Yup.string(),
        woWcode: Yup.string().required('รหัสงานไม่สามารถเป็นค่าว่างได้'),
        // woPrnumber: Yup.string(),
        customerSeq: Yup.mixed().oneOf(this.customerDropdown.map(c => c.seq)).required('ชื่อลูกค้าไม่สามารถเป็นค่าว่างได้'),
        woProdAmount: Yup.number().required('จำนวนที่ผลิตไม่สามารถเป็นค่าว่างได้'),
        employeeSeq: Yup.mixed().oneOf(this.employeeDropdown.map(c => c.seq)).required('พนักงานขายไม่สามารถเป็นค่าว่างได้'),
        wotCode: Yup.mixed().oneOf(this.taskDropdown.map(c => c.wotCode)).required('ประเภทงานไม่สามารถเป็นค่าว่างได้'),
        paymentCode: Yup.mixed().oneOf(this.paymenDropdown.map(c => c.paymentCode)).required('การชำระเงินไม่สามารถเป็นค่าว่างได้'),
        materialSeq: Yup.mixed().oneOf(this.materialDropdown.map(c => c.seq)).required('ประเภทกระดาษไม่สามารถเป็นค่าว่างได้'),
        // woMole: Yup.string(),
        woOrderDate: Yup.string().required('วันที่สั่งงานไม่สามารถเป็นค่าว่างได้'),
        woDeliverDate: Yup.string().required('วันกำหนดส่งงานไม่สามารถเป็นค่าว่างได้'),
        woPage: Yup.string()
            .transform((value) => (isNaN(parseInt(value, 10)) ? undefined : value))
            .test('is-number', 'หน้ากระดาษต้องเป็นตัวเลขเท่านั้น', (value) => !isNaN(parseInt(value, 10)))
            .required('หน้ากระดาษไม่สามารถเป็นค่าว่างได้'),
        woSide: Yup.string()
            .transform((value) => (isNaN(parseInt(value, 10)) ? undefined : value))
            .test('is-number', 'หน่วยต้องเป็นตัวเลขเท่านั้น', (value) => !isNaN(parseInt(value, 10)))
            .required('ขอบข้างไม่สามารถเป็นค่าว่างได้'),
        woPriceUnit: Yup.string()
            .transform((value) => (isNaN(parseInt(value, 10)) ? undefined : value))
            .test('is-number', 'หน่วยต้องเป็นตัวเลขเท่านั้น', (value) => !isNaN(parseInt(value, 10)))
            .required('หน่วยไม่สามารถเป็นค่าว่างได้'),
        woStartAmountUnit: Yup.string()
            .transform((value) => (isNaN(parseInt(value, 10)) ? undefined : value))
            .test('required-with-perforationCode', 'จำนวนดวง/(ม้วน,แผ่น)ไม่สามารถเป็นค่าว่างได้', (value) => !isNaN(parseInt(value, 10)))
            .required('จำนวนดวง/(ม้วน,แผ่น)ไม่สามารถเป็นค่าว่างได้'),
        woSizeV: Yup.string()
            .transform((value) => (isNaN(parseInt(value, 10)) ? undefined : value))
            .test('is-number', 'ขนาดต้องเป็นตัวเลขเท่านั้น', (value) => !isNaN(parseInt(value, 10)))
            .required('ขนาดไม่สามารถเป็นค่าว่างได้'),
        woSizeH: Yup.string()
            .transform((value) => (isNaN(parseInt(value, 10)) ? undefined : value))
            .test('is-number', 'ขนาดต้องเป็นตัวเลขเท่านั้น', (value) => !isNaN(parseInt(value, 10)))
            .required('ขนาดไม่สามารถเป็นค่าว่างได้'),
        measureCode: Yup.mixed().oneOf(this.measureDropdown.map(c => c.measureCode)).required('ขนาดไม่สามารถเป็นค่าว่างได้'),
        woGab: Yup.string()
            .transform((value) => (isNaN(parseInt(value, 10)) ? undefined : value))
            .test('is-number', 'ช่องไฟต้องเป็นตัวเลขเท่านั้น', (value) => !isNaN(parseInt(value, 10)))
            .required('ช่องไฟไม่สามารถเป็นค่าว่างได้'),
        axisCode: Yup.mixed().oneOf(this.axisDropdown.map(c => c.axisCode)).required('ต้องเลือกแกน'),
        perforationCode: Yup.mixed().oneOf(this.perforationDropdown.map(c => c.perforationCode)).required('ปรุฉีกไม่สามารถเป็นค่าว่างได้'),
        spinningCode: Yup.mixed().oneOf(this.spinningDropdown.map(c => c.spinningCode)).required('กรอไม่สามารถเป็นค่าว่างได้'),
        diecutCode: Yup.mixed().oneOf(this.diecutDropdown.map(c => c.diecutCode)).required('ไดคัทไม่สามารถเป็นค่าว่างได้'),
        woPricePackage: Yup.number().nullable(),
        spCode: Yup.mixed().oneOf(this.sinningPetternDropdown.map(c => c.spCode)).required('รูปแบบการกรอไม่สามารถเป็นค่าว่างได้'),
        varnishCode: Yup.mixed().oneOf(this.varnishDropdown.map(c => c.varnishCode)).required('วานิชไม่สามารถเป็นค่าว่างได้'),
        woStarRow: Yup.string()
            .transform((value) => (isNaN(parseInt(value, 10)) ? undefined : value))
            .test('required-with-perforationCode', 'จำนวนดวง/แถวไม่สามารถเป็นค่าว่างได้',(value) => !isNaN(parseInt(value, 10)))
            .required('จำนวนดวง/แถวไม่สามารถเป็นค่าว่างได้')
      });
    }
  },

  methods: {
    handleInput(event,formData) {
      const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
      const match = sanitizedValue.match(/(\d*\.\d{0,3}|\d*)/);
      this.formData[formData] = match ? match[0] : '';
    },
    handleModal() {
      try {
        $(this.$refs.workOrderModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.formSchema.validate(this.formData, {abortEarly: false}).then(async () => {
              this.isLoading = true
              const token = loginStore.getters.token
              if (this.formData.type === 0) {
                const payload = {
                  woRef: this.formData.woRef,
                  woWcode: this.formData.woWcode,
                  woPrnumber: this.formData.woPrnumber,
                  customerSeq: this.formData.customerSeq,
                  woProdAmount: this.formData.woProdAmount,
                  employeeSeq: this.formData.employeeSeq,
                  wotCode: this.formData.wotCode,
                  paymentCode: this.formData.paymentCode,
                  materialSeq: this.formData.materialSeq,
                  woMole: this.formData.woMole,
                  woOrderDate: this.formData.woOrderDate,
                  woDeliverDate: this.formData.woDeliverDate,
                  woPage: this.formData.woPage,
                  woSide: this.formData.woSide,
                  woPriceUnit: this.formData.woPriceUnit,
                  woSheetAmount: this.formData.woSheetAmount === 0 ? null : this.formData.woSheetAmount,
                  woStartAmountUnit: this.formData.woStartAmountUnit === 0 ? null : this.formData.woStartAmountUnit,
                  woSizeV: this.formData.woSizeV,
                  woSizeH: this.formData.woSizeH,
                  measureCode: this.formData.measureCode,
                  woGab: this.formData.woGab,
                  woReamsAmount: this.formData.woReamsAmount === 0 ? null : this.formData.woReamsAmount,
                  axisCode: this.formData.axisCode,
                  perforationCode: this.formData.perforationCode,
                  woBox: this.formData.woBox === 0 ? null : this.formData.woBox,
                  spinningCode: this.formData.spinningCode,
                  diecutCode: this.formData.diecutCode,
                  woPricePackage: this.formData.woPricePackage,
                  spCode: this.formData.spCode,
                  varnishCode: this.formData.varnishCode,
                  woStarFold: this.formData.woStarFold === 0 ? null : this.formData.woStarFold,
                  woStarBox: this.formData.woStarBox === 0 ? null : this.formData.woStarBox,
                  woSheetBox: this.formData.woSheetBox === 0 ? null : this.formData.woSheetBox,
                  woStarRow: this.formData.woStarRow === 0 ? null : this.formData.woStarRow,
                  woNote: this.formData.woNote,
                  woNotePacking: this.formData.woNotePacking,
                  status: '1'
                }
                console.log(payload);
                await workOrderStore.dispatch('createData', {token, payload})
              }
              if (this.formData.type === 1) {
                const payload = {
                  seq: this.formData.seq,
                  woNumber: this.formData.workOrderNumber,
                  woRef: this.formData.woRef,
                  woWcode: this.formData.woWcode,
                  woPrnumber: this.formData.woPrnumber,
                  customerSeq: this.formData.customerSeq,
                  woProdAmount: this.formData.woProdAmount,
                  employeeSeq: this.formData.employeeSeq,
                  wotCode: this.formData.wotCode,
                  paymentCode: this.formData.paymentCode,
                  materialSeq: this.formData.materialSeq,
                  woMole: this.formData.woMole,
                  woOrderDate: this.formData.woOrderDate,
                  woDeliverDate: this.formData.woDeliverDate,
                  woPage: this.formData.woPage,
                  woSide: this.formData.woSide,
                  woPriceUnit: this.formData.woPriceUnit,
                  woSheetAmount: this.formData.woSheetAmount === 0 ? null : this.formData.woSheetAmount,
                  woStartAmountUnit: this.formData.woStartAmountUnit === 0 ? null : this.formData.woStartAmountUnit,
                  woSizeV: this.formData.woSizeV,
                  woSizeH: this.formData.woSizeH,
                  measureCode: this.formData.measureCode,
                  woGab: this.formData.woGab,
                  woReamsAmount: this.formData.woReamsAmount === 0 ? null : this.formData.woReamsAmount,
                  axisCode: this.formData.axisCode,
                  perforationCode: this.formData.perforationCode,
                  woBox: this.formData.woBox === 0 ? null : this.formData.woBox,
                  spinningCode: this.formData.spinningCode,
                  diecutCode: this.formData.diecutCode,
                  woPricePackage: this.formData.woPricePackage,
                  spCode: this.formData.spCode,
                  varnishCode: this.formData.varnishCode,
                  woStarFold: this.formData.woStarFold === 0 ? null : this.formData.woStarFold,
                  woStarBox: this.formData.woStarBox === 0 ? null : this.formData.woStarBox,
                  woSheetBox: this.formData.woSheetBox === 0 ? null : this.formData.woSheetBox,
                  woStarRow: this.formData.woStarRow === 0 ? null : this.formData.woStarRow,
                  woNote: this.formData.woNote,
                  woNotePacking: this.formData.woNotePacking,
                  wosCode: "001",
                  status: '1'
                }
                await workOrderStore.dispatch('updateData', {token, payload})
              }
              const result = workOrderStore.getters.saveResult
              this.isLoading = false
              if (result) {
                if (result.data) {
                  this.workOrderNo = result.data.workOrderNumber
                } else {
                  this.workOrderNo = this.formData.workOrderNumber
                }
                this.successFunction()
              } else {
                this.errorFunction()
              }
            }
        ).catch((err) => {
          this.isLoading = false
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.validateResult = {
          woRef: '',
          woWcode: '',
          woPrnumber: '',
          customerSeq: '',
          woProdAmount: '',
          employeeSeq: '',
          wotCode: '',
          paymentCode: '',
          materialSeq: '',
          woMole: '',
          woOrderDate: '',
          woDeliverDate: '',
          woPage: '',
          woSide: '',
          woPriceUnit: '',
          woSheetAmount: '',
          woSizeV: '',
          woSizeH: '',
          measureCode: '',
          woGab: '',
          woReamsAmount: '',
          axisCode: '',
          perforationCode: '',
          woBox: '',
          spinningCode: '',
          diecutCode: '',
          woPricePackage: '',
          spCode: '',
          varnishCode: '',
          woStarFold: '',
          woStarBox: '',
          woSheetBox: '',
          woStarRow: '',
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่าง',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      let confirmed = false;
      try {
        this.$swal
            .fire({
              title: 'บันทึกข้อมูลสำเร็จ',
              text: `${this.formData.type === 1 ? 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว' : 'หมายเลข WO ของท่านคือ : ' + this.workOrderNo}`,
              icon: 'success',
              confirmButtonText: 'ยืนยัน',
              backdrop: false,
              allowOutsideClick: false
            })
            .then((result) => {
              if (result.isConfirmed) {
                confirmed = true;
                this.$emit('form-submitted');
                $(this.$refs.workOrderModal).modal('hide');
              }
            });
        const modal = $(this.$refs.workOrderModal);
        const modalInstance = modal.data('bs.modal');
        modalInstance._config.backdrop = 'static';
        modalInstance._config.keyboard = false;
        modal.modal('show');
        modalInstance._element.addEventListener('click', (e) => {
          if (!confirmed) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = workOrderStore.getters.errorMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    reversDateFormat(dateData) {
      try {
        return dateData ? moment(dateData).format('YYYY-MM-DD') : '';
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel() {
      $(this.$refs.workOrderModal).modal('hide');
      this.$emit('form-submitted')
    }
  }
}
</script>

<style scoped>label {
  font-size: 14px;
}</style>
