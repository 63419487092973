<template>
  <content>
    <div class="content-header">
      <div class="container-fluid">
        <a @click="handleBack" class="navigationText rowHovered">{{ `< กลับ` }}</a>
            <h1 class="m-0">บันทึกเวลาการผลิต {{}}</h1>
            <saveProd-modal @form-submitted="getData(currentPage, 10)" ref="saveProdModal"/>
            <div class="card card-primary mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <b>ทั่วไป</b>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ชื่องาน</span><br>
                    <span class="text-bold front-color-custom">{{ this.item[0].woWcode }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">หมายเลข Work Order</span><br>
                    <span class="text-bold">{{ this.item[0].woNumber }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                    <span class="text-bold">{{ this.item[0].ctmName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ประเภทงาน</span><br>
                    <span class="text-bold">{{ this.item[0].wotName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">พนักงานขาย</span><br>
                    <span class="text-bold">{{ this.item[0].empName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">กำหนดส่งงาน</span><br>
                    <span class="text-bold">{{ this.item[0]['planning'].plProdDeliver }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-12">
                    <span class="inActiveText">หมายเหตุ</span><br>
                    <span class="text-bold">{{ this.item[0].woRemark }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-primary mt-2">
              <div class="card-body">
                <div class="row">
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">วัตถุดิบพิเศษ</span><br>
                    <div class="d-flex justify-content-start align-items-center">
                      <input type="text" class="form-control" placeholder="000">
                      <span style="padding-right: 2%; padding-left: 2%"><b> ม.</b> </span>
                      <button class="btn btn-primary">เพิ่ม </button>
                    </div>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">วัตถุดิบเบิก</span><br>
                    <span class="text-bold">1000 ม.</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">วัตถุดิบทั้งหมด</span><br>
                    <span class="text-bold">1000 ม.</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">ระยะรอบ</span><br>
                    <span class="text-bold">30 ซม.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-primary mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6 mt-2 float-left">
                    <b>ตารางผลิต</b>
                  </div>
                  <div class="col-sm-6 text-right">
                    <button class="btn btn-primary" @click.prevent="addTime()">เพิ่มช่วงเวลา</button>
                  </div>
                </div>
              </div>
            </div>
        <Tabletemplate
            @table-template="handleEmitTable"
            :item="{
                    header : [
                       {
                         columnDisplay:'',
                         columnName: 'button',
                         sort:false,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'วันที่',
                         columnName: 'prodWorkingStart',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'เวลาเริ่ม',
                         columnName: 'prodWorkingTimeStart',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'เวลาเริ่มสิ้นสุด',
                         columnName: 'prodWorkingTimeFinnish',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ประเภทช่วงเวลา',
                         columnName: 'pwtName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ช่างพิมพ์',
                         columnName: 'empName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'รอบพิมพ์',
                         columnName: 'printRoundName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ความเร็ว',
                         columnName: 'printSpeedName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'เพิ่มเติม',
                         columnName: 'prodDetail',
                         sort:true,
                         style:'width: 1%'
                       },
                    ],
                    data:{
                      tblData:mapData,
                      pageSize:pageSize,
                      currentPage:currentPage,
                      allShow:allShow
                    }
            }"
        />
      </div>
    </div>

  </content>
</template>


<script>
import Content from "@/components/Content.vue";
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";
import HeaderSection from "@/components/boilerplate/headerSection.vue";
import SaveProdModal from "@/components/saveProd/SaveProdModal.vue";
import Tabletemplate from "@/components/boilerplate/tableTemplate.vue";
import LoginStore from "@/store/loginStore";
import productionPrintStore from "@/store/productionPrintStore";

export default {
  name: "SaveProdContent",
  components: {Tabletemplate, HeaderSection, Header, Loading, Content, SaveProdModal },
  props: ['item'],
  emits: [
    'saveProd-content-view',
  ],
  data() {
    try {
      return {
        token: LoginStore.getters.token,
        isLoading: false,
        itemInfo: null,
        tblData: null,
        mapData: [],
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        dataByID: null,
        emitProgress: {
          progress: '',
          result: ''
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    this.initializeData()
  },
  methods: {
    async initializeData() {
      try {
        await this.getData(this.currentPage)
        this.handleMapData()
      } catch (e) {
        throw new Error(e)
      }
    },
    async handleRowClick() {
      try {
        this.emitProgress.progress = 'info'
        this.emitProgress.result = this.dataByID;
        this.handleChange()
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData(page) {
      try {
        this.currentPage = page
        this.isLoading = true
        const payload = {
          token: this.token,
          woSeq:this.item[0].seq,
          plSeq:this.item[0]['planning'].seq,
          prepressSeq:this.item[0]['woPrepress'].seq
        }
        await productionPrintStore.dispatch('getSubDataTbl', payload)
        this.tblData = productionPrintStore.getters.subTblData['productionPrintLst']
        this.pageSize = productionPrintStore.getters.tblData['limit']
        this.allShow = productionPrintStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    handleButton() {
        return [{
          icon: "fa fa-trash",
          buttonName: "",
          css: "btn btn-danger button-small",
          condition: { module: "saveProdContent", process: 'delete' },
        }];
    },
    async handleEmitTable(data) {
      if (data.emitData === 'pagination') {
        const page = data.result
        await this.getData(page)
        this.handleMapData()
      }
      if (data.emitData === 'rowID') {
        const seq = data.result.seq
        this.dataByID = await this.getDataById(seq)
        await this.handleRowClick()
      }
    },
    handleMapData() {
      this.mapData = []
      this.tblData.map(dataRe => {
        let buttonHandle = this.handleButton()
        this.mapData.push(
            {
              button: {
                data: buttonHandle,
              },
              seq: {
                data: dataRe.seq,
                style: ''
              },
              prodWorkingStart: {
                data: dataRe.prodWorkingStart,
                style: ''
              },
              prodWorkingTimeStart: {
                data: dataRe.prodWorkingTimeStart,
                style: ''
              },
              prodWorkingTimeFinnish: {
                data: dataRe.prodWorkingTimeFinnish,
                style: ''
              },
              pwtName: {
                data: dataRe.pwtName,
                style: ''
              },
              empName: {
                data: dataRe.empName,
                style: ''
              },
              printRoundName: {
                data: dataRe.printRoundName,
                style: ''
              },
              printSpeedName: {
                data: dataRe.printSpeedName,
                style: ''
              },
              prodDetail: {
                data: dataRe.prodDetail,
                style: ''
              },
            }
        )
      })
    },
    handleBack() {
      this.emitProgress.progress = 'back'
      this.handleChange()
    },
    handleChange() {
      this.$emit("saveProd-content-view", { emitData: this.emitProgress.progress })
    },
    async addTime() {
      try {
        this.$refs.saveProdModal.handleModal(this.item[0])
      } catch (e) {
        throw new Error(e);
      }
    },
  }
}
</script>


<style scoped></style>