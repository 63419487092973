<template>
    <div class="modal fade" id="modal-default" ref="workOrderSelectionModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Work Order No.#่{{ data.woNumber }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card card-primary">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <b>ทั่วไป</b>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">หมายเลขWorkOrder</span><br>
                                    <span>{{ data.woNumber }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">เลขอ้างอิง</span><br>
                                    <span>{{ data.woRef }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">เลขที่ใบสั่งซื้อ</span><br>
                                    <span>{{ data.woPrnumber }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">ชื่องาน</span><br>
                                    <span>{{ data.woNumber }}</span>
                                </div>
                                <div class="col-sm-6">
                                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                                    <span>{{ data.ctmName }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">จำนวนที่ผลิต</span><br>
                                    <span>{{ data.woProdAmount }} ดวง</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">พนักงานขาย</span><br>
                                    <span>{{ data.empName }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">ประเภทงาน</span><br>
                                    <span>{{ data['wotName'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">วันที่สั่งงาน</span><br>
                                    <span>{{ changeDateFormat(data['woOrderDate']) }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">วันกำหนดส่งงาน</span><br>
                                    <span>{{ changeDateFormat(data['woDeliverDate']) }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">วิธีการชำระเงิน</span><br>
                                    <span>{{ data['paymentName'] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-primary">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <b>รายละเอียดงาน</b>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-12">
                                    <span class="inActiveText">ประเภทกระดาษ</span><br>
                                    <span>{{ data.materialName }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">หน้ากระดาษ</span><br>
                                    <span>{{ data.woPage }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">ขอบข้าง</span> <br>
                                    <span>{{ data.woSide }} มม.</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">ราคา/หน่วย</span> <br>
                                    <span>{{ data.woPriceUnit }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">จำนวนแผ่น</span> <br>
                                    <span>{{ data.woSheetAmount }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">ขนาด (ต x น)</span> <br>
                                    <span>{{ data.woSizeV }} X {{ data.woSizeH }} {{ data['measureName'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">ช่องไฟ</span> <br>
                                    <span>{{ data.woGab }} มม.</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">จำนวนรีม</span> <br>
                                    <span>{{ data.woReamsAmount }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">แกน</span> <br>
                                    <span>{{ data['axisName'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">ปรุฉีก</span> <br>
                                    <span>{{ data['perforationName'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">จำนวนกล่อง</span> <br>
                                    <span>{{ data.woBox }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">กรอ</span> <br>
                                    <span>{{ data['spinningName'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">ไดคัท</span> <br>
                                    <span>{{ data['diecutName'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-4">
                                    <span class="inActiveText">ราคา</span> <br>
                                    <span>{{ data.woPricePackage }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">รูปแบบการกรอ</span> <br>
                                    <span>{{ data['spName'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">วานิช</span> <br>
                                    <span>{{ data['varnishName'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-2">
                                    <span class="inActiveText">จำนวนดวง/ม้วน</span> <br>
                                    <span>{{ data['woStarRoll'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-2">
                                    <span class="inActiveText">จำนวนดวง/พับ</span> <br>
                                    <span>{{ data['woStarFold'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-2">
                                    <span class="inActiveText">จำนวนดวง/แผ่น</span> <br>
                                    <span>{{ data['woStarSheet'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-2">
                                    <span class="inActiveText">จำนวนดวง/กล่อง</span> <br>
                                    <span>{{ data['woStarBox'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-2">
                                    <span class="inActiveText">จำนวนแผ่น/กล่อง</span> <br>
                                    <span>{{ data['woSheetBox'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-2">
                                    <span class="inActiveText">จำนวนดวง/แถว</span> <br>
                                    <span>{{ data['woStarRow'] }}</span>
                                </div>
                                <div class="col-sm-12">
                                    <hr>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">หมายเหตุ</span> <br>
                                    <span>{{ data['woNote'] }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">หมายเหตุ(Packing)</span> <br>
                                    <span>{{ data['woNotePacking'] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">ยกเลิก</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import loginStore from "@/store/loginStore";

export default {
    name: "WorkOrderSelectionModal",
    props: ['item'],
    data() {
        return {
            data: [],
            token: loginStore.getters.token,
            permission:loginStore.getters.role
        }
    },
    watch: {
        item: {
            handler() {
                try {
                    if (this.item) {
                        this.data = this.item
                    }
                } catch (e) {
                    throw new Error(e);
                }
            },
            deep: true
        }
    },
    methods: {
        changeDateFormat(dateData) {
            try {
                return dateData ? moment(dateData, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
            } catch (e) {
                throw new Error(e);
            }
        },
        handleModal() {
            try {
                $(this.$refs.workOrderSelectionModal).modal({
                    backdrop: 'static',
                    keyboard: false
                });
            } catch (e) {
                throw new Error(e);
            }
        },
    }
}
</script>

<style scoped>

</style>