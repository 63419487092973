<template>
  <div class="modal fade" id="modal-default" ref="packingOutModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">ส่งออกสต๊อคสินค้า</h4>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="card card-primary">
              <div class="card-body">
                <div class="row">
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                    <span>{{ item.customerName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่องาน</span><br>
                    <span>{{ item.stockWname }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ขนาดงาน</span><br>
                    <span>{{ item.sizeMole }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-12">
                    <span class="inActiveText">หมายเหตุ</span><br>
                    <span>{{ item.stockRemark }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>จำนวนดวงทั้งหมด</label>
                  <input v-model="formData.amount" type="number" class="form-control" placeholder="จำนวนดวงทั้งหมด">
                  <span class="text-danger">{{ validateResult.amount }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 image-container">
                <img v-if="item.photoOne" :src="getImageSource(item.photoOne)" alt="Uploaded Image" class="set-image"/>
                <img v-if="item.photoTwo" :src="getImageSource(item.photoTwo)" alt="Uploaded Image" class="set-image"/>
                <img v-if="item.photoThree" :src="getImageSource(item.photoThree)" alt="Uploaded Image" class="set-image"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="handleCancel()" class="btn btn-default float-right" >ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
      
<script>
import loginStore from "@/store/loginStore";
import packingStore from "@/store/packingStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";
import environmentStore from "@/store/environmentStore";

export default {
  name: "PackingOutModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        employeeSeq: loginStore.getters.seq,
        role: loginStore.getters.role.roleCode,
        baseurl: environmentStore.getters.upload,
        validateResult: {
          amount: null
        },
        formData: {
          amount: null
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        amount: Yup.string().required('กรุณาเลือกกรอกจำนวน')
      });
    },
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.amount = this.item['stoAmount'];
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    },
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.packingOutModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    getImageSource(image) {
      try {
        console.log(this.baseurl + image)
        if (image.startsWith('/upload')) {
          console.log('this is baseurl img : '+this.baseurl + image);
          return this.baseurl + image;
        } else {
          return image;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    cancel() {
      try {
        this.formData.amount = null
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.formSchema.validate(this.formData, { abortEarly: false }).then(async () => {
          const token = this.token
          const payload = {
            stockSeq: this.item.seq,
            stockAmoutPacking: this.formData.amount,
            stockAmout: this.formData.amount,
            ptSeq: 2,
            employeeSeq: this.employeeSeq
          }
          await packingStore.dispatch('stockOut', { token, payload })
          const result = packingStore.getters.saveResult
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
        }
        ).catch((err) => {
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.formData = {
          amount: null
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.packingOutModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError =  packingStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel(){
      $(this.$refs.packingOutModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>
      
<style scoped></style>
      