import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
axiosInstance.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 499;
};
export default createStore({
    state: {
        tblData: Object,
        previewData:Object,
        saveResult: Boolean,
        handleMessage:String,
        getPreviewDataTmp:Object,
        getPreviewData : Object,
        getResourceData:Object,
        checkPlanning:Object
    },
    getters: {
        tblData: state => state.tblData,
        saveResult: state => state.saveResult,
        previewData: state => state.previewData,
        handleMessage:state => state.handleMessage,
        getPreviewDataTmp:state => state.getPreviewDataTmp,
        getPreviewData:state => state.getPreviewData,
        getResourceData:state => state.getResourceData,
        checkPlanning:state => state.checkPlanning
    },
    mutations: {
        setDataTbl(state, payload) {
            state.tblData = payload
        },
        setPreviewData(state,payload){
          state.previewData = payload
        },
        setSave(state, result) {
            state.saveResult = result
        },
        setHandleMessage(state,result){
            state.handleMessage = result
        },
        getPreviewDataTmp(state,payload){
          state.getPreviewDataTmp = payload
        },
        getPreviewData(state,payload){
            state.getPreviewData = payload
        },
        getResourceViewData(state,payload){
            state.getResourceData = payload
        },
        checkPlanning(state,payload){
            state.checkPlanning = payload
        },
    },
    actions: {
        async getDataTbl({commit}, {token, sequence,type,machine,sale,customer, search, orderStartDate, orderFinnishDate, page, status, limit, employeeSeq, orderName, woNumber}) {
            try {
                let customURL = `planning/planningListProduction?page=${page}&limit=${limit}&woDate=${orderStartDate}&woDeliver=${orderFinnishDate}&status=${status}&customerSeq=${customer}&employeeSeq=${sale}&woWCode=${orderName}&woNumber=${woNumber}&machineSeq=${machine}`
                if(sequence !== undefined){
                    customURL = `planning/planningListProduction?seq=${sequence}&limit=1&employeeSeq=${employeeSeq}`
                }
                const result = await axiosInstance.get(customURL, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            } catch (error: any) {
                commit('setSave', false);
            }
        },
       async previewData({commit}, {token, payload}) {
            try{
                const result = await axiosInstance.post(`planning/calculate`, payload,{
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setPreviewData',result.data)
                }else{
                    commit('setPreviewData',false)
                }
            }catch (error:any) {
                commit('setPreviewData', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
       async getPreviewDataTmp({commit}, {token, woSeq , woNumber}) {
            try{
                const result = await axiosInstance.get(`planning/tablePlanningTime?woSeq=${woSeq}&woNumber=${woNumber}`,{
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('getPreviewDataTmp',result.data)
                }else{
                    commit('getPreviewDataTmp',false)
                }
            }catch (error:any) {
                commit('getPreviewDataTmp', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async getPreviewData({commit}, {token, woSeq , woNumber}) {
            try{
                const result = await axiosInstance.get(`planning/tableWoPlanningAndWoSleet?woSeq=${woSeq}&woNumber=${woNumber}`,{
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('getPreviewData',result.data)
                }else{
                    commit('getPreviewData',false)
                }
            }catch (error:any) {
                commit('getPreviewData', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async createData({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`planning/create`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave',false)
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async updateData({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.put(`planning/update`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })

                if(result.status === 200){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave',false)
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async deleteData({commit}, {token, seq}) {
            try {
                const result = await axiosInstance.delete(`planning?seq=${seq}`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async getResourceView({commit}, {token,machineCode}) {
            try{
                const result = await axiosInstance.get(`planning/resourceView?machineCode=${machineCode}`,{
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('getResourceViewData',result.data)
                }else{
                    commit('getResourceViewData',false)
                }
            }catch (error:any) {
                commit('getResourceViewData', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async checkPlanning({commit}, {token, seq}) {
            try{
                const result = await axiosInstance.get(`planning/checkPlanning?seq=${seq}`,{
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('checkPlanning',result.data)
                }else{
                    commit('checkPlanning',false)
                }
            }catch (error:any) {
                commit('checkPlanning', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },

        async updateByResourceView({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.put(`planning/updatePrintByResoureceView`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })

                if(result.status === 200){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave',false)
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
    },
    modules: {}
})
