<template>
  <div class="modal fade" id="modal-default" ref="uploadPictureModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">รูปภาพ</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>รายละเอียดชิ้นงาน</label>
                  <textarea v-model="formData.detail" type="text" class="form-control"
                            placeholder="เพิ่มรายละเอียด"></textarea>
                  <span class="text-danger">{{ validateResult.detail }}</span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <div v-if="item.photoOne !== ''" class="image-container">
                    <img v-for="image in images" :key="image" :src="getImageSource(image)" alt="Uploaded Image"
                         class="set-image"/>
                  </div>
                  <div v-else>
                    <div v-if="images.length > 0" class="image-container">
                      <img v-for="image in images" :key="image" :src="getImageSource(image)" alt="Uploaded Image"
                           class="set-image"/>
                    </div>
                    <div class="blank-image" v-else>
                      <i class="fas fa-image"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <input type="file" ref="fileInput" multiple @change="handleFileInputChange" accept="image/jpeg"
                         style="display: none"/>
                  <button :disabled="isLoading" type="button" @click="uploadImages()"
                          class="btn btn-primary centered-button">อัปโหลดรูปภาพ
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="handleCancel()" class="btn btn-default float-right">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import loginStore from "@/store/loginStore";
import packingStore from "@/store/packingStore";
import * as Yup from "yup";
import environmentStore from "@/store/environmentStore";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "UploadPictureModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        images: [],
        imgTemp: [],
        files: [],
        baseurl: environmentStore.getters.upload,
        validateResult: {
          detail: ''
        },
        formData: {
          detail: ''
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        detail: Yup.string().required('กรุณาระบุรายละเอียด'),
      });
    },
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item.photoOne) {
            this.images[0] = this.item.photoOne;
          }
          if (this.item.photoOne && this.item.photoTwo) {
            this.images[0] = this.item.photoOne;
            this.images[1] = this.item.photoTwo;
          }
          if (this.item.photoOne && this.item.photoTwo && this.item.photoThree) {
            this.images[0] = this.item.photoOne;
            this.images[1] = this.item.photoTwo;
            this.images[2] = this.item.photoThree;
          }
          this.formData.detail = this.item['stockRemark'];
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    },
  },
  mounted() {
    try {
      console.log('this is baseurl : '+this.baseurl);
      this.token = loginStore.getters.token
    } catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.uploadPictureModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    getImageSource(image) {
      try {
        console.log(this.baseurl + image)
        if (image.startsWith('/upload')) {
          console.log('this is baseurl img : '+this.baseurl + image);
          return this.baseurl + image;
        } else {
          return image;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.isLoading = true
        this.formSchema.validate(this.formData, {abortEarly: false}).then(async () => {
              const token = this.token
              const payload = new FormData();
              payload.append('seq', this.item.stockSeq);
              payload.append('stockRemark', this.formData.detail);
              this.files.forEach(file => {
                payload.append('images', file);
              });
              await packingStore.dispatch('uploadImage', {token, payload})
              const result = packingStore.getters.saveResult
              this.isLoading = false
              if (result) {
                this.successFunction()
              } else {
                this.errorFunction()
              }
            }
        ).catch((err) => {
          this.isLoading = false
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.uploadPictureModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = packingStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.files = [],
            this.images = [],
            this.formData = {
              detail: ''
            }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertUploadImgFunction() {
      try {
        this.images = this.imgTemp
        this.$swal.fire({
          title: 'รูปภาพเกินจำนวน',
          text: 'อัปโหลดรูปภาพเกินจำนวนจำกัด (สูงสุด 3 รูปภาพ)',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    alertTypeFile() {
      try {
        this.$swal.fire({
          title: 'อัปโหลดไฟล์ผิดประเภท',
          text: 'กรุณาอัปโหลดเฉพาะไฟล์รูปภาพ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFileTypeError() {
      try {
        this.$swal.fire({
          title: 'อัปโหลดไฟล์ผิดประเภท',
          text: 'กรุณาอัปโหลดไฟล์ .jpg .jpeg',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handleFileInputChange(event) {
      try {
        this.imgTemp = this.images
        const files = event.target.files;

        this.isLoading = true
        if (this.images.length !== 0) {
          this.images = this.images.filter(item => !item.startsWith('/upload'));
        }
        if (files.length === 0) {
          this.images = this.imgTemp
        }
        for (let i = 0; i < 3; i++) {
          if (files[i] && !files[i].type.startsWith('image/')) {
            this.alertTypeFile()
            event.target.value = '';
          } else {
            if (this.images.length > 3 || files.length > 3) {
              this.alertUploadImgFunction()
              break;
            }
            if (!files[i] || !files[i].name) {
              continue;
            }
            const extension = files[i].name.split('.').pop().toLowerCase();
            if (extension !== 'jpg' && extension !== 'jpeg') {
              this.alertFileTypeError();
              event.target.value = '';
              return;
            }
            const fileReader = new FileReader();
            if (files[i] instanceof Blob) {
              fileReader.readAsDataURL(files[i]);
              fileReader.onload = () => {
                if (this.images.length < 3) {
                  this.images.push(fileReader.result);
                  this.files.push(files[i]);
                } else {
                  this.alertUploadImgFunction();
                }
              };
            }
          }
        }
        this.isLoading = false
      } catch (e) {
        throw new Error(e);
      }
    },
    uploadImages() {
      try {
        this.$refs.fileInput.click();
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel() {
      try {
        this.resetFormData();
        $(this.$refs.uploadPictureModal).modal('hide');
        this.$emit('form-submitted')
      } catch (e) {
        throw new Error(e);
      }
    }
  },
}
</script>

<style scoped></style>
    