<template>
    <Content>
        <div class="content-header">
            <div class="container-fluid">
                <Loading v-if="isLoading"/>
                <div class="row mb-3">
                  <div class="col-sm-12">
                    <Header/>
                  </div>
                    <div class="col-sm-10">
                        <h1 class="m-0">ประวัติแพ๊คกิ้ง</h1>
                    </div>
                    <div class="col-sm-2">
                        <packingHistory-Modal @form-submitted="getData(currentPage)" ref="packingHistoryModal"
                            :item="rowOnClick" />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <div class="card card-primary">
                            <form>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label>ลูกค้า</label>
                                                        <select class="select form-control"
                                                            v-model="formData.woCustomerName">
                                                            <option value="">เลือกลูกค้า</option>
                                                            <option v-for="(item) in selectCustomer">
                                                                {{ item['customerName'] }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label>ชื่องาน</label>
                                                        <input v-model="formData.woName" type="text" class="form-control"
                                                            placeholder="ค้นหาชื่องาน">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label>ขนาด</label>
                                                        <select class="select form-control" v-model="formData.woMole">
                                                            <option value="">เลือกขนาด</option>
                                                            <option v-for="(item) in selectMole">
                                                                {{ item['wo_mole'] }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label>วันที่</label>
                                                        <input v-model="formData.createDate" type="date"
                                                            class="form-control" placeholder="dd/mm/yy">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-2" style="padding-top: 3%">
                                            <button @click.prevent="getData(1)" class="btn btn-primary btn-block"
                                                type="submit">ค้นหา</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body p-0">
                                <table class="table table-sm" style="text-align: center;">
                                    <thead>
                                        <tr>
                                            <th style="width: 20%">ประเภท</th>
                                            <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('customerName')">ลูกค้า
                                                <i :class="`fa fa-arrow-${sortTable.column === 'customerName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                            </th>
                                            <th class="on-pointer" style="width: 20%" @click.prevent="handleSortData('woName')">ชื่องาน
                                                <i :class="`fa fa-arrow-${sortTable.column === 'woName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                            </th>
                                            <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('woMole')">ขนาด
                                                <i :class="`fa fa-arrow-${sortTable.column === 'woMole' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                            </th>
                                            <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('createDate')">วันที่
                                                <i :class="`fa fa-arrow-${sortTable.column === 'createDate' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                            </th>
                                            <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('empName')">พนักงาน
                                                <i :class="`fa fa-arrow-${sortTable.column === 'empName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="rowHovered tr-Adjust" v-for="(item) in tblData"
                                            @click="handleRowClick(item)">
                                            <td
                                                :class="item['typeStock'] === 'รับเข้า' ? 'planText' : item['typeStock'] === 'ส่งออก' ? 'approveText' : 'rejectText'">
                                                {{
                                                    item['typeStock'] }}</td>
                                            <td>{{ item['customerName'] }}</td>
                                            <td>{{ item['woName'] }}</td>
                                            <td>{{ item['woMole'] }}</td>
                                            <td>{{ item['createDate'] }}</td>
                                            <td>{{ item['empName'] }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1
                                    ถึง {{ allShow <= 10 ? allShow : 10 }} ของ {{ allShow }} ทั้งหมด </div>
                                </div>
                                <div class="col-sm-12 col-md-7 ">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination">
                                            <li class="paginate_button page-item previous">
                                                <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                                                    ก่อนหน้า
                                                </button>
                                            </li>
                                            <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                                                v-for="page in pages" :key="page">
                                                <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                                            </li>
                                            <li class="paginate_button page-item previous">
                                                <button @click="nextPage()" :disabled="currentPage >= allPage[8]"
                                                    class="page-link">ถัดไป
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Content>
</template>
      
  
<script>
import Content from "@/components/Content.vue";
import historyPackingStore from "@/store/historyPackingStore";
import PackingHistoryModal from "@/components/packingHistory/PackingHistoryModal.vue";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import moment from 'moment';
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";

export default {
    name: "PackingHistory",
    components: {Header, Loading, PackingHistoryModal, Content },
    data() {
        try {
            return {
                sortTable:{column:null,sort:'asc',icon:'up'},
                isLoading: false,
                tblData: null,
                pageSize: 0,
                allPage: 0,
                currentPage: 1,
                allShow: 0,
                showModal: false,
                permission: loginStore.getters.pagePermission,
                rowOnClick: {},
                token: null,
                selectCustomer: null,
                selectMole: null,
                formData: {
                    woCustomerName: '',
                    woName: '',
                    woMole: '',
                    createDate: '',
                }
            }
        } catch (e) {
            throw new Error(e);
        }
    },
    async mounted() {
        try {
            this.token = loginStore.getters.token
            await this.getData(1)
            this.getCustomer()
            this.getMole()
        } catch (e) {
            throw new Error(e);
        }
    },
    computed: {
        pages() {
            try {
                const totalPages = Math.ceil(this.allShow / this.pageSize);
                const maxPagesToShow = 9;
                const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

                if (totalPages <= maxPagesToShow) {
                    const result = Array.from({ length: totalPages }, (_, i) => i + 1);
                    this.allPage = result;
                    return result;
                } else {
                    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
                    let startPage;
                    let endPage;

                    if (currentPage <= halfMaxPagesToShow + 1) {
                        startPage = 1;
                        endPage = maxPagesToShow;
                    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
                        startPage = totalPages - maxPagesToShow + 1;
                        endPage = totalPages;
                    } else {
                        startPage = currentPage - halfMaxPagesToShow;
                        endPage = currentPage + halfMaxPagesToShow;
                    }

                    const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
                    this.allPage = result;
                    return result;
                }
            } catch (e) {
                throw new Error(e);
            }
        },
    },
    methods: {
        handleSortData(column) {
            this.sortTable.column = column
            this.sortTable.sort =  this.sortTable.sort === 'desc'? 'asc':"desc"
            this.sortTable.icon = this.sortTable.sort === 'desc'? 'down':"up"
            const data = this.tblData
            const actionData = []
            for(let item of data){
                if(item[column] === null){
                item[column] = ''
                }
                actionData.push(item)
            }
            this.tblData = actionData.sort((a, b) => {
                if (this.sortTable.sort === "desc") {
                return a[column].localeCompare(b[column]);
                } else {
                return b[column].localeCompare(a[column]);
                }
            })
        },
        handleRowClick(data) {
            try {
                this.rowOnClick = data
                if (data.typeStock) {
                    this.$refs.packingHistoryModal.handleModal();
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        async getData(page) {
            try {
                this.isLoading = true
                const payload = {
                    token: this.token,
                    page: page,
                    woCustomerName: this.formData.woCustomerName,
                    woName: this.formData.woName,
                    woMole: this.formData.woMole,
                    createDate: this.convertDateFormat(this.formData.createDate)
                }
                await historyPackingStore.dispatch('getDataTbl', payload)
                this.tblData = historyPackingStore.getters.tblData['stockLst']
                this.pageSize = historyPackingStore.getters.tblData['limit']
                this.allShow = historyPackingStore.getters.tblData['count']
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
                throw new Error(e);
            }
        },
        prevPage() {
            try {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.getData(this.currentPage, 10)
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        nextPage() {
            try {
                if (this.currentPage < this.allPage[8]) {
                    this.currentPage++;
                    this.getData(this.currentPage, 10)
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        goToPage(page) {
            try {
                this.currentPage = page;
                this.getData(this.currentPage, 10)
            } catch (e) {
                throw new Error(e);
            }
        },
        successFunction() {
            try {
                this.$swal.fire({
                    title: 'ลบข้อมูลสำเร็จ',
                    text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
                    icon: 'success',
                    confirmButtonText: 'ยืนยัน'
                }).then(() => {
                    this.getData(this.currentPage)
                })
            } catch (e) {
                throw new Error(e);
            }
        },
        convertDateFormat(item) {
            try {
                if (item) {
                    return moment(item, 'YYYY-MM-DD').format('DD/MM/YYYY');
                }
                return '';
            } catch (e) {
                throw new Error(e);
            }
        },
        async getCustomer() {
            try {
                const payload = {
                    token: this.token
                }
                await masterStore.dispatch('packingHistorySearch', payload)
                this.selectCustomer = masterStore.getters.packingHistoryCustomerSearch
            } catch (e) {
                throw new Error(e);
            }
        },
        async getMole() {
            try {
                const payload = {
                    token: this.token
                }
                await masterStore.dispatch('packingHistorySearch', payload)
                this.selectMole = masterStore.getters.packingHistoryMoleSearch
            } catch (e) {
                throw new Error(e);
            }
        }
    }
}
</script>
  
<style scoped></style>