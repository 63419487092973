<template>
  <div class="wrapper">
    <Navbar/>
    <Sidebar/>
    <div class="content-wrapper">
      <div v-if="currentPath === `/`" class="content-header">
        <div class="container-fluid">
          <h1 class="mb-2">Dashboard</h1>
          <div class="col-sm-12 mb-2 bg-secondary bg-light">
            <label class="pt-1">Resource View</label>
          </div>
          <FullCalender item="" option="d"/>
        </div>
      </div>
      <router-view/>
    </div>
    <footer class="main-footer">
      <div class="float-right d-none d-sm-block">
        <b>Version</b> {{ version }}
      </div>
      <strong>SiamNeo</strong> All rights reserved.
    </footer>

  </div>
</template>

<script>
import Navbar from "@/layouts/inc/Navbar.vue";
import Sidebar from "@/layouts/inc/Sidebar.vue";
import FullCalender from "@/components/resourceCalendar/FullCalender.vue";

export default {
  name: "MainLayout",
  components: {
    FullCalender,
    Navbar,
    Sidebar
  },
  data() {
    return {
      manuData: '',
      version: ''
    }
  },
  computed: {
    currentPath() {
      return this.$route['path'];
    },
  },
}
</script>

<style scoped>

</style>
