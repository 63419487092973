import {createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized} from 'vue-router'
import MainLayout from "../layouts/MainLayout.vue";
import Login from "@/views/login.vue";
import customer from "@/views/Customer.vue";
import customerGroup from "@/views/CustomerGroup.vue";
import loginStore from "@/store/loginStore";
import healthCheckStore from "@/store/healthCheckStore";
import sidebarMenuStore from "@/store/sidebarMenuStore";
import material from "@/views/Material.vue";
import employee from "@/views/Employee.vue";
import holiday from "@/views/Holiday.vue";
import workOrder from "@/views/WorkOrder.vue";
import machine from "@/views/Machine.vue";
import machineMA from "@/views/MachineMA.vue";
import packing from "@/views/Packing.vue";
import calendarProduction from "@/views/CalendarProduction.vue";
import workOrderSelection from "@/views/WorkOrderSelection.vue";
import productionList from "@/views/ProductionList.vue";
import prepress from "@/views/Prepress.vue";
import storeMaterial from "@/views/StoreMaterial.vue";
import history from "@/views/History.vue";
import packingHistory from "@/views/PackingHistory.vue";
import roleStore from "@/views/RoleStore.vue";
import resourceView from "@/views/ResourceView.vue";
import foundError404 from "@/page/error404.vue"
import foundError500 from "@/page/errorExternal.vue"
import saveprodView from "@/views/SaveprodView.vue";
import savesleetView from "@/views/SavesleetView.vue";
import prodStatus from "@/views/ProdStatus.vue";
import prodHistory from "@/views/ProdHistory.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        component: Login,
        name: 'login'
    },
    {
        path: '/error404',
        component: foundError404,
        name: 'error404'
    },
    {
        path: '/errorExternal',
        component: foundError500,
        name: 'errorExternal'
    },
    {
        path: '/',
        name: 'MainLayout',
        component: MainLayout,
        children: [
            {
                path: '/customer-group',
                name: 'customerGroup',
                component: customerGroup,
            },
            {
                path: '/customer',
                name: 'customer',
                component: customer,
            },
            {
                path: '/material',
                name: 'material',
                component: material,
            },
            {
                path: '/employee',
                name: 'employee',
                component: employee,
            },
            {
                path: '/holiday',
                name: 'holiday',
                component: holiday,
            },
            {
                path: '/workOrder',
                name: 'WorkOrder',
                component: workOrder,
            },
            {
                path: '/machine',
                name: 'Machine',
                component: machine,
            },
            {
                path: '/packing',
                name: 'Packing',
                component: packing,
            },
            {
                path: '/machine-ma',
                name: 'MachineMA',
                component: machineMA,
            },
            {
                path: '/calendarProduction',
                name: 'CalendarProduction',
                component: calendarProduction,
            },
            {
                path: '/workOrderSelection',
                name: 'WorkOrderSelection',
                component: workOrderSelection,
            },
            {
                path: '/productionList',
                name: 'ProductionList',
                component: productionList,
            },
            {
                path: '/prepress',
                name: 'Prepress',
                component: prepress,
            },
            {
                path: '/storeMaterial',
                name: 'StoreMaterial',
                component: storeMaterial,
            },
            {
                path: '/history',
                name: 'History',
                component: history,
            },
            {
                path: '/packingHistory',
                name: 'PackingHistory',
                component: packingHistory,
            },
            {
                path: '/roleStore',
                name: 'RoleStore',
                component: roleStore,
            },
            {
                path: '/resourceView',
                name: 'ResourceView',
                component: resourceView,
            },
            {
                path: '/saveprodView',
                name: 'SaveprodView',
                component: saveprodView,
            },
            {
                path: '/savesleetView',
                name: 'SavesleetView',
                component: savesleetView,
            },
            {
                path: '/prodStatus',
                name: 'ProdStatus',
                component: prodStatus,
            },
            {
                path: '/prodHistory',
                name: 'ProdHistory',
                component: prodHistory,
            },
        ]
    },

]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to: RouteLocationNormalized, from: any, next: any) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('accessToken');
    await healthCheckStore.dispatch('getHealthCheckStatus')
    const healthCheckResult = healthCheckStore.getters.healthCheckStatus
    if(healthCheckResult === 'bad'){
        if (to.path !== '/errorExternal') {
            next('/errorExternal');
        } else {
            next();
        }
    }else{
        if (authRequired && !loggedIn) {
            next('/login');
        } else {
            if (loggedIn) {
                await loginStore.dispatch('authUser', loggedIn);
                const loginResult = loginStore.getters.result

                if (loginResult) {
                    const payload = {
                        token: loggedIn
                    }
                    await sidebarMenuStore.dispatch('fetchMenu', payload);
                    await loginStore.dispatch('pagePermission', to.path)
                    const manuData = sidebarMenuStore.getters.subMenu
                    const toPath = manuData.filter((item: any) => {
                        const finding = item.filter((f: any) => f.menuPath === to.path)
                        if (finding.length !== 0) {
                            return finding
                        }
                    });
                    if (to.path !== "/") {
                        if (toPath.length === 0) {
                            await router.push('/')
                        }
                    }
                } else {
                    localStorage.removeItem('accessToken')
                    await router.push('/login')
                }
            }
            next();
        }
    }

});
export default router