import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
axiosInstance.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 499;
};
const axiosFormDataInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});
axiosFormDataInstance.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 499;
};
export default createStore({
    state: {
        tblData: Object,
        saveResult: Boolean,
        handleMessage:String
    },
    getters: {
        tblData: state => state.tblData,
        saveResult: state => state.saveResult,
        handleMessage:state => state.handleMessage
    },
    mutations: {
        setDataTbl(state, payload) {
            state.tblData = payload
        },
        setSave(state, result) {
            state.saveResult = result
        },
        setHandleMessage(state,result){
            state.handleMessage = result
        }
    },
    actions: {
        async getDataTbl({commit}, {sequence,token, page, limit, menuSeq, customerSeq, stockWName, sizeMole}) {
            try {
                if (!limit) {
                    limit = 10
                }
                if (!page) {
                    page = 1
                }
                let customURL = `stock/findAll?page=${page}&limit=${limit}&menuSeq=${menuSeq}&status=1&customerSeq=${customerSeq}&stockWName=${stockWName}&sizeMole=${sizeMole}&seq=`
                if(sequence !== undefined){
                    customURL = `stock/findAll?seq=${sequence}&limit=10`
                }
                const result = await axiosInstance.get(customURL, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            }catch (error:any) {
                commit('setUser', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async createStock({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`stock/createStock`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            }catch (error:any) {
                commit('setSave',false)
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async stockIn({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`stock/createStockIn`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            }catch (error:any) {
                commit('setSave',false)
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async stockOut({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`stock/createStockOut`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            }catch (error:any) {
                commit('setSave', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async stockReject({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`stock/createStockReject`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            }catch (error:any) {
                commit('setSave', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async uploadImage({commit}, {token, payload}) {
            try {
                const result = await axiosFormDataInstance.post(`stock/upload/photo`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            }catch (error:any) {
                commit('setSave', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
    },
    modules: {}
})
