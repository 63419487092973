import { createStore } from 'vuex'
export default createStore({
    state: {
        environment: process.env.VUE_APP_API_URL_LOCALHOST,
        upload: process.env.VUE_APP_API_UPLOAD_LOCALHOST
    },
    getters: {
        environment: state => state.environment,
        upload: state => state.upload,
    },
})
