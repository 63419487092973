import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
export default createStore({
    state: {
        customerGroup : [],
        creditForm: [],
        customerGroupFrom: [],
        employeeForm: [],
        materialSearch: [],
        materialForm: [],
        machineSearch: [],
        machineTypeSearch: [],
        machineForm: [],
        machineTypeForm: [],
        machineMaForm: [],
        empMenuDropdown: [],
        holidayDropdownSearch: [],
        workOrderDropdown: [],
        workOrderPlanningDropdown: [],
        workOrderPlanningForm: [],
        holidaySearch: [],
        supplierForm: [],
        materialCodeForm: [],
        storeLocation: [],
        workOrderList: [],
        supplierFormOut: [],
        materialCodeFormOut: [],
        materialCodeSearch: [],
        mtWidth: [],
        empForm: [],
        mtType: [],
        colorDropdown: [],
        packingTypeForm: [],
        packingCustomerSearch: [],
        packingMoleSearch: [],
        packingHistoryCustomerSearch: [],
        packingHistoryMoleSearch: [],
        packingCreateMeasureSearch: [],
        packingCreateCustomerSearch: [],
        statusDropdown: [
            {value: '1', text: 'เปิดใช้งาน'},
            {value: '0', text: 'ปิดใช้งาน'},
            {value: 'all', text: 'ทั้งหมด'},
        ],
        saleDropdown: [],
        customerDropdown: [],
        machineDropdown:[],
        productionPrintForm:[],
        productionSleetForm:[]
    },
    getters: {
        statusDropdown: state => state.statusDropdown,
        customerGroup: state => state.customerGroup,
        creditForm: state => state.creditForm,
        customerGroupFrom: state => state.customerGroupFrom,
        employeeForm: state => state.employeeForm,
        materialSearch: state => state.materialSearch,
        materialForm: state => state.materialForm,
        machineSearch: state => state.machineSearch,
        machineTypeSearch: state => state.machineTypeSearch,
        machineForm: state => state.machineForm,
        machineTypeForm: state => state.machineTypeForm,
        machineMaForm: state => state.machineMaForm,
        empMenuDropdown: state => state.empMenuDropdown,
        holidayDropdownSearch: state => state.holidayDropdownSearch,
        workOrderDropdown: state => state.workOrderDropdown,
        workOrderPlanningDropdown: state => state.workOrderPlanningDropdown,
        workOrderPlanningForm:state => state.workOrderPlanningForm,
        holidaySearch: state => state.holidaySearch,
        supplierForm: state => state.supplierForm,
        empForm: state => state.empForm,
        colorDropdown: state => state.colorDropdown,
        materialCodeForm: state => state.materialCodeForm,
        storeLocation: state => state.storeLocation,
        workOrderList: state => state.workOrderList,
        supplierFormOut: state => state.supplierFormOut,
        materialCodeFormOut: state => state.materialCodeFormOut,
        mtWidth: state => state.mtWidth,
        mtType: state => state.mtType,
        materialCodeSearch: state => state.materialCodeSearch,
        packingTypeForm: state => state.packingTypeForm,
        packingCustomerSearch: state => state.packingCustomerSearch,
        packingMoleSearch: state => state.packingMoleSearch,
        packingHistoryCustomerSearch: state => state.packingHistoryCustomerSearch,
        packingHistoryMoleSearch: state => state.packingHistoryMoleSearch,
        packingCreateMeasureSearch: state => state.packingCreateMeasureSearch,
        packingCreateCustomerSearch: state => state.packingCreateCustomerSearch,
        saleDropdown: state => state.saleDropdown,
        customerDropdown: state => state.customerDropdown,
        machineDropdown: state => state.machineDropdown,
        productionPrintForm: state => state.productionPrintForm,
        productionSleetForm: state => state.productionSleetForm,
        
    },
    mutations: {
        setGetters(state, payload) {
            if (payload.category === 'customerGroup') {
                state.customerGroup = payload.customerGroupLst
            }
            if (payload.category === 'customer') {
                state.creditForm = payload.creditLst
                state.customerGroupFrom = payload.customerGroupLst
                state.employeeForm = payload.employeeLst
            }
            if (payload.category === 'materialSearch') {
                state.materialSearch = payload.materialTypeLst
            }
            if (payload.category === 'materialForm') {
                state.materialForm = payload.materialTypeLst
            }
            if (payload.category === 'machineSearch') {
                state.machineSearch = payload.machineStatusLst
            }
            if (payload.category === 'machineTypeSearch') {
                state.machineTypeSearch = payload.machineTypeLst
            }
            if (payload.category === 'machineForm') {
                state.machineForm = payload.machineStatusLst
            }
            if (payload.category === 'machineTypeForm') {
                state.machineTypeForm = payload.machineTypeLst
            }
            if (payload.category === 'machineMaForm') {
                state.machineMaForm = payload.machineLst
            }
            if (payload.category === 'empMenuDropdown') {
                state.empMenuDropdown = payload.materialTypeLst
            }
            if (payload.category === 'holidayDropdownSearch') {
                state.holidayDropdownSearch = payload.materialTypeLst
            }
            if (payload.category === 'workOrderDropdown') {
                state.workOrderDropdown = payload
            }
            if (payload.category === 'workOrderPlanningDropdown') {
                state.workOrderPlanningDropdown = payload.materialTypeLst
            }
            if (payload.category === 'workOrderPlanningForm') {
                state.workOrderPlanningForm = payload
            }
            if (payload.category === 'holidaySearch') {
                state.holidaySearch = payload
            }
            if (payload.category === 'empForm') {
                state.empForm = payload
            }
            if (payload.category === 'colorDropdown') {
                state.colorDropdown = payload
            }
            if (payload.category === 'storeIn') {
                state.supplierForm = payload.supplierLst
                state.materialCodeForm = payload.materialLst
                state.storeLocation = payload.storeLocaltionLst
            }
            if (payload.category === 'storeOut') {
                state.workOrderList = payload.workOrderList
                state.supplierFormOut = payload.supplierLst
                state.materialCodeFormOut = payload.materialLst
                state.mtWidth = payload.materialWidthLst
            }
            if (payload.category === 'mtType') {
                state.mtType = payload.materialTypeLst
            }
            if (payload.category === 'materialCodeSearch') {
                state.materialCodeSearch = payload.materialLst
            }
            if (payload.category === 'packingTypeForm') {
                state.packingTypeForm = payload.packingTypeLst
            }
            if (payload.category === 'packingSearch') {
                state.packingCustomerSearch = payload.customerLst
                state.packingMoleSearch = payload.sizeMoleLst
            }
            if (payload.category === 'packingHistorySearch') {
                state.packingHistoryCustomerSearch = payload.customerNameLst
                state.packingHistoryMoleSearch = payload.woMoleLst
            }
            if (payload.category === 'packingCreateFormSearch') {
                state.packingCreateMeasureSearch = payload.measureLst
                state.packingCreateCustomerSearch = payload.customerLst
            }
            if (payload.category === 'saleData') {
                state.saleDropdown = payload.employeeLst
            }
            if (payload.category === 'customerData') {
                state.customerDropdown = payload.customerLst
            }
            if (payload.category === 'machineData') {
                state.machineDropdown = payload.machineLst
            }
            if (payload.category === 'productionPrintForm') {
                state.productionPrintForm = payload
            }
            if (payload.category === 'productionSleetForm') {
                state.productionSleetForm = payload
            }
        },
    },
    actions: {
        async customerGroup({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/customer/search`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'customerGroup'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async customer({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/customer/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'customer'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async materialSearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/material/search`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'materialSearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async colorDropdown({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/workOrder/prepress/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'colorDropdown'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async materialForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/material/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'materialForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async machineSearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/machine/search`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'machineSearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async machineTypeSearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/machine/search`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'machineTypeSearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async machineForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/machine/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'machineForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async machineTypeForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/machine/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'machineTypeForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async machineMaForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/machineMa/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'machineMaForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async empMenuDropdown({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/employeeDropdown`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'empMenuDropdown'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async holidayDropdownSearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/holidayDropdownSearch`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'holidayDropdownSearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async workOrderDropdown({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/workOrder/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'workOrderDropdown'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async workOrderPlanningDropdown({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/workOrderPlanningDropdown`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'workOrderPlanningDropdown'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async workOrderPlanningForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/workOrder/planning/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'workOrderPlanningForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async holidaySearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/holiday/search`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'holidaySearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async storeIn({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/store/in/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'storeIn'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async storeOut({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/store/out/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'storeOut'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async empForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/employee/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'empForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async mtType({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/store/search/history`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'mtType'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async materialCodeSearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/store/search`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'materialCodeSearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async packingTypeForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/stock/in/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'packingTypeForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async packingSearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/stock/search`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'packingSearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async packingHistorySearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/stock/search/history`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'packingHistorySearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async packingCreateFormSearch({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/stock/create/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'packingCreateFormSearch'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async saleData({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/employee/search/bar/dropdown`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })                
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'saleData'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async customerData({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/customer/search/bar/dropdown`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })                
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'customerData'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async machineData({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/machine/search/bar/dropdown`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'machineData'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async productionPrintForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/production/machine/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'productionPrintForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
        async productionSleetForm({commit}, {token}) {
            try {
                const result = await axiosInstance.get(`masterTable/production/sleet/form`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    const commitData = result.data
                    commitData.category = 'productionSleetForm'
                    commit('setGetters', commitData)
                }
            } catch (error) {
                commit('setGetters', false);
            }
        },
    },
    modules: {},
});
