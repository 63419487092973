<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <Loading v-if="isLoading"/>
        <div class="row mb-3">
          <div class="col-sm-12">
            <Header/>
          </div>
          <div class="col-sm-10">
            <h1 class="m-0">คลังวัสดุ</h1>
          </div>
          <div class="col-sm-2">
            <button :disabled="permission.epCreate === '0'" type="button" @click="handleRowClick({}, 1)"
              class="btn btn-danger float-right">
              จ่ายออก
            </button>
            <button :disabled="permission.epCreate === '0'" type="button" @click="handleRowClick({}, 0)"
              class="btn btn-success float-right" style="margin-right: 3%;">
              เพิ่มเข้า
            </button>
            <storeMaterialAdd-modal @form-submitted="getData(currentPage, 10)" ref="storeMaterialAddModal"
              :item="rowOnClick" />
            <storeMaterialReject-modal @form-submitted="getData(currentPage, 10)" ref="storeMaterialRejectModal"
              :item="rowOnClick" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>Supplier</label>
                        <input v-model="formData.supplier" type="text" class="form-control" placeholder="ค้นหา supplier">
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>Amount</label>
                        <input v-model="formData.amount" type="text" class="form-control" placeholder="ค้นหา amount">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>รหัสสินค้า</label>
                        <input v-model="formData.mtName" type="text" class="form-control" placeholder="ค้นหารหัสสินค้า">
                      </div>
                    </div>
                    <div class="form-group col-sm-1" style="padding-top: 2%">
                      <button @click.prevent="getData(1)" class="btn btn-primary btn-block"
                        type="submit">ค้นหา</button>
                      <button @click.prevent="toExport()" class="btn btn-outline-primary btn-block" type="submit">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <table class="table table-sm" style="text-align: center;">
                  <thead>
                    <tr>
                      <th class="on-pointer" @click.prevent="handleSortData('supplier')">Supplier
                        <i :class="`fa fa-arrow-${sortTable.column === 'supplier' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('materialName')">รหัสสินค้า
                        <i :class="`fa fa-arrow-${sortTable.column === 'materialName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('storeWidth')">หน้ากว้าง
                        <i :class="`fa fa-arrow-${sortTable.column === 'storeWidth' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('storeAmount')">Amount
                        <i :class="`fa fa-arrow-${sortTable.column === 'storeAmount' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('slName')">ตำแหน่งจัดเก็บ
                        <i :class="`fa fa-arrow-${sortTable.column === 'slName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="rowHovered tr-Adjust" v-for="(item) in tblData" @click="handleRowClick(item, 2)">
                      <td>{{ item['supplier'] }}</td>
                      <td>{{ item['materialName'] }}</td>
                      <td>{{ item['storeWidth'] }}</td>
                      <td>{{ item['storeAmount'] }}</td>
                      <td>{{ item['slName'] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1 ถึง {{ allShow <=
                  10 ? allShow : 10 }} ของ {{ allShow }} ทั้งหมด </div>
                </div>
                <div class="col-sm-12 col-md-7 ">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination">
                      <li class="paginate_button page-item previous">
                        <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                          ก่อนหน้า
                        </button>
                      </li>
                      <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                        v-for="page in pages" :key="page">
                        <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                      </li>
                      <li class="paginate_button page-item previous">
                        <button @click="nextPage()" :disabled="currentPage >= allPage[8]" class="page-link">ถัดไป
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Content>
</template>
    

<script>
import Content from "@/components/Content.vue";
import storeMaterialStore from "@/store/storeMaterialStore";
import loginStore from "@/store/loginStore";
import StoreMaterialAddModal from "@/components/storeMaterial/StoreMaterialAddModal.vue";
import StoreMaterialRejectModal from "@/components/storeMaterial/StoreMaterialRejectModal.vue";
import masterStore from "@/store/MasterStore";
import moment from 'moment';
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";

export default {
  name: "StoreMaterial",
  components: {Header, Loading, StoreMaterialAddModal, StoreMaterialRejectModal, Content },
  data() {
    try {
      return {
        sortTable:{column:null,sort:'asc',icon:'up'},
        isLoading: false,
        tblData: null,
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        showModal: false,
        permission: loginStore.getters.pagePermission,
        rowOnClick: {},
        selectMaterial: null,
        token: null,
        formData: {
          supplier: '',
          amount: '',
          mtName: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  async mounted() {
    try {
      this.token = loginStore.getters.token
      await this.getData(1, 10)
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({ length: totalPages }, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  methods: {
    handleSortData(column) {
      this.sortTable.column = column
      this.sortTable.sort =  this.sortTable.sort === 'desc'? 'asc':"desc"
      this.sortTable.icon = this.sortTable.sort === 'desc'? 'down':"up"
      const data = this.tblData
      const actionData = []
      for(let item of data){
        if(typeof item[column] === "number"){
          item[column] = item[column].toString()
        }
        if(item[column] === null){
          item[column] = ''
        }
        actionData.push(item)
      }
      this.tblData = actionData.sort((a, b) => {
        if (this.sortTable.sort === "desc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      })
    },
    async handleRowClick(data, type) {
      try {
        await this.getData(this.currentPage)
        data['type'] = type
        this.rowOnClick = data
        if (this.permission.epCreate === '1' && type === 0) {
          this.$refs.storeMaterialAddModal.handleModal();
        }
        else if (this.permission.epCreate === '1' && type === 1) {
          this.$refs.storeMaterialRejectModal.handleModal();
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData(page) {
      try {
        this.isLoading = true
        const payload = {
          token: this.token,
          page: page,
          suplier: this.formData.supplier,
          amount: this.formData.amount,
          materialName: this.formData.mtName
        }
        await storeMaterialStore.dispatch('getDataTbl', payload)
        this.tblData = storeMaterialStore.getters.tblData['storeLst']
        this.pageSize = storeMaterialStore.getters.tblData['limit']
        this.allShow = storeMaterialStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'ลบข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage)
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    convertDateFormat(item) {
      try {
        if (item) {
          return moment(item, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        return '';
      } catch (e) {
        throw new Error(e);
      }
    },
    getYear() {
      try {
        const now = new Date();
        const year = now.getFullYear();
        return year;
      } catch (e) {
        throw new Error(e);
      }
    }
  }
}
</script>

<style scoped></style>