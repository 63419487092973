<template>
    <div class="modal fade" id="modal-default" ref="productionPlanListViewModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">รายการแผนผลิต</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="submitForm">
                    <div class="modal-body">
                        <div class="card card-primary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 mb-2">
                                        <b>ทั่วไป</b>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">หมายเลข Work Order</span><br>
                                        <span>{{ item.woNumber }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ชื่องาน</span><br>
                                        <span class="text-bold front-color-custom">{{ item.woNumber }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ชื่อลูกค้า</span><br>
                                        <span>{{ item.ctmName }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ประเภทงาน</span><br>
                                        <span>{{ item.wotName }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">กำหนดส่งงาน</span><br>
                                        <span>{{ changeDateFormat(item.woDeliverDate) }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">พนักงานขาย</span><br>
                                        <span>{{ item.empName }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-12">
                                        <span class="inActiveText">หมายเหตุ</span><br>
                                        <span>{{ item.woRemark === null ? '-' : item.woRemark }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-primary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 mb-2">
                                        <b>กำหนดค่าการผลิต</b>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ประเภทกระดาษ</span><br>
                                        <span>{{ item.materialName }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ปรุฉีก</span><br>
                                        <span>{{ item.perforationName }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">วานิช</span><br>
                                        <span>{{ item.varnishName }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ขนาด (ต x น)</span><br>
                                        <span>{{ item.woSizeV }}</span>
                                        <span> X </span>
                                        <span>{{ item.woSizeH }}</span>
                                        <span> มม </span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">หน้ากว้างกระดาษ</span><br>
                                        <span>{{ changeSizePaper(item.woSide) }}</span>
                                        <span> ซม </span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ไดคัท</span><br>
                                        <span>{{ item.diecutName }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-2">
                                        <span class="inActiveText">วางแผนแล้ว</span><br>
                                        <span>{{ item.planning ? item.planning.plPlanAhead : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-2">
                                        <span class="inActiveText">จำนวนผลิตจริง</span><br>
                                        <span>{{ item.planning ? item.planning.plProdAmount : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-2">
                                        <span class="inActiveText">จำนวน</span><br>
                                        <span>{{ item.planning ? item.planning.plAmount : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-3">
                                        <span class="inActiveText">จำนวนดวงพิมพ์ต่อแถว</span><br>
                                        <span>{{ item.planning ? item.planning.plStartRowPrint : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-3">
                                        <span class="inActiveText">จำนวนบล็อคพิมพ์</span><br>
                                        <span>{{ item.planning ? item.planning.plBlockAmount : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-2">
                                        <span class="inActiveText">จำนวนดวงต่อแถว</span><br>
                                        <span>{{ item.planning ? item.planning.plStarRow : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-2">
                                        <span class="inActiveText">ขอบข้าง</span><br>
                                        <span>{{ item.planning ? item.planning.plSide : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-2">
                                        <span class="inActiveText">ช่องไฟ</span><br>
                                        <span>{{ item.planning ? item.planning.plGab : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-3">
                                        <span class="inActiveText">สี</span><br>
                                        <span>{{ item.planning ? item.planning.colorCode : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-3">
                                        <span class="inActiveText">หมายเหตุสี</span><br>
                                        <span>{{ item.planning ? item.planning.plColorDescript : '-' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card card-primary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 mb-2">
                                        <b>เครื่องจักร</b>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-6">
                                        <span class="inActiveText">เครื่องจักร</span><br>
                                        <span>{{ item.machineCode }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-6">
                                        <span class="inActiveText">เวลาเครื่องวิ่ง</span><br>
                                        <span>{{ item.planning ? item.planning.plMachineRunning : '-' }}</span>
                                        <span> ชม. </span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">เวลาตั้งงาน (ประมาณ)</span><br>
                                        <span>{{ item.planning ? item.planning.plStartTime : '-' }}</span>
                                        <span> ชม. </span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ความเร็ว (Stroke/Min)</span><br>
                                        <span>{{ item.planning ? item.planning.plSpeedMin : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">เวลาตัดม้วนต่อวัน</span><br>
                                        <span>{{ item.planning ? item.planning.plRole : '-' }}</span>
                                        <span> ชม. </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card card-primary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 mb-2">
                                        <b>สลีต</b>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-6">
                                        <span class="inActiveText">เครื่องจักร</span><br>
                                        <span>{{ item.planning ? item.planning.machineSleetSeq : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-6">
                                        <span class="inActiveText">ความเร็ว (Stroke/Min)</span><br>
                                        <span>{{ item.planning ? item.planning.plSpeedMinSleet : '-' }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-6">
                                        <span class="inActiveText">เวลาสลีต</span><br>
                                        <span>{{ item.planning ? item.planning.plSleetRunning : '-' }}</span>
                                        <span> ชม. </span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-6">
                                        <span class="inActiveText">เวลาสลีต (ประมาณ)</span><br>
                                        <span>{{ item.planning ? item.planning.plSleetEstimation : '-' }}</span>
                                        <span> ชม. </span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-6">
                                        <span class="inActiveText">เวลาเริ่มงาน (จริง)</span><br>
                                        <span>{{ item.planning ? changeDateFormat(item.planning.plSleetStart) : '-'
                                        }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-6">
                                        <span class="inActiveText">เวลาปิดงาน (จริง)</span><br>
                                        <span>{{ item.planning ? changeDateFormat(item.planning.plSleetFinish) : '-'
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-primary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 mb-2 ">
                                        <b>วัตถุดิบ</b>
                                    </div>
                                    <div class="col-sm-7">
                                        <div class="row">
                                            <div style="padding-left: 3%" class="col-sm-6">
                                                <span class="inActiveText">ราคาวัตถุดิบ(บาท/ตรม.)</span><br>
                                                <span>{{ item.planning ? item.planning.machineSleetSeq : '-' }}</span>
                                            </div>
                                            <div style="padding-left: 3%" class="col-sm-6">
                                                <span class="inActiveText">ความยาววัตถุดิบที่ใช้(คำนวน)</span><br>
                                                <span>{{ item.planning ? item.planning.plSpeedMinSleet : '-' }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div style="padding-left: 3%" class="col-sm-12">
                                            <span class="inActiveText">หมายเหตุ</span><br>
                                            <span>{{ item.planning ? item.planning.plSpeedMinSleet : '-' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-7">
                                        <div class="row">
                                            <div style="padding-left: 3%" class="col-sm-6">
                                                <span class="inActiveText">ราคาหมึกพิมพ์</span><br>
                                                <span>{{ item.planning ? item.planning.plSleetRunning : '-' }}</span>
                                                <span> ชม. </span>
                                            </div>
                                            <div style="padding-left: 3%" class="col-sm-5">
                                                <span class="inActiveText">วัตถุดิบที่ใช้</span><br>
                                                <span>{{ item.planning ? item.planning.plSleetEstimation : '-' }}</span>
                                                <span> ชม. </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 mb-2 bg-secondary bg-light">
                            <b>ระยะเวลาการผลิต</b>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="reduceHeadText">เวลาเริ่มงาน(จริง)</label>
                                    <input type="text" class="form-control" placeholder="ราคาวัตถุดิบ">
                                    <span class="text-danger"></span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="reduceHeadText">เวลาปิดงาน(จริง)</label>
                                    <input type="text" class="form-control" placeholder="ราคาวัตถุดิบ">
                                    <span class="text-danger"></span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="reduceHeadText">กำหนดส่งงาน(จริง)</label>
                                    <input type="text" class="form-control" placeholder="ราคาวัตถุดิบ">
                                    <span class="text-danger"></span>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label class="reduceHeadText">ราคาหมึกพิมพ์</label>
                                    <input type="text" class="form-control" placeholder="ราคาวัตถุดิบ">
                                    <span class="text-danger"></span>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label class="reduceHeadText">วัตถุดิบที่ใช้</label>
                                    <input type="text" class="form-control" placeholder="ราคาวัตถุดิบ">
                                    <span class="text-danger"></span>
                                </div>
                            </div>
                            <div class="col-sm-1 align-self-end">
                                <div class="form-group">
                                    <label class="reduceHeadText">ตร.ม.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default float-right" data-dismiss="modal">ยกเลิก</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import loginStore from "@/store/loginStore";

export default {
    name: "ProductionPlanListViewModal",
    props: ['item'],
    data() {
        try {
            return {}
        } catch (e) {
            throw new Error(e);
        }
    },
    methods: {
        handleModal() {
            try {
                $(this.$refs.productionPlanListViewModal).modal('show');
            } catch (e) {
                throw new Error(e);
            }
        },
        async submitForm() {
            try {

            } catch (e) {
                throw new Error(e);
            }
        },
        changeDateFormat(dateData) {
            try {
                return dateData ? moment(dateData, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
            } catch (e) {
                throw new Error(e);
            }
        },
        changeSizePaper(size) {
            try {
                let centimeter = size / 10;
                return centimeter
            } catch (e) {
                throw new Error(e);
            }
        }
    },
}
</script>

<style scoped>
.front-color-custom {
    color: #007BFF;
}
</style>