<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <Loading v-if="isLoading" />
        <div class="row mb-3">
          <div class="col-sm-12">
            <Header/>
          </div>
          <div class="col-sm-6">
            <h1 class="m-0">วันหยุดประจำปี</h1>
          </div>
          <div class="col-sm-6">
            <button :disabled="permission.epCreate === '0'" type="button" @click="handleRowClick({}, 0)"
              class="btn btn-success float-right">
              เพิ่มวันหยุดประจำปี
            </button>
            <holiday-modal @form-submitted="getData(currentPage, 10)" ref="holidayModal" :item="rowOnClick" />
            <holiday-view-modal @form-submitted="getData(currentPage, 10)" ref="holidayViewModal" :item="rowOnClick" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>ชื่อวันหยุด</label>
                        <input v-model="formData.holidayName" type="text" class="form-control"
                          placeholder="ค้นหาชื่อวันหยุด">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>วันที่</label>
                        <input v-model="formData.holidayDate" type="date" class="form-control" placeholder="dd/mm/yyyy">
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>ปี</label>
                        <select class="select form-control" v-model="formData.holidayYear">
                          <option value="">{{ getYear() }}</option>
                          <option v-for="(item) in selectHolidayYear">{{ item }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>สถานะ</label>
                        <select class="select form-control" v-model="formData.holidayStatus">
                          <option value="" disabled selected hidden>เลือกสถานะ</option>
                          <option v-for="(item) in selectStatus" :value="item.value">{{ item['text'] }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-sm-1 align-self-end">
                      <button @click.prevent="getData(1, 10)" class="btn btn-primary btn-block"
                        type="submit">ค้นหา</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <table class="table table-sm">
                  <thead>
                    <tr>
                    <th style="width: 10%"></th>
                    <th class="on-pointer" style="width: 20%" @click.prevent="handleSortData('holidayName')">ชื่อวันหยุด
                      <i :class="`fa fa-arrow-${sortTable.column === 'holidayName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                    </th>
                    <th class="on-pointer" style="width: 60%" @click.prevent="handleSortData('holidayDate')">วันที่
                      <i :class="`fa fa-arrow-${sortTable.column === 'holidayDate' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                    </th>
                    <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('status')">สถานะ
                      <i :class="`fa fa-arrow-${sortTable.column === 'status' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr class="rowHovered" v-for="(item) in tblData" @click="handleRowClick(item, 1)">
                      <td>
                        <button :disabled="permission.epDelete === '0'" type="button" @click="doDelete(item, $event)"
                          class="btn btn-danger">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{{ item['holidayName'] }}</td>
                      <td>{{ item['holidayDate'] }}</td>
                      <td :class="item['status'] === '1' ? 'activeTextToggle' : 'inActiveTextToggle'">{{ item['status']
                        ===
                        '1' ? "เปิดใช้งาน" : "ปิดใช้งาน" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1 ถึง {{ allShow <=
                  10 ? allShow : 10 }} ของ {{ allShow }} ทั้งหมด </div>
                </div>
                <div class="col-sm-12 col-md-7 ">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination">
                      <li class="paginate_button page-item previous">
                        <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                          ก่อนหน้า
                        </button>
                      </li>
                      <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                        v-for="page in pages" :key="page">
                        <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                      </li>
                      <li class="paginate_button page-item previous">
                        <button @click="nextPage()" :disabled="currentPage >= allPage[8]" class="page-link">ถัดไป
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Content>
</template>
    

<script>
import Content from "@/components/Content.vue";
import holidayStore from "@/store/holidayStore";
import loginStore from "@/store/loginStore";
import HolidayModal from "@/components/holiday/HolidayModal.vue";
import masterStore from "@/store/MasterStore";
import moment from 'moment'
import HolidayViewModal from "@/components/holiday/HolidayViewModal.vue";
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";

export default {
  name: "Holiday",
  components: {Header, Loading, HolidayViewModal, HolidayModal, Content },
  data() {
    try {
      return {
        sortTable:{column:null,sort:'asc',icon:'up'},
        isLoading: false,
        tblData: null,
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        showModal: false,
        permission: loginStore.getters.pagePermission,
        rowOnClick: {},
        selectHolidayYear: null,
        selectStatus: null,
        token: null,
        formData: {
          holidayName: '',
          holidayDate: '',
          holidayYear: '',
          holidayStatus: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  async mounted() {
    try {
      this.token = loginStore.getters.token
      await this.getData(1, 10)
      this.getSelectHolidayYear()
      this.getStatus()
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({ length: totalPages }, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  methods: {
    handleSortData(column) {
      this.sortTable.column = column
      this.sortTable.sort =  this.sortTable.sort === 'desc'? 'asc':"desc"
      this.sortTable.icon = this.sortTable.sort === 'desc'? 'down':"up"
      const data = this.tblData
      this.tblData = data.sort((a, b) => {
        if (this.sortTable.sort === "desc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      })
    },
    async handleRowClick(data, type) {
      try {
        await this.getData(this.currentPage)
        data['type'] = type
        if (type === 1) {
          const dataByID = await this.getDataById(data.seq)
          const dataModal = dataByID[0]
          if (dataByID.length > 0) {
            dataModal.type = type
            this.rowOnClick = dataModal
            if (this.permission.epUpdate === '1' && type === 1) {
              this.$refs.holidayModal.handleModal();
            } else {
              this.$refs.holidayViewModal.handleModal();
            }
          } else {
            await this.getData(this.currentPage)
          }
        } else {
          this.rowOnClick = data
          if (this.permission.epCreate === '1' && type === 0) {
            this.$refs.holidayModal.handleModal();
          } else {
            await this.getData(this.currentPage)
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData(page) {
      try {
        this.isLoading = true
        const payload = {
          token: this.token,
          page: page,
          holidayName: this.formData.holidayName,
          holidayDate: this.convertDateFormat(this.formData.holidayDate),
          holidayYear: this.formData.holidayYear === '' ? this.getYear() : this.formData.holidayYear,
          holidayStatus: this.formData.holidayStatus || "1"
        }
        await holidayStore.dispatch('getDataTbl', payload)
        this.tblData = holidayStore.getters.tblData['holidays']
        this.pageSize = holidayStore.getters.tblData['limit']
        this.allShow = holidayStore.getters.tblData['count']
        this.getSelectHolidayYear();
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getDataById(seq) {
      try {
        this.isLoading = true
        const payload = {
          sequence: seq,
          token: this.token,
          holidayStatus: this.formData.holidayStatus || "1"
        }
        await holidayStore.dispatch('getDataTbl', payload)
        this.isLoading = false
        return holidayStore.getters.tblData['holidays']
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getSelectHolidayYear() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('holidaySearch', payload)
        this.selectHolidayYear = masterStore.getters.holidaySearch
      } catch (e) {
        throw new Error(e);
      }
    },
    async getStatus() {
      try {
        this.selectStatus = masterStore.getters.statusDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    async doDelete(data, event) {
      try {
        event.stopPropagation();
        const payload = {
          token: loginStore.getters.token,
          seq: data.seq
        }
        const resultConfirm = await this.confirmDeleteFunction(payload)
        const result = holidayStore.getters.saveResult
        if (result === true && resultConfirm === true) {
          this.successFunction()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async confirmDeleteFunction(payload) {
      try {
        const result = await this.$swal.fire({
          title: 'ต้องการลบข้อมูลหรือไม่',
          text: 'กดยืนยันเพื่อทำการลบข้อมูล',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })
        if (result.isConfirmed) {
          await holidayStore.dispatch('deleteData', payload)
          return true
        } else {
          return false
        }

      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'ลบข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage)
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    convertDateFormat(item) {
      try {
        if (item) {
          return moment(item, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        return '';
      } catch (e) {
        throw new Error(e);
      }
    },
    getYear() {
      try {
        const now = new Date();
        const year = now.getFullYear();
        return year;
      } catch (e) {
        throw new Error(e);
      }
    }
  }
}
</script>

<style scoped></style>