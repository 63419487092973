<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <Loading v-if="isLoading" />
        <div class="row mb-3">
          <div class="col-sm-12">
            <Header />
          </div>
          <div class="col-sm-6">
            <h1 class="m-0">รายการ Work Order</h1>
          </div>
          <div class="col-sm-6">
            <button :disabled="permission.epCreate === '0'" type="button" @click="handleRowClick({}, 0)"
              class="btn btn-success float-right">
              เพิ่ม Work Order
            </button>
            <WorkOrderModal @form-submitted="getData(currentPage)" ref="workOrderModal" :item="rowOnClick" />
            <ApproveWo @view-submitted="getData(currentPage)" ref="approveWoModal" :item="rowOnClick" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>วันเริ่มต้นสั่งงาน</label>
                            <input type="date" v-model="searchForm.orderStartDate" class="form-control"
                              placeholder="วว/ดด/ปปปป">
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>ชื่องาน</label>
                            <input type="text" v-model="searchForm.orderName" class="form-control"
                              placeholder="ค้นหาชื่องาน">
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>วันสิ้นสุดสั่งงาน</label>
                            <input type="date" v-model="searchForm.orderFinnishDate" class="form-control"
                              placeholder="วว/ดด/ปปปป">
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>หมายเลข</label>
                            <input type="text" v-model="searchForm.woNumber" class="form-control"
                              placeholder="ค้นหาหมายเลข work order">
                          </div>
                        </div>
                        <div class="col-sm-6" v-if="currentEmpRole.roleCode == '003'">
                          <div class="form-group">
                            <label>เซลล์</label>
                            <input type="text" v-model="empData" class="form-control"
                              placeholder="ค้นหาเซลล์" :disabled="isDisabled">
                          </div>
                        </div>
                        <SearchField v-if="currentEmpRole.roleCode != '003'"
                          :item="{ selected: 'sale', other: { condition: 'role', expect: { compareCondition: 'not equal', data: '003' } }, classCondition: 'col-sm-6', emitData: 'sale-field' }"
                          @handle-emit-field="handleSearchEmit" />
                        <SearchField
                          :item="{ selected: 'customer', other: {}, classCondition: 'col-sm-6', emitData: 'customer-field' }"
                          @handle-emit-field="handleSearchEmit" />
                      </div>
                    </div>
                    <div class="form-group col-sm-2" style="padding-top: 2%">
                      <button @click.prevent="getData()" class="btn btn-primary btn-block" type="submit">ค้นหา
                      </button>
                      <button @click.prevent="toExport()" class="btn btn-outline-primary btn-block" type="submit">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 1%;"></th>
                        <th scope="col" style="width: 7%;"></th>
                        <th scope="col" class="to-center on-pointer" @click.prevent="handleSortData('woNumber')">
                          หมายเลข
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woNumber' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('woStatusName')">สถานะ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woStatusName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('ctmName')">ชื่อลูกค้า
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'ctmName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('woWcode')">ชื่องาน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woWcode' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('wotName')">ประเภทงาน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'wotName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('materialName')">กระดาษ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'materialName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('woMole')">ขนาดงาน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woMole' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('empName')">เซลล์
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'empName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('woProdAmount')">จำนวนผลิต
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woProdAmount' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('woPriceUnit')">ราคา
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woPriceUnit' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('woOrderDate')">
                          วันที่เปิด
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woOrderDate' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('woDeliverDate')">
                          วันที่ส่งงาน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woDeliverDate' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th scope="col" class="on-pointer" @click.prevent="handleSortData('woNote')">
                          หมายเหตุ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woNote' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <!-- <th scope="col" class="on-pointer" @click.prevent="handleSortData('status')">สถานะ
                        <i
                          :class="`fa fa-arrow-${sortTable.column === 'status' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                      </th>
                      <th scope="col" class="to-center on-pointer" style="width: 5%;"
                        @click.prevent="handleSortData('machineCode')">เครื่อง
                        <i
                          :class="`fa fa-arrow-${sortTable.column === 'machineCode' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                      </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="rowHovered" v-for="(item) in tblData" :key="item" @click="handleRowClick(item, 1)">
                        <td>
                          <button :disabled="item.employeeSeq !== currentEmpSeq || permission.epDelete !== '1'"
                            @click="doDelete(item, $event)" class="btn btn-danger"><i class="fa fa-trash"></i>
                          </button>
                        </td>
                        <td>
                          <button :disabled="item.employeeSeq !== currentEmpSeq" @click="duplicateWo(item, $event)"
                            class="btn btn-success"><i class="fa fa-copy"></i> คัดลอก
                          </button>
                        </td>
                        <td>{{ item['woNumber'] ? item['woNumber'] : '-' }}</td>
                        <td :class="getStatusColor(item['woStatusName'])">{{
                          item['woStatusName']
                        }}
                        </td>
                        <td>{{ item['ctmName'] }}</td>
                        <td>{{ item['woWcode'] }}</td>
                        <td>{{ item['wotName'] }}</td>
                        <td>{{ item['materialName'] }}</td>
                        <td>{{ item['woMole'] }}</td>
                        <td>{{ item['empName'] }}</td>
                        <td>{{ item['woProdAmount'] }}</td>
                        <td>{{ item['woPriceUnit'] }}</td>
                        <td>{{ changeDateFormat(item['woOrderDate']) }}</td>
                        <td>{{ changeDateFormat(item['woDeliverDate']) }}</td>
                        <td>{{ item['woNote'] ? item['woNote'] : '-' }}</td>
                        <!-- <td :class="item['status'] === '1' ? 'activeTextToggle' : 'inActiveTextToggle'">
                        {{ item['status'] === '1' ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
                      </td>
                      <td>{{ item['machineCode'] }}</td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1
                  ถึง {{ allShow <= 10 ? allShow : 10 }} ของ {{ allShow }} ทั้งหมด </div>
                </div>
                <div class="col-sm-12 col-md-7 ">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination">
                      <li class="paginate_button page-item previous">
                        <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                          ก่อนหน้า
                        </button>
                      </li>
                      <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                        v-for="page in pages" :key="page">
                        <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                      </li>
                      <li class="paginate_button page-item previous">
                        <button @click="nextPage()" :disabled="currentPage >= allPage[8]" class="page-link">ถัดไป
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import WorkOrderModal from "@/components/workOrder/WorkOrderModal.vue";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import workOrderStore from "@/store/workOrderStore";
import moment from "moment/moment";
import ApproveWo from "@/components/workOrder/ApproveWoModal.vue";
import Loading from "@/components/loading/Loading.vue";
import customerStore from "@/store/customerStore";
import Header from "@/components/header/Header.vue";
import SearchField from "@/components/boilerplate/SearchField.vue";

export default {
  name: "WorkOrder",
  components: { SearchField, Header, Loading, ApproveWo, WorkOrderModal, Content },
  data() {
    try {
      return {
        sortTable: { column: null, sort: 'asc', icon: 'up' },
        isLoading: false,
        currentEmpSeq: loginStore.getters.seq,
        currentEmpRole: loginStore.getters.role,
        currentEmpData: loginStore.getters.firstName + ' ' + loginStore.getters.lastName,
        permission: loginStore.getters.pagePermission,
        tblData: null,
        rowOnClick: {},
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        token: loginStore.getters.token,
        statusDropdown: null,
        saleDropdown: null,
        customerDropdown: null,
        isDisabled: false,
        empData: '',
        fieldInclude: {
          sale: 'sale'
        },
        searchForm: {
          orderStartDate: '',
          orderName: '',
          orderFinnishDate: '',
          woNumber: '',
          sale: '',
          customer: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({ length: totalPages }, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  mounted() {
    try {
      this.getStatus()
      this.getSale()
      this.getCustomer()
      this.getData()
      this.setSaleValue()
    } catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    handleSearchEmit(data) {
     switch (data.emitData) {
       case "sale-field" :
         this.searchForm.sale = data.result
         break;
       case "machine-field":
         this.searchForm.machine = data.result
         break;
       case "customer-field" :
         this.searchForm.customer = data.result
         break;
       default :
         this.searchForm.sale = ""
         this.searchForm.machine = ""
         this.searchForm.customer = ""
         break;
     }
    },
    handleSortData(column) {
      this.sortTable.column = column
      this.sortTable.sort = this.sortTable.sort === 'desc' ? 'asc' : "desc"
      this.sortTable.icon = this.sortTable.sort === 'desc' ? 'down' : "up"
      const data = this.tblData
      const actionData = []
      for (let item of data) {
        // console.log(item[column]);
        if (typeof item[column] === "number") {
          item[column] = item[column].toString()
        }
        if (item[column] === undefined) {
          item[column] = ''
        }
        if (item[column] === null) {
          item[column] = ''
        }
        actionData.push(item[column])
      }
      this.tblData = actionData.sort((a, b) => {
        if (this.sortTable.sort === "desc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      })
    },
    async getStatus() {
      try {
        this.statusDropdown = masterStore.getters.statusDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    async getSale() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('saleData', payload)
        this.saleDropdown = masterStore.getters.saleDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    async getCustomer() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('customerData', payload)
        this.customerDropdown = masterStore.getters.customerDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData() {
      try {
        this.isLoading = true
        const payload = {
          token: this.token,
          page: this.currentPage,
          orderStartDate: this.changeDateFormat(this.searchForm.orderStartDate),
          orderName: this.searchForm.orderName,
          orderFinnishDate: this.changeDateFormat(this.searchForm.orderFinnishDate),
          woNumber: this.searchForm.woNumber,
          sale: this.searchForm.sale,
          customer: this.searchForm.customer,
          status: this.searchForm.status ? this.searchForm.status : "1",
          employeeSeq: this.currentEmpRole.roleCode === '003' ? this.currentEmpSeq : '',
          limit: 10
        }
        await workOrderStore.dispatch('getDataTbl', payload)
        this.tblData = workOrderStore.getters.tblData['workOrders']
        this.pageSize = workOrderStore.getters.tblData['limit']
        this.allShow = workOrderStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getDataById(seq) {
      try {
        this.isLoading = true
        const payload = {
          sequence: seq,
          token: this.token,
          status: this.searchForm.status || "1"
        }
        await workOrderStore.dispatch('getDataTbl', payload)
        this.isLoading = false
        return workOrderStore.getters.tblData['workOrders']
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    toExport() {
      try {

      } catch (e) {
        throw new Error(e);
      }
    },
    async handleRowClick(data, type) {
      try {
        data['type'] = type
        // This if is for generate work order number
        await this.getData(this.currentPage)
        await masterStore.dispatch('workOrderDropdown', { token: this.token })
        data['master'] = await masterStore.getters.workOrderDropdown
        if (type === 0) {
          data.employeeSeq = this.currentEmpSeq
          this.rowOnClick = data
          this.$refs.workOrderModal.handleModal()
        } else {
          const permission = loginStore.getters.role
          if (permission.roleCode === '002' && type === 1) {
            const dataByID = await this.getDataById(data.seq)
            const dataModal = dataByID[0]
            if (dataByID.length > 0) {
              dataModal.type = type
              dataModal.master = data.master
              this.rowOnClick = dataModal
              this.$refs.approveWoModal.handleModal()
            } else {
              await this.getData(this.currentPage)
            }
          } else {
            if (
              (
                data['woStatusName'] === 'Waiting'
                && data.employeeSeq === this.currentEmpSeq
                && this.permission.epUpdate === '1'
              )
              || (
                data['woStatusName'] === 'Reject'
                && data.employeeSeq === this.currentEmpSeq
                && this.permission.epUpdate === '1'
              )
            ) {
              const dataByID = await this.getDataById(data.seq)
              const dataModal = dataByID[0]
              if (dataByID.length > 0) {
                dataModal.type = type
                dataModal.master = data.master
                this.rowOnClick = dataModal
                this.$refs.workOrderModal.handleModal()
              } else {
                await this.getData(this.currentPage)
              }
            } else {
              const dataByID = await this.getDataById(data.seq)
              const dataModal = dataByID[0]
              if (dataByID.length > 0) {
                dataModal.type = type
                dataModal.master = data.master
                this.rowOnClick = dataModal
                this.$refs.approveWoModal.handleModal()
              } else {
                await this.getData(this.currentPage)
              }
            }
          }
        }

      } catch (e) {
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    async doDelete(data, event) {
      try {
        event.stopPropagation();
        const payload = {
          token: loginStore.getters.token,
          seq: data.seq
        }
        const resultConfirm = await this.confirmDeleteFunction(payload)
        const result = workOrderStore.getters.saveResult
        if (result === true && resultConfirm === true) {
          this.successFunction()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async confirmDeleteFunction(payload) {
      try {
        const result = await this.$swal.fire({
          title: 'ต้องการลบข้อมูลหรือไม่',
          text: 'กดยืนยันเพื่อทำการลบข้อมูล',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })
        if (result.isConfirmed) {
          await workOrderStore.dispatch('deleteData', payload)
          return true
        } else {
          return false
        }

      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'ลบข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage)
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    changeDateReversFormat(dateData) {
      try {
        return dateData ? moment(dateData, 'YYYY/MM/DD').format('DD-MM-YYYY') : ''
      } catch (e) {
        throw new Error(e);
      }
    },
    changeDateFormat(dateData) {
      try {
        return dateData ? moment(dateData, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
      } catch (e) {
        throw new Error(e);
      }
    },
    async duplicateWo(data, event) {
      try {
        event.stopPropagation();
        const payload = {
          token: this.token,
          seq: data.seq
        }
        await this.confirmDuplicateFunction(payload)
        const result = workOrderStore.getters.saveResult
        if (result === true) {
          this.duplicateSuccessFunction()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async confirmDuplicateFunction(payload) {
      try {
        const result = await this.$swal.fire({
          title: 'ต้องการคัดลอกข้อมูลหรือไม่',
          text: 'กดยืนยันเพื่อทำการคัดลอกข้อมูล',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })

        if (result.isConfirmed === true) {
          await workOrderStore.dispatch('duplicateData', payload)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    duplicateSuccessFunction() {
      try {
        this.$swal.fire({
          title: 'คัดลอกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกคัดลอกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage)
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    duplicateErrorFunction() {
      try {
        this.$swal.fire({
          title: 'คัดลอกข้อมูลล้มเหลว',
          text: 'ไม่สามารถคัดลอกได้เนื่องจากมีสถานะปิดใช้งาน',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    getStatusColor(data) {
      try {
        let result = 'planText'
        if (data === 'Accept') {
          result = 'approveText'
        }
        if (data === 'Reject') {
          result = 'rejectText'
        }
        if (data === 'Waiting') {
          result = 'waitingText'
        }
        return result
      } catch (errorFunction) {
        throw new Error(errorFunction);
      }
    },
    setSaleValue() {
      try {
      if (this.currentEmpRole.roleCode == '003'){
        this.isDisabled = true;
        this.searchForm.sale = this.currentEmpSeq;
        this.empData = this.currentEmpData;
      }
      } catch (errorFunction) {
        throw new Error(errorFunction);
      }
    }
  }
}
</script>

<style scoped>
.table {
  width: 200%;
}

.table th:first-child,
.table th:nth-child(2),
.table th:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #D8EBFF;
}

.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #F8F9FB;
}
</style>
