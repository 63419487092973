import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
axiosInstance.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 499;
};
export default createStore({
    state: {
        tblData: Object,
        saveResult: Boolean,
        handleMessage:String
    },
    getters: {
        tblData: state => state.tblData,
        saveResult: state => state.saveResult,
        handleMessage:state => state.handleMessage
    },
    mutations: {
        setDataTbl(state, payload) {
            state.tblData = payload
        },
        setSave(state, result) {
            state.saveResult = result
        },
        setHandleMessage(state,result){
            state.handleMessage = result
        }
    },
    actions: {
        async getDataTbl({commit}, {sequence,token, page}) {
            try {
                let customURL = `production/findAll/history?limit=10&page=${page}`
                if(sequence !== undefined){
                    customURL = `production/findAll/history?page=1&limit=20`
                }

                const result = await axiosInstance.get(customURL, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            } catch (error:any) {
                commit('setUser', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
    },
    modules: {}
})
