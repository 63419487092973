
<template>
  <h4  v-if="currentPath !== `/`" class="navigationText">{{ manuData }}</h4>
</template>

<script>
import sidebarMenuStore from "@/store/sidebarMenuStore";

export default {
  name: "Header",
  data() {
    return {
      manuData: ''
    }
  },
  computed: {
    currentPath() {
      const currentPath = this.$route['path']
      const allSub = sidebarMenuStore.getters.allSubMenu
      const menuIs = allSub.filter(item => item['menuPath'] === currentPath)
      if(menuIs.length > 0){
        const getMenu = menuIs[0]['menuHead']
        const mainMenu = sidebarMenuStore.getters.mainMenu
        const getHeadMenu = mainMenu.filter(item => item['seq'] === getMenu)
        this.manuData = getHeadMenu[0]['menuName']
      }
      return this.$route['path'];
    },
  },
}
</script>
<style scoped>

</style>