<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <HeaderSection :item="{headerName : 'บันทึกเวลาการผลิต'}"/>
        <SearchSection
            :item="{
            exportRequire:true,
            fieldRequire:[
                {
                  selected: 'findAll', other: {}, classCondition: 'col-sm-12', emitData: 'findAll-field'
                },
                {
                  selected: 'dateStart', other: {}, classCondition: 'col-sm-6', emitData: 'dateStart-field'
                },
                {
                  selected: 'dateFinish', other: {}, classCondition: 'col-sm-6', emitData: 'dateFinish-field'
                }
            ]
          }"
            @search-from="handleEmitSearch"
        />
        <Tabletemplate
            @table-template="handleEmitTable"
            :item="{
                    header : [
                       {
                         columnDisplay:'',
                         columnName: 'button',
                         sort:false,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'เครื่อง',
                         columnName: 'machineName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'หมายเลข',
                         columnName: 'woNumber',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'สถานะ',
                         columnName: 'woStatusName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ชื่อลูกค้า',
                         columnName: 'ctmName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ชื่องาน',
                         columnName: 'woWcode',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ขนาดงาน',
                         columnName: 'size',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'เซลล์',
                         columnName: 'empName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'เริ่ม(ประมาณ)',
                         columnName: 'plProdFinish',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'สิ้นสุด(ประมาณ)',
                         columnName: 'plProdFinish',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ชั่วโมง',
                         columnName: 'plMachineRunning',
                         sort:true,
                         style:'width: 1%'
                       },
                    ],
                    data:{
                      tblData:mapData,
                      pageSize:pageSize,
                      currentPage:currentPage,
                      allShow:allShow
                    }
            }"
        />
      </div>
    </div>
  </Content>
</template>


<script>
import LoginStore from "@/store/loginStore";
import Content from "@/components/Content.vue";
import Header from "@/components/header/Header.vue";
import HeaderSection from "@/components/boilerplate/headerSection.vue";
import SearchSection from "@/components/boilerplate/searchSaction.vue";
import Tabletemplate from "@/components/boilerplate/tableTemplate.vue";
import SearchField from "@/components/boilerplate/SearchField.vue";
import productionPrintStore from "@/store/productionPrintStore";
import moment from "moment/moment";
import customerStore from "@/store/customerStore";

export default {
  name: "SaveprodView-cont",
  components: {Tabletemplate, SearchSection, HeaderSection, SearchField, Header, Content},
  data() {
    try {
      return {
        token: LoginStore.getters.token,
        isLoading: false,
        tblData: null,
        mapData: [],
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        dataByID: null,
        emitProgress: {
          progress: '',
          result: ''
        },
        searchForm: {
          findAll: '',
          dateStart: '',
          dateFinish: ''
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  emits: [
    'saveProd-content',
  ],
  mounted() {
    this.initializeData()
  },
  methods: {
    async initializeData() {
      try {
        await this.getData(this.currentPage)
        this.handleMapData()
      } catch (e) {
        throw new Error(e)
      }
    },
    async handleRowClick() {
      try {
        this.emitProgress.progress = 'info'
        this.emitProgress.result = this.dataByID;
        this.handleChange()
      } catch (e) {
        throw new Error(e);
      }
    },
    async getDataById(seq) {
      try {
        this.isLoading = true
        const payload = {
          sequence: seq,
          token: this.token,
          status: "1"
        }
        await productionPrintStore.dispatch('getDataTbl', payload)
        this.isLoading = false
        return productionPrintStore.getters.tblData['workOrders']
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getData(page) {
      try {
        this.currentPage = page
        this.isLoading = true
        const payload = {
          token: this.token,
          status: "1",
          page: page,
        }
        await productionPrintStore.dispatch('getDataTbl', payload)
        this.tblData = productionPrintStore.getters.tblData['workOrders']
        this.pageSize = productionPrintStore.getters.tblData['limit']
        this.allShow = productionPrintStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    handleProcess(selection) {
      let result = ""
      if (selection === 1) result = 'accept'
      if (selection === 2) result = 'info'
      if (selection === 3) result = 'close'
      return result
    },
    handleButton(inform) {
      const buttonConfigs = [
        {icon: "fa fa-edit", buttonName: "บันทึกเวลา", styleSuffix: "success"},
        {icon: "", buttonName: "ปิดการผลิต", styleSuffix: "primary"}
      ];
      if (inform === "006") {
        return [{
          icon: "fa fa-plus",
          buttonName: "รับงาน",
          css: "btn btn-outline-warning button-small",
          condition: {module: "saveProd", process: this.handleProcess(1)},
        }];
      } else {
        return buttonConfigs.map((config, i) => ({
          icon: config.icon,
          buttonName: config.buttonName,
          css: `btn btn-outline-${config.styleSuffix} button-small`,
          condition: {module: "saveProd", process: this.handleProcess(i + 2)},
        }));
      }
    },
    handleMapData() {
      this.mapData = []
      this.tblData.map(dataRe => {
        let buttonHandle = this.handleButton(dataRe.wosCode)
        this.mapData.push(
            {
              button: {
                data: buttonHandle,
              },
              seq: {
                data: dataRe.seq,
                style: ''
              },
              machineName: {
                data: dataRe['planning'].machine.machineDetail,
                style: ''
              },
              woNumber: {
                data: dataRe.woNumber,
                style: ''
              },
              woStatusName: {
                data: dataRe.woStatusName,
                style: ''
              },
              ctmName: {
                data: dataRe.ctmName,
                style: ''
              },
              woWcode: {
                data: dataRe.woWcode,
                style: ''
              },
              size: {
                data: `${dataRe.woSizeH} x ${dataRe.woSizeV}`,
                style: ''
              },
              empName: {
                data: dataRe.empName,
                style: ''
              },
              plProdStart: {
                data: dataRe['planning'].plProdStart,
                style: ''
              },
              plProdFinish: {
                data: dataRe['planning'].plProdFinish,
                style: ''
              },
              plMachineRunning: {
                data: dataRe['planning'].plMachineRunning,
                style: ''
              },
            }
        )
      })
    },
    handleEmitSearch(data) {
      Object.assign(this.searchForm, data);
    },
    async handleEmitTable(data) {
      if (data.emitData === 'pagination') {
        const page = data.result
        await this.getData(page)
        this.handleMapData()
      }
      if (data.emitData === 'rowID') {
        const seq = data.result.seq
        this.dataByID = await this.getDataById(seq)
        await this.handleRowClick()
      }
      if (data.emitData === 'button') {
        this.dataByID = await this.getDataById(data.result.seq)
        this.emitProgress.progress = data.result.submitType
        this.emitProgress.result = this.dataByID;
        if (data.result.submitType === 'info') {
          this.handleChange()
        }
        if (data.result.submitType === 'accept') {
          await this.accept(this.dataByID[0]['planning'].woSeq,)
        }
        if (data.result.submitType === 'close') {
          const payload = {
            woSeq: this.dataByID[0]['planning'].woSeq,
            plSeq : this.dataByID[0]['planning'].seq,
            prepressSeq: this.dataByID[0]['woPrepress'].seq,
          }
          await this.close(payload)
        }
      }
    },
    async accept(seq) {
      const token = this.token
      await productionPrintStore.dispatch('accept', {token, seq})
      const result = productionPrintStore.getters.saveResult
      if (result) {
        this.successFunction()
      } else {
        this.errorFunction()
      }
    },
    async close(payload) {
      const token = this.token
      await productionPrintStore.dispatch('close', {token, payload})
      const result = productionPrintStore.getters.saveResult
      if (result) {
        this.successFunction()
      } else {
        this.errorFunction()
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(async () => {
          await this.initializeData()
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = productionPrintStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(async () => {
         await this.initializeData()
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handleChange() {
      this.$emit("saveProd-content", {emitData: this.emitProgress.progress, result: this.emitProgress.result})
    },
  }
}
</script>


<style scoped>

</style>