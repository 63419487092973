<template>
  <SaveSleetViewCont v-if="!itemContent" @saveSleet-content="handleEmitSaveCont" />
  <SaveSleetContent v-if="itemContent" :item="itemContent" @saveSleet-content-view="handleEmitSaveContView" />
</template>

<script>
import Content from "@/components/Content.vue";
import Header from "@/components/header/Header.vue";
import SaveSleetViewCont from "@/components/saveSleet/SaveSleetViewComponent.vue";
import SaveSleetContent from "@/components/saveSleet/SaveSleetContent.vue";

export default {
name: "SavesleetView",
components: { SaveSleetContent, SaveSleetViewCont, Header, Content},
data() {
  try {
    return {
      itemContent:null,
    }
  } catch (e) {
    throw new Error(e);
  }
},
mounted() {

},
methods: {
  async handleEmitSaveCont(data) {
    if (data.emitData === 'info') {
      this.itemContent = data.result
    }
  },
  async handleEmitSaveContView(data) {
    if (data.emitData === 'back') {
      this.itemContent = null
    }
  },
}
}

</script>

<style scoped>

</style>






<!-- <template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <HeaderSection :item="{headerName : 'บันทึกเวลาการสลีต'}"/>
        <SearchSection
          :item="{
            exportRequire:true,
            fieldRequire:[
                {
                  selected: 'findAll', other: {}, classCondition: 'col-sm-12', emitData: 'findAll-field'
                },
                {
                  selected: 'dateStart', other: {}, classCondition: 'col-sm-6', emitData: 'dateStart-field'
                },
                {
                  selected: 'dateFinish', other: {}, classCondition: 'col-sm-6', emitData: 'dateFinish-field'
                }
            ]
          }"
          @search-from="handleEmitSearch"
        />
        <Tabletemplate
            @table-template="handleEmitTable"
            :item="{
                    header : [
                       {
                         columnDisplay:'',
                         columnName: '',
                         sort:false,
                         style:'width: 2%'
                       },
                       {
                         columnDisplay:'เครื่อง',
                         columnName: 'machineName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'หมายเลข',
                         columnName: 'woNumber',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'สถานะ',
                         columnName: 'woStatusName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ชื่อลูกค้า',
                         columnName: 'ctmName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ชื่องาน',
                         columnName: 'woWcode',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ขนาดงาน',
                         columnName: 'size',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'เซลล์',
                         columnName: 'empName',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'เริ่ม(ประมาณ)',
                         columnName: 'plProdFinish',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'สิ้นสุด(ประมาณ)',
                         columnName: 'plProdFinish',
                         sort:true,
                         style:'width: 1%'
                       },
                       {
                         columnDisplay:'ชั่วโมง',
                         columnName: 'plMachineRunning',
                         sort:true,
                         style:'width: 1%'
                       },
                    ],
                    data:{
                      tblData:mapData,
                      pageSize:pageSize,
                      currentPage:currentPage,
                      allShow:allShow
                    }
            }"
        />
      </div>
    </div>
  </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import Header from "@/components/header/Header.vue";
import Loading from "@/components/loading/Loading.vue";
import SearchField from "@/components/boilerplate/SearchField.vue";
import HeaderSection from "@/components/boilerplate/headerSection.vue";
import SearchSection from "@/components/boilerplate/searchSaction.vue";
import Tabletemplate from "@/components/boilerplate/tableTemplate.vue";
import productionSleetStore from "@/store/productionSleetStore";
import loginStore from "@/store/loginStore";
import moment from "moment";


export default {
  name: "SavesleetView",
  components: {Tabletemplate, SearchSection, HeaderSection, SearchField, Header, Content, Loading},
  data() {
    try {
      return {
        token : loginStore.getters.token,
        isLoading: false,
        tblData: null,
        mapData:[],
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        searchForm: {
          findAll: '',
          dateStart: '',
          dateFinish: ''
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
   this.initializeData()
  },
  methods: {
    async initializeData(){
      try{
        await this.getData(this.currentPage)
        this.handleMapData()
      }catch (e) {
        throw new Error(e)
      }
    },
    async getData(page) {
      try {
        this.currentPage = page
        this.isLoading = true
        const payload = {
          token: this.token,
          status: "1",
          page: page,
        }
        await productionSleetStore.dispatch('getDataTbl', payload)
        this.tblData = productionSleetStore.getters.tblData['workOrders']
        this.pageSize = productionSleetStore.getters.tblData['limit']
        this.allShow = productionSleetStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    handleMapData() {
      this.mapData = []
      this.tblData.map(dataRe => {
        this.mapData.push(
            {
              "": {
                data: {},
                style: ''
              },
              "seq": {
                data: dataRe.seq,
                style: ''
              },
              machineName: {
                data: dataRe['planning'].machineSleet.machineDetail,
                style: ''
              },
              woNumber: {
                data: dataRe.woNumber,
                style: ''
              },
              woStatusName: {
                data: dataRe.woStatusName,
                style: ''
              },
              ctmName: {
                data: dataRe.ctmName,
                style: ''
              },
              woWcode: {
                data: dataRe.woWcode,
                style: ''
              },
              size: {
                data: `${dataRe.woSizeH} x ${dataRe.woSizeV}`,
                style: ''
              },
              empName: {
                data: dataRe.empName,
                style: ''
              },
              plProdStart: {
                data: dataRe['planning'].plSleetStart,
                style: ''
              },
              plProdFinish: {
                data: dataRe['planning'].plSleetFinish,
                style: ''
              },
              plMachineRunning: {
                data: dataRe['planning'].plSleetRunning,
                style: ''
              },
            }
        )
      })
    },
    handleEmitSearch(data) {
      Object.assign(this.searchForm, data);
    },
    async handleEmitTable(data) {
      if(data.emitData === 'pagination'){
        const page = data.result
        await this.getData(page)
        this.handleMapData()
      }
    },
  }
}
</script>

<style scoped>

</style> -->