import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";
import {string} from "yup";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
axiosInstance.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 499;
};
export default createStore({
    state: {
        tblData: Object,
        saveResult: Boolean,
        handleMessage:String,
    },
    getters: {
        tblData: state => state.tblData,
        saveResult: state => state.saveResult,
        errorMessage:state => state.handleMessage
    },
    mutations: {
        setDataTbl(state, payload) {
            state.tblData = payload
        },
        setSave(state, result) {
            state.saveResult = result
        },
        setError(state,error){
            state.handleMessage = error
        }
    },
    actions: {
        async getDataTbl({commit}, {sequence,token,orderStartDate,orderName,orderFinnishDate,woNumber,sale,customer,employeeSeq, page, status, limit}) {
            try {
                if (!limit) {
                    limit = 10
                }
                let customURL = `workOrder?page=${page}&limit=${limit}&woDate=${orderStartDate}&woDeliver=${orderFinnishDate}&status=${status}&customerSeq=${customer}&employeeSeq=${sale}&woWCode=${orderName}&woNumber=${woNumber}`
                // let customURL = `workOrder?page=${page}&limit=${limit}&status=${status}&woDate=${orderStartDate}&woDeliver=${orderFinnishDate}&usedSelect=${type}&textSearch=${search}&employeeSeq=${employeeSeq}`
                if(sequence !== undefined){
                    customURL = `workOrder?seq=${sequence}&limit=10&status=${status}`
                }
                const result = await axiosInstance.get(customURL, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            } catch (error: any) {
                commit('setSave', false);
            }
        },
        async createData({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`workOrder/create`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 201) {
                    commit('setSave', result)
                } else {
                    commit('setSave', false)
                    commit('setError', result.data['msg']);
                }
            } catch (error: any) {
                commit('setSave', false);
                commit('setError', error.response.data['msg']);
            }
        },
        async updateData({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.put(`workOrder/update`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setSave', true)
                } else {
                    commit('setSave', false)
                    commit('setError', result.data['msg']);
                }
            } catch (error :any) {
                commit('setSave', false);
                commit('setError', error.response.data['msg']);
            }
        },
        async deleteData({commit}, {token, seq}) {
            try {
                const result = await axiosInstance.delete(`workOrder?seq=${seq}`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setSave', true)
                } else {
                    commit('setSave', false)
                    commit('setError', result.data['msg']);
                }
            } catch (error :any) {
                commit('setSave', false);
                commit('setError', error.response.data['msg']);
            }
        },
        async duplicateData({commit}, {token, seq}) {
            try {
                const result = await axiosInstance.put(`workOrder/duplicateWorkOrder?seq=${seq}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setSave', true)
                } else {
                    commit('setSave', false)
                    commit('setError', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave', false);
                commit('setError', error.response.data['msg']);
            }
        },
        async approve({commit}, {token, seq,status}) {
            try {
                const result = await axiosInstance.put(`workOrder/saleManagerApprove?seq=${seq}&approve=${status}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setSave', true)
                } else {
                    commit('setSave', false)
                    commit('setError', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave', false);
                commit('setError', error.response.data['msg']);
            }
        },
    },
    modules: {}
})
