<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <router-link :to="{ path: '/' }">
      <br>
      <div class="mt-3">
        <img src="../../assets/logo.png" alt="Logo" class="imgLogo mt-3">
      </div>
    </router-link>

    <div class="sidebar">
      <div class="form-inline mt-3 brand-link">
        <div class="input-group">
          <button class="btn btn-primary btn-block"><i class="fa fa-user-circle mr-1"></i>{{user}}</button>
        </div>
      </div>
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <SidebarItem v-for="(menu, index) in mainMenus" :key="menu['seq']" v-bind:name="menu['menuName']"
            v-bind:seq="menu['seq']" v-bind:icon="menu['menuIcon']" v-bind:subMenu="subMenu[index]" />
        </ul>

      </nav>

    </div>

  </aside>

</template>

<script>
import SidebarItem from "@/layouts/inc/SidebarItem.vue";
import sidebarMenuStore from "@/store/sidebarMenuStore"
import loginStore from "@/store/loginStore"
export default {
  name: "Sidebar",
  components: {
    SidebarItem
  },
  data() {
    return {
      mainMenus: null,
      subMenu: null,
      user:'',
    }
  },

  methods: {
    getUser(){
      this.user = loginStore.getters.firstName + ' ' + loginStore.getters.lastName
    },
    getMenu() {
      this.mainMenus = sidebarMenuStore.getters.mainMenu
      this.subMenu = sidebarMenuStore.getters.subMenu
    }

  },
  mounted() {
    this.getMenu();
    this.getUser();
  }
}
</script>

<style scoped>
@import "@/assets/style.css";
</style>
