<template>
  <div class="modal fade" id="modal-default" ref="packingCreateModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">สร้างสต๊อคสินค้า</h4>
        </div>
        <Loading v-if="isLoading" />
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ชื่อลูกค้า</label>
                  <select class="select form-control" v-model="formData.customerName">
                    <option value="">เลือกลูกค้า</option>
                    <option v-for="(item) in selectCustomer" :value="item.seq">
                      {{ item['customerName'] }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.customerName }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ชื่องาน</label>
                  <input v-model="formData.workName" type="text" class="form-control" placeholder="ชื่องาน">
                  <span class="text-danger">{{ validateResult.workName }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">ขนาด (ต x น)</label>
                  <input v-model="formData.sizeV" type="number" class="form-control" placeholder="ขนาด"
                    @input="handleInput($event, 'sizeV')">
                  <span class="text-danger">{{ validateResult.sizeV }}</span>
                </div>
              </div>
              <div class="align-self-end">
                <div class="form-group align-content-center">
                  <label class="reduceHeadText">X</label>
                </div>
              </div>
              <div class="col-sm-2 align-self-end">
                <div class="form-group">
                  <input v-model="formData.sizeH" type="number" class="form-control" placeholder="ขนาด"
                    @input="handleInput($event, 'sizeH')">
                  <span class="text-danger">{{ validateResult.sizeH }}</span>
                </div>
              </div>
              <div class="col-sm-2 align-self-end">
                <div class="form-group">
                  <select class="select form-control" v-model="formData.measure">
                    <option value="">เลือกหน่วยวัด</option>
                    <option v-for="(item) in selectMeasure" :value="item.seq">
                      {{ item['measureName'] }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.measure }}</span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label>หมายเหตุ</label>
                  <input v-model="formData.remark" type="text" class="form-control" placeholder="หมายเหตุ">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="handleCancel()" class="btn btn-default float-right" data-dismiss="modal">
              ยกเลิก
            </button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import packingStore from "@/store/packingStore";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";
import environmentStore from "@/store/environmentStore";

export default {
  name: "PackingCreateModal",
  components: { Loading },
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        role: loginStore.getters.role.roleCode,
        selectPackingType: [],
        baseurl: environmentStore.getters.upload,
        selectCustomer: null,
        selectMeasure: null,
        validateResult: {
          customerName: null,
          workName: null,
          sizeV: null,
          sizeH: null,
          measure: null,
          remark: null
        },
        formData: {
          customerName: null,
          workName: null,
          sizeV: null,
          sizeH: null,
          measure: null,
          remark: null

        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        // count: Yup.string().required('กรุณาเลือกกรอกจำนวน'),
        // packingType: Yup.mixed().oneOf(this.selectPackingType.map(c => c.seq)).required('ประเภทแพ็คกิ้งห้ามเป็นค่าว่าง')
      });
    },
    calculateAmount() {
      let response = 0
      if (this.formData.packingType == 1) {
        response = (this.formData.count * this.item.woStartRoll)
      } else {
        response = this.formData.count
      }
      return response;
    }
  },
  mounted() {
    try {
      this.getCustomer()
      this.getMeasure()
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.count = this.item['stiAmount'];
            this.formData.packingType = this.item['ptSeq'];
            this.formData.amount = this.item['stiCountAmount'];
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    },
    calculateAmount(newVal) {
      this.formData.amount = newVal;
    },
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.packingCreateModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    handleInput(event, formData) {
      const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
      const match = sanitizedValue.match(/(\d*\.\d{0,2}|\d*)/);
      this.formData[formData] = match ? match[0] : '';
    },
    getImageSource(image) {
      try {
        console.log(this.baseurl + image)
        if (image.startsWith('/upload')) {
          console.log('this is baseurl img : ' + this.baseurl + image);
          return this.baseurl + image;
        } else {
          return image;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    cancel() {
      try {
        this.formData.count = null
        this.formData.packingType = null
        this.formData.amount = null
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.isLoading = true
        this.formSchema.validate(this.formData, { abortEarly: false }).then(async () => {
          const token = this.token
          const payload = {
            customerSeq: this.formData.customerName,
            stockWname: this.formData.workName,
            stockSizeH: this.formData.sizeH,
            stockSizeV: this.formData.sizeV,
            stockRemark: this.formData.remark,
            measureSeq: this.formData.measure,
            status: 1
          }
          await packingStore.dispatch('createStock', { token, payload })
          const result = packingStore.getters.saveResult
          this.isLoading = false
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
        }
        ).catch((err) => {
          this.isLoading = false
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })

      } catch (e) {
        throw new Error(e);
      }
    },
    async getCustomer() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('packingCreateFormSearch', payload)
        this.selectCustomer = masterStore.getters.packingCreateCustomerSearch
      } catch (e) {
        throw new Error(e);
      }
    },
    async getMeasure() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('packingCreateFormSearch', payload)
        this.selectMeasure = masterStore.getters.packingCreateMeasureSearch
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.formData = {
          count: null,
          packingType: null,
          amount: null
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.packingCreateModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = packingStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel() {
      $(this.$refs.packingCreateModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>

<style scoped></style>
      