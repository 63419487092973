<template>
  <div class="row mb-2">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
              <tr>
                <th
                    v-for="(item, index) in headerData"
                    :key="index" scope="col"
                    :class="{ 'on-pointer': item.sort }"
                    :style="item.style"
                >
                  {{ item.columnDisplay }}
                  <i v-if="item.sort"
                     :class="`fa fa-arrow-${sortTable.column === item.columnName ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(rowItem,index) in rowData"
                  class="rowHovered"
                  :key="index"
              >
                <td
                    v-for="(headerItem,index) in headerData"
                >
                  <span
                      v-if="headerItem.columnName === 'button'"
                      v-for="(buttonItem,index) in rowItem[headerItem.columnName].data"
                  >
                    <button
                        :class="`${buttonItem['css']}`"
                        @click="handleButtonClick({seq:rowItem.seq.data,submitType:buttonItem.condition.process})">
                      <i :class="`${buttonItem['icon']}`"></i>
                      {{ buttonItem['buttonName'] }}
                    </button>
                  </span>
                  {{ headerItem.columnName !== 'button' ? rowItem[headerItem.columnName].data : '' }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- This page size section -->
      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1
            ถึง 10 ของ
            {{ allShow }}
            ทั้งหมด
          </div>
        </div>
        <div class="col-sm-12 col-md-7 ">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination">
              <li class="paginate_button page-item previous">
                <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                  ก่อนหน้า
                </button>
              </li>
              <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                  v-for="page in pages" :key="page">
                <button @click="goToPage(page)" class="page-link">{{ page }}</button>
              </li>
              <li class="paginate_button page-item previous">
                <button @click="nextPage()" :disabled="currentPage >= allPage[8]"
                        class="page-link">ถัดไป
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import loginStore from "@/store/loginStore";

export default {
  name: "Tabletemplate",
  components: {},
  props: ['item'],

  data() {
    try {
      return {
        token: loginStore.getters.token,
        sortTable: {column: null, sort: 'asc', icon: 'up'},
        headerData: [],
        rowData: [],
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        emitProgress: {
          progress: '',
          result: ''
        }
      }
    } catch (e) {
      throw new Error(e)
    }
  },
  watch: {
    item: {
      handler() {
        try {
          if (this.item) {
            if (this.item.data.tblData.length !== 0) {
              this.headerData = this.item.header
              this.pageSize = this.item.data.pageSize
              this.allShow = this.item.data.allShow
              this.currentPage = this.item.data.currentPage
              this.rowData = this.item.data.tblData
              this.tblData = this.item.data.tblData
            }
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  emits: [
    'table-template',
  ],
  computed: {
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage;

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({length: totalPages}, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }
          const result = Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  methods: {

    async handleRowClick(data, type) {
      try {
        this.emitProgress.progress = 'rowID'
        this.emitProgress.result = {seq: data.seq.data};
        this.handleChange()
      } catch (e) {
        throw new Error(e);
      }
    },

    async handleButtonClick(data, type) {
      try {
        this.emitProgress.progress = 'button'
        this.emitProgress.result = {seq: data.seq, submitType:data.submitType};
        this.handleChange()
      } catch (e) {
        throw new Error(e);
      }
    },

    handleSortData(column) {
      this.sortTable.column = column
      this.sortTable.sort = this.sortTable.sort === 'desc' ? 'asc' : "desc"
      this.sortTable.icon = this.sortTable.sort === 'desc' ? 'down' : "up"
      const data = this.tblData
      const actionData = []
      for (let item of data) {
        if (item[column] === null) {
          item[column] = ''
        }
        actionData.push(item)
      }
      this.tblData = actionData.sort((a, b) => {
        if (this.sortTable.sort === "desc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      })
    },

    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.emitProgress.progress = 'pagination'
          this.emitProgress.result = this.currentPage;
          this.handleChange()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.emitProgress.progress = 'pagination'
          this.emitProgress.result = this.currentPage;
          this.handleChange()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.emitProgress.progress = 'pagination'
        this.emitProgress.result = page;
        this.handleChange()
      } catch (e) {
        throw new Error(e);
      }
    },
    handleChange() {
      this.$emit("table-template", {emitData: this.emitProgress.progress, result: this.emitProgress.result})
    },
  }
}
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.table {
  width: 200%;
}

.table th:first-child,
.table th:nth-child(2),
.table th:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #D8EBFF;
}

.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #F8F9FB;
}
</style>