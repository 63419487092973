<template>
  <div class="modal fade" id="modal-default" ref="saveSleetModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">เพิ่มช่วงเวลาการผลิต</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading" />
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>เครื่องสลีต</label>
                  <select class="select form-control" v-model="formData.machine">
                    <option value="" disabled selected hidden>เลือกเครื่องสลีต</option>
                    <option v-for="(item) in selectFormGetter.machineSleet" :key="item['seq']" :value="item['seq']">{{
            item['machineDetail']
          }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.machine }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>จำนวนม้วน</label>
                  <input type="text" v-model="formData.rollAmount" class="form-control" placeholder="จำนวนม้วน">
                  <span class="text-danger">{{ validateResult.rollAmount }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>ช่างสลีต</label>
                  <select class="select form-control" v-model="formData.mechanic">
                    <option value="" disabled selected hidden>เลือกช่างสลีต</option>
                    <option v-for="(item) in selectFormGetter.employee" :key="item['seq']" :value="item['seq']">{{
            item['employeeFirstname'] + ' ' + item['employeeLastname']
          }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.mechanic }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>เวลาเริ่มงานจริง</label>
                  <input type="datetime-local" v-model="formData.startDateTime" class="form-control" placeholder="dd/mm/yyyy">
                  <span class="text-danger">{{ validateResult.startDateTime }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>เวลาเริ่มต้น</label>
                  <input type="time" v-model="formData.startTime" class="form-control" placeholder="dd/mm/yyyy">
                  <span class="text-danger">{{ validateResult.startTime }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>เวลาสิ้นสุด</label>
                  <input type="time" v-model="formData.endTime" class="form-control" placeholder="dd/mm/yyyy">
                  <span class="text-danger">{{ validateResult.endTime }}</span>
                </div>
              </div>

              <div class="col-sm-12">
                <label>รายละเอียดเพิ่มเติม</label>
                <textarea type="text" v-model="formData.description" class="form-control"
                  placeholder="รายละเอียดเพิ่มเติม"></textarea>
                <span class="text-danger">{{ validateResult.description }}</span>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import loginStore from "@/store/loginStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";
import masterStore from "@/store/MasterStore";
import productionSleetStore from "@/store/productionSleetStore";

export default {
  name: "SaveSleetModal",
  components: { Loading },
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        selectFormGetter: {
          employee: [],
          machineSleet: []
        },
        validateResult: {
          machine: '',
          rollAmount: '',
          mechanic: '',
          startDateTime: '',
          startTime: '',
          endTime: '',
          description: '',
        },
        formData: {
          woSeq: '',
          plSeq: '',
          prepressSeq: '',

          machine: '',
          rollAmount: '',
          mechanic: '',
          startDateTime: '',
          startTime: '',
          endTime: '',
          description: '',
        },
        activeButton: null,
        activeSubButton: null
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  async mounted() {
    try {
      const payload = {
        token: this.token
      }
      await masterStore.dispatch('productionSleetForm', payload)
      this.getSelectionData()
    } catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    handleModal(item) {
      try {
        this.formData.woSeq = item.seq
        this.formData.plSeq = item['planning'].seq
        this.formData.prepressSeq = item['woPrepress'].seq
        $(this.$refs.saveSleetModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },

    handleCancel() {
      $(this.$refs.saveSleetModal).modal('hide');
      this.$emit('form-submitted')
    },

    setActive(buttonId) {
      this.activeButton = buttonId;
      this.formData.workType = buttonId;
    },

    setActive2(buttonId) {
      this.activeSubButton = buttonId;
      this.formData.subWorkType = buttonId;
    },

    getSelectionData() {
      try {
        this.isLoading = true
        const dropdownForm = masterStore.getters.productionSleetForm
        this.selectFormGetter.employee = dropdownForm['employeeLst']
        this.selectFormGetter.machineSleet = dropdownForm['machineSleetLst']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e)
      }
    },
    async submitForm() {
      try {
        // await this.formSchema.validate(this.formData, { abortEarly: false })
        this.isLoading = true;
        const token = this.token
        let payload = {}
        payload = {
          woSeq: this.formData.woSeq,
          plSeq: this.formData.plSeq,
          prepressSeq: this.formData.prepressSeq,
          status: '1',
          machineSeq : this.formData.machine,
          sleetRollAmout : this.formData.rollAmount,
          empSeq: this.formData.mechanic,
          prodWorkingStart: this.formData.startDateTime,
          prodWorkingTimeStart: this.formData.startTime,
          prodWorkingTimeFinnish: this.formData.endTime,
          sleetDetail: this.formData.description,
        }
        await productionSleetStore.dispatch('createData', { token, payload })
        const result = productionSleetStore.getters.saveResult
        this.isLoading = false;
        if (result) {
          this.successFunction()
        } else {
          this.errorFunction()
        }
      } catch (err) {
        this.validateResult = {};
        err.inner.forEach((error) => {
          this.alertFunction();
          this.validateResult[error.path] = error.message;
        });
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.saveSleetModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = productionSleetStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
  },
}
</script>

<style scoped>
.radio-button {
  padding: 5px 10px;
  margin: 5px;
  background-color: #fff;
  border: 1px solid #c7c6c6;
  border-radius: 5px;
  cursor: pointer;
  flex-grow: auto;
}

.radio-button.active {
  background-color: #2196f3;
  color: white;
}

.radio-buttons {
  display: flex;
  align-items: center;
  /* Center items vertically */
}
</style>