<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <Loading v-if="isLoading"/>
        <div class="row mb-3">
          <div class="col-sm-12">
            <Header/>
          </div>
          <div class="col-sm-6">
            <h1 class="m-0">เลือกจาก Work Order</h1>
          </div>
          <div class="col-sm-6">
            <WorkOrderSelectionModal @form-submitted="getData(currentPage, 10)" ref="workOrderSelectionModal"
                                     :item="rowOnClick"/>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>วันเริ่มต้นสั่งงาน</label>
                            <input type="date" v-model="searchForm.orderStartDate" class="form-control"
                                   placeholder="วว/ดด/ปปปป">
                          </div>
                        </div>
                        <SearchField
                          :item="{ selected: 'sale', other: { condition: 'role', expect: { compareCondition: 'not equal', data: '003' } }, classCondition: 'col-sm-6', emitData: 'sale-field' }"
                          @handle-emit-field="handleSearchEmit" />
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>วันสิ้นสุดสั่งงาน</label>
                            <input type="date" v-model="searchForm.orderFinnishDate" class="form-control"
                                   placeholder="วว/ดด/ปปปป">
                          </div>
                        </div>
                        <SearchField
                          :item="{ selected: 'customer', other: {}, classCondition: 'col-sm-6', emitData: 'customer-field' }"
                          @handle-emit-field="handleSearchEmit" />
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>ชื่องาน</label>
                            <input type="text" v-model="searchForm.orderName" class="form-control"
                                   placeholder="ชื่องาน">
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>หมายเลข</label>
                            <input type="text" v-model="searchForm.woNumber" class="form-control"
                                   placeholder="หมายเลข work order">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-sm-2" style="padding-top: 2%">
                      <button @click.prevent="getData()" class="btn btn-primary btn-block"
                              type="submit">ค้นหา
                      </button>
                      <button @click.prevent="toExport()" class="btn btn-outline-primary btn-block"
                              type="submit">
                        Export
                      </button>
                      <button @click.prevent="toSubmit()" :disabled="role !== '001' && role !== '005'"
                              class="btn btn-success btn-block" type="submit">
                        รับงาน ({{ selectionSum }})
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table class="table table-sm">
                    <thead>
                    <tr>
                      <th scope="col" class="to-center" style="width: 3.5%;">เลือก</th>
                      <th scope="col">หมายเลข</th>
                      <th scope="col">สถานะ</th>
                      <th scope="col">ชื่อลูกค้า</th>
                      <th scope="col">ชื่องาน</th>
                      <th scope="col">กระดาษ</th>
                      <th scope="col">หน้ากระดาษ</th>
                      <th scope="col">ขนาด</th>
                      <th scope="col">เซลล์</th>
                      <th scope="col">วันที่เปิด</th>
                      <th scope="col">จำนวนผลิต</th>
                      <th scope="col">ราคา</th>
                      <th scope="col">วันที่ส่งงาน</th>
                      <th scope="col">หมายเหตุ</th>
                      <!-- <th scope="col">สถานะ</th> -->
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="rowHovered" v-for="(item) in tblData" :key="item"
                        @click="handleRowClick(item, 1)">
                      <td>
                        <input type="checkbox" style="transform: scale(1.3);"
                               :disabled="role !== '001' && role !== '005'"
                               :checked="checked(item.seq)"
                               @click="handleCheckBox(item['seq'],$event)"/>
                      </td>
                      <td>{{ item['woNumber'] }}</td>
                      <td :class="getStatusColor(item['woStatusName'])">{{ item['woStatusName'] }}
                      </td>
                      <td>{{ item['ctmName'] }}</td>
                      <td>{{ item['woWcode'] }}</td>
                      <td>{{ item['materialName'] }}</td>
                      <td>{{ item['woPage'] }}</td>
                      <td>{{ item['woSizeV'] }} x {{ item['woSizeH'] }}</td>
                      <td>{{ item['empName'] }}</td>
                      <td>{{ changeDateFormat(item['woOrderDate']) }}</td>
                      <td>{{ item['woProdAmount'] }}</td>
                      <td>{{ item['woPriceUnit'] }}</td>
                      <td>{{ changeDateFormat(item['woDeliverDate']) }}</td>
                      <td>{{ item['woNote'] }}</td>
                      <!-- <td
                          :class="item['status'] === '1' ? 'activeTextToggle' : 'inActiveTextToggle'">
                        {{ item['status'] === '1' ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
                      </td> -->
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1
                  ถึง 10 ของ
                  {{ allShow }}
                  ทั้งหมด
                </div>
              </div>
              <div class="col-sm-12 col-md-7 ">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination">
                    <li class="paginate_button page-item previous">
                      <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                        ก่อนหน้า
                      </button>
                    </li>
                    <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                        v-for="page in pages" :key="page">
                      <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                    </li>
                    <li class="paginate_button page-item previous">
                      <button @click="nextPage()" :disabled="currentPage >= allPage[8]"
                              class="page-link">ถัดไป
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import WorkOrderSelectionModal from "@/components/workOrderSelection/WorkOrderSelectionModal.vue";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import workOrderSelectionStore from "@/store/workOrderSelectionStore";
import moment from "moment/moment";
import Loading from "@/components/loading/Loading.vue";
import workOrderStore from "@/store/workOrderStore";
import Header from "@/components/header/Header.vue";
import SearchField from "@/components/boilerplate/SearchField.vue";

export default {
  name: "WorkOrderSelection",
  components: {SearchField, Header, Loading, WorkOrderSelectionModal, Content},
  data() {
    try {
      return {
        isLoading: false,
        tblData: null,
        rowOnClick: {},
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        checkedItems: [],
        storedCheckItem:[],
        token: loginStore.getters.token,
        role: loginStore.getters.role.roleCode,
        statusDropdown: null,
        searchForm: {
          orderStartDate: '',
          sale: '',
          orderFinnishDate: '',
          customer: '',
          orderName: '',
          woNumber: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    selectionSum() {
      return this.checkedItems.length;
    },
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({length: totalPages}, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  mounted() {
    try {
      this.getStatus()
      this.getData()
    } catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    handleSearchEmit(data) {
     switch (data.emitData) {
       case "sale-field" :
         this.searchForm.sale = data.result
         break;
       case "machine-field":
         this.searchForm.machine = data.result
         break;
       case "customer-field" :
         this.searchForm.customer = data.result
         break;
       default :
         this.searchForm.sale = ""
         this.searchForm.machine = ""
         this.searchForm.customer = ""
         break;
     }
    },
    checked(item){
      const result = this.checkedItems.filter(data => data === item)
      return result.length > 0;
    },
    async handleCheckBox(itemID, event) {
      event.stopPropagation()
      await this.getData()
      const isChecked = event.target.checked;
      const indexTBL = this.tblData.filter(item => item.seq === itemID)
      if (isChecked && (indexTBL.length > 0)) {
        this.checkedItems.push(itemID)
      } else {
        const index = this.checkedItems.indexOf(itemID);
        if (index !== -1) {
          this.checkedItems.splice(index, 1);
        }
      }
    },
    async getStatus() {
      try {
        this.statusDropdown = masterStore.getters.statusDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData() {
      try {
        this.isLoading = true
        const payload = {
          token: this.token,
          page: this.currentPage,
          orderStartDate: this.changeDateFormat(this.searchForm.orderStartDate),
          sale: this.searchForm.sale,
          orderFinnishDate: this.changeDateFormat(this.searchForm.orderFinnishDate),
          customer: this.searchForm.customer,
          orderName: this.searchForm.orderName,
          woNumber: this.searchForm.woNumber,
          limit: 10
        }
        await workOrderSelectionStore.dispatch('getDataTbl', payload)
        this.tblData = workOrderSelectionStore.getters.tblData['workOrders']
        this.pageSize = workOrderSelectionStore.getters.tblData['limit']
        this.allShow = workOrderSelectionStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getDataById(seq) {
      try {
        this.isLoading = true
        const payload = {
          sequence: seq,
          token: this.token,
          status: this.searchForm.status || "1"
        }
        await workOrderSelectionStore.dispatch('getDataTbl', payload)
        this.isLoading = false
        return workOrderSelectionStore.getters.tblData['workOrders']
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    toExport() {
      try {

      } catch (e) {
        throw new Error(e);
      }
    },
    async toSubmit() {
      try {
        if (this.selectionSum === 0) {
          this.errorFunction()
        } else {
          const payload = {
            //selectionSum: this.selectionSum,
            seqLst: this.checkedItems
          }
          await this.confirmSelectionFunction(payload)
          const result = workOrderSelectionStore.getters.saveResult
          if (result === true) {
            this.restoreCheckbox()
            this.successFunction()
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    restoreCheckbox(){
      this.checkedItems = []
      this.storedCheckItem = []
    },
    async confirmSelectionFunction(payload) {
      try {
        const token = this.token
        const result = await this.$swal.fire({
          title: 'ต้องการรับงานหรือไม่',
          text: 'กดยืนยันเพื่อทำการรับงาน',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })
        if (result.isConfirmed) {
          await workOrderSelectionStore.dispatch('selectData', {token, payload})
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async handleCheckboxReset(){
      this.storedCheckItem = this.checkedItems
      await this.getData()
      this.checkedItems = []
      for (let x = 0; x < this.storedCheckItem.length; x++) {
        const index = this.tblData.filter(item => item.seq === this.storedCheckItem[x])
        if(index.length > 0){
            this.checkedItems.push(this.storedCheckItem[x])
        }
      }
    },
    async handleRowClick(data, type) {
      try {
        await this.handleCheckboxReset()
        const dataByID = await this.getDataById(data.seq)
        const dataModal = dataByID[0]
        if (dataByID.length > 0) {
          dataModal.type = type
          dataModal.master = await masterStore.getters.workOrderDropdown
          this.rowOnClick = dataModal
          this.$refs.workOrderSelectionModal.handleModal()
        }else{
          await this.getData()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'รับงานสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage)
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    changeDateFormat(dateData) {
      try {
        return dateData ? moment(dateData, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        this.$swal.fire({
          title: 'ดำเนินการไม่สำเร็จ',
          text: 'กรุณาเลือก Work Order',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    getStatusColor(data) {
      try {
        let result = 'planText'
        if (data === 'Accept') {
          result = 'approveText'
        }
        if (data === 'Reject') {
          result = 'rejectText'
        }
        if (data === 'Waiting') {
          result = 'waitingText'
        }
        return result
      } catch (errorFunction) {
        throw new Error(e);
      }
    },
  }
}
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.table {
  width: 200%;
}

.table th:first-child,
.table th:nth-child(2),
.table th:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #D8EBFF;
}

.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #F8F9FB;
}
</style>
