<template>
  <div class="modal fade" id="modal-default" ref="machineMaModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{
              formData.type === 0 ? 'เพิ่มข้อมูลการบำรุงรักษาเครื่องจักร' : 'แก้ไขข้อมูลการบำรุงรักษาเครื่องจักร'
            }}</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>รหัสเครื่องจักร</label>
                  <select class="select form-control" v-model="formData.machineMaCode">
                    <option value="" disabled selected hidden>เลือกเครื่องจักร</option>
                    <option v-for="(item) in selectMachine">{{ item['machineCode'] }}</option>
                  </select>
                  <span class="text-danger">{{ validateResult.machineMaCode }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>เริ่มต้น</label>
                  <input type="date" v-model="formData.startDate" class="form-control" placeholder="dd/mm/yyyy">
                  <span class="text-danger">{{ validateResult.startDate }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>เวลา</label>
                  <input type="time" v-model="formData.startTime" class="form-control" placeholder="hh:mm">
                  <span class="text-danger">{{ validateResult.startTime }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>สิ้นสุด</label>
                  <input type="date" v-model="formData.endDate" class="form-control" placeholder="dd/mm/yyyy">
                  <span class="text-danger">{{ validateResult.endDate }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>เวลา</label>
                  <input type="time" v-model="formData.endTime" class="form-control" placeholder="hh:mm">
                  <span class="text-danger">{{ validateResult.endTime }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label>หมายเหตุ</label>
                <textarea type="text" v-model="formData.detail" class="form-control" placeholder="หมายเหตุ"></textarea>
                <span class="text-danger">{{ validateResult.detail }}</span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
              <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import machineMaStore from "@/store/machineMaStore";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import moment from 'moment';
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "MachineMaModal",
  components: {Loading},
  props: ['item'],
  data() {
    try{
      return {
        isLoading: false,
        selectMachine: [],
        token: loginStore.getters.token,
        validateResult: {
          machineMaCode: '',
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          detail: ''
          },
        formData: {
          type: '',
          machineMaCode: '',
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          detail: '',
          seq: null
        }
      }
    }catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        machineMaCode: Yup.mixed().oneOf(this.selectMachine.map(c => c.machineCode)).required('รหัสเครื่องจักรห้ามเป็นค่าว่าง'),
        startDate: Yup.string().required('วันที่เริ่มต้นห้ามเป็นค่าว่าง'),
        startTime: Yup.string().required('เวลาเริ่มต้นห้ามเป็นค่าว่าง'),
        endDate: Yup.string().required('วันที่สิ้นสุดห้ามเป็นค่าว่าง'),
        endTime: Yup.string().required('เวลาสิ้นสุดห้ามเป็นค่าว่าง'),
        detail: Yup.string().required('รายละเอียดห้ามเป็นค่าว่าง'),
      });
    }
  },
  mounted() {
    try{
      this.getMachine()
    }catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    item: {
      handler() {
        try{
          this.resetFormData()
          if (this.item) {
            this.formData.type = this.item['type'];
            this.formData.seq = this.item['seq'];
            this.formData.machineMaCode = this.item['machineCode'];
            this.formData.startDate = this.splitDate(this.item['machineMaStart']);
            this.formData.startTime = this.splitTime(this.item['machineMaStart']);
            this.formData.endDate = this.splitDate(this.item['machineMaEnd']);
            this.formData.endTime = this.splitTime(this.item['machineMaEnd']);
            this.formData.detail = this.item['machineMaDetail'];
          }
        }catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.machineMaModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try{
        this.formSchema.validate(this.formData, {abortEarly: false}).then(async () => {
          this.isLoading = true;
          const token = this.token
          const datetimeStart = moment(`${this.formData.startDate}T${this.formData.startTime}`).toISOString();
          const datetimeEnd = moment(`${this.formData.endDate}T${this.formData.endTime}`).toISOString();
          if (this.formData.type === 0) {
            const payload = {
              machineCode: this.formData.machineMaCode,
              machineMaStart: datetimeStart,
              machineMaEnd: datetimeEnd,
              machineMaDetail: this.formData.detail,
            }
            await machineMaStore.dispatch('createData', {token, payload})
          }
          if (this.formData.type === 1) {
            const datetimeStart = moment(`${this.formData.startDate}T${this.formData.startTime}`).toISOString();
            const datetimeEnd = moment(`${this.formData.endDate}T${this.formData.endTime}`).toISOString();
            const payload = {
              seq: this.formData.seq,
              machineCode: this.formData.machineMaCode,
              machineMaStart: datetimeStart,
              machineMaEnd: datetimeEnd,
              machineMaDetail: this.formData.detail,
            }
            await machineMaStore.dispatch('updateData', {token, payload})
          }
          const result = machineMaStore.getters.saveResult
          this.isLoading = false;
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
        }
        ).catch((err) => {
          this.isLoading = false;
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      }catch (e) {
        throw new Error(e);
      } 
    },
    splitDate(data) {
      try{
        if (data) {
          const startValueArr = data.split(' ');
          const dayStartVal = startValueArr[0].split('/');
          const dateStartValue = dayStartVal[2] + '-' + dayStartVal[1] + '-' + dayStartVal[0];
          return dateStartValue;
        } else {
          const dateStartValue = null;
          return dateStartValue;
        }
      }catch (e) {
        throw new Error(e);
      } 
    },
    splitTime(data) {
      try{
        if (data) {
          const startValueArr = data.split(' ');
          const timeStartValue = startValueArr[1];
          return timeStartValue;
        } else {
          const timeStartValue = null;
          return timeStartValue;
        }
      }catch (e) {
        throw new Error(e);
      } 
    },
    resetFormData() {
      try {
        this.validateResult = {
          machineMaCode: '',
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          detail: ''
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.machineMaModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError =  machineMaStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    async getMachine() {
      try{
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('machineMaForm', payload)
        this.selectMachine = masterStore.getters.machineMaForm
      }catch (e) {
        throw new Error(e);
      }
    },
    handleCancel(){
      $(this.$refs.machineMaModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>

<style scoped></style>
    