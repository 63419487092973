<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-3">
          <Loading v-if="isLoading" />
          <div class="col-sm-12">
            <Header />
          </div>
          <div class="col-sm-6">
            <h1 class="m-0">รายการแผนผลิต</h1>
          </div>
          <div class="col-sm-6">
            <production-plan-list-modal @form-submitted="getData(currentPage, 10)" ref="productionPlanListModal"
              :item="rowOnClick" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="row">
                        <SearchField
                          :item="{ selected: 'machine', other: {}, classCondition: 'col-sm-12', emitData: 'machine-field' }"
                          @handle-emit-field="handleSearchEmit" />
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>วันเริ่มต้นสั่งงาน</label>
                            <input type="date" v-model="searchForm.orderStartDate" class="form-control" placeholder="วว/ดด/ปปปป">
                          </div>
                        </div>
                        <SearchField
                          :item="{ selected: 'sale', other: { condition: 'role', expect: { compareCondition: 'not equal', data: '003' } }, classCondition: 'col-sm-6', emitData: 'sale-field' }"
                          @handle-emit-field="handleSearchEmit" />
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>วันสิ้นสุดสั่งงาน</label>
                            <input type="date" v-model="searchForm.orderFinnishDate" class="form-control" placeholder="วว/ดด/ปปปป">
                          </div>
                        </div>
                        <SearchField
                          :item="{ selected: 'customer', other: {}, classCondition: 'col-sm-6', emitData: 'customer-field' }"
                          @handle-emit-field="handleSearchEmit" />
                          <div class="col-sm-6">
                          <div class="form-group">
                            <label>ชื่องาน</label>
                            <input type="text" v-model="searchForm.orderName" class="form-control"
                                   placeholder="ชื่องาน">
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>หมายเลข</label>
                            <input type="text" v-model="searchForm.woNumber" class="form-control"
                                   placeholder="หมายเลข work order">
                          </div>
                        </div>
                        <div class="col-sm-2" style="display: none;">
                          <div class="form-group">
                            <label>ประเภทการค้นหา</label>
                            <select v-model="searchForm.type" class="select form-control">
                              <option value="" disabled selected hidden>ประเภทการค้นหา
                              </option>
                              <option value="1">หมายเลข Work Order</option>
                              <option value="2">เซลล์</option>
                              <option value="3">ชื่องาน</option>
                              <option value="4">ชื่อลูกค้า</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-10" style="display: none;">
                          <div class="form-group">
                            <label>ค้นหา</label>
                            <input type="text" v-model="searchForm.search" class="form-control" placeholder="ค้นหา">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-sm-2" style="padding-top: 2%">
                      <button @click.prevent="getData()" class="btn btn-primary btn-block" type="submit">ค้นหา
                      </button>
                      <button @click.prevent="toExport()" class="btn btn-outline-primary btn-block" type="submit">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table class="table table-sm">
                    <thead>
                      <tr>
                        <th style="width: 1%"></th>
                        <th style="width: 7%"></th>
                        <th class="on-pointer" @click.prevent="handleSortData('machine')">เครื่อง
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'machine' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('woNumber')">หมายเลข
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woNumber' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('status')">สถานะ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'status' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('ctmName')">ชื่อลูกค้า
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'ctmName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('woWcode')">ชื่องาน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woWcode' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click="handleSortData('woMole')">ขนาดงาน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woMole' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click="handleSortData('woPage')">หน้ากระดาษ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woPage' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click="handleSortData('woPage')">วัตถุดิบ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woPage' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click="handleSortData('woPage')">วัตถุดิบจริง
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woPage' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('empName')">เซลล์
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'empName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('woOrderDate')">วันที่เปิด
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woOrderDate' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('woProdAmount')">จำนวน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woProdAmount' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdStart')">เริ่ม (ประมาณ)
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdStart' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdFinish')">สิ้นสุด (ประมาณ)
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdFinish' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plNormal')">ชม. (ประมาณ)
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plNormal' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdStart')">เริ่ม (จริง)
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdStart' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdFinish')">สิ้นสุด (จริง)
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdFinish' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdFinish')">ตัดต่อม้วน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdFinish' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdFinish')">ตั้งงาน
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdFinish' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdDeliver')">กำหนดส่ง
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdDeliver' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdDeliver')">OT
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdDeliver' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plProdDeliver')">OT พิเศษ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plProdDeliver' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('colorName')">สี
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'colorName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('woSide')">ขอบข้าง
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'colorName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('woGab')">ช่องไฟ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'colorName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('woBox')">บล๊อคพิมพ์
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'woBox' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plSpeedMin')">ความเร็ว
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plSpeedMin' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                        <th class="on-pointer" @click.prevent="handleSortData('plSpeedMin')">หมายเหตุ
                          <i
                            :class="`fa fa-arrow-${sortTable.column === 'plSpeedMin' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- -->
                      <tr class="rowHovered" v-for="(item) in tblData" :key="item"
                        @click.prevent="handleRowClick(item, 1, $event)">
                        <td>
                          <button :disabled="role !== '001' && role !== '005'" @click.prevent="doDelete(item, $event)"
                            class="btn btn-danger"><i class="fa fa-trash"></i>
                          </button>
                        </td>
                        <td>
                          <button :class="getButtonColor(item['wosCode'])" :disabled="role !== '001' && role !== '005'"
                            @click.prevent="handleRowClick(item, 2, $event)" class="btn"><i class="fa fa-edit"></i>{{
                              item['wosCode'] === '003' ? 'วางแผน' : 'ย้ายแผน'
                            }}
                          </button>
                        </td>
                        <td>{{ item['planning']['machine'] ? item['planning']['machine']['machineDetail'] : '-' }}</td>
                        <td>{{ item['woNumber'] ? item['woNumber'] : '-' }}</td>
                        <td>{{ item['woStatusName'] }}</td>
                        <td>{{ item['ctmName'] }}</td>
                        <td>{{ item['woWcode'] }}</td>
                        <td>{{ item['woSizeV'] }} x {{ item['woSizeH'] }}</td>
                        <td>{{ item['woPage'] }}</td>
                        <td>{{ item['planning']['plMaterialHeight'] ? item['planning']['plMaterialHeight'] : '0' }}</td>
                        <td>{{ item['planning']['plmaterialCostCal'] ? item['planning']['plmaterialCostCal'] : '0' }}</td>
                        <td>{{ item['empName'] }}</td>
                        <td>{{ changeDateFormat(item['woOrderDate']) }}</td>
                        <td>{{ item['woProdAmount'] }}</td>
                        <td>{{ item['planning']['plProdStart'] ? changeDateFormat(item['planning']['plProdStart']) : '-' }}</td>
                        <td>{{ item['planning']['plProdFinish'] ? changeDateFormat(item['planning']['plProdFinish']) : '-' }}</td>
                        <td>{{ item['planning']['plNormal'] ? item['planning']['plNormal'] : '-' }}</td>
                        <td>{{ item['planning']['plProdStart'] ? changeDateFormat(item['planning']['plProdStart']) : '-' }}</td>
                        <td>{{ item['planning']['plProdFinish'] ? changeDateFormat(item['planning']['plProdFinish']) : '-' }}</td>
                        <td>{{ item['planning']['plRole'] ? item['planning']['plRole'] : '-' }}</td>
                        <td>{{ item['planning']['plStartTime'] ? item['planning']['plStartTime'] : '-' }}</td>
                        <td>{{ item['planning']['plProdDeliver'] ? changeDateFormat(item['planning']['plProdDeliver']) : '-' }}</td>
                        <td>{{ item['planning']['plOt'] ? item['planning']['plOt'] : '-' }}</td>
                        <td>{{ item['planning']['plOtHoliday'] ? item['planning']['plOtHoliday'] : '-' }}</td>
                        <td>{{ item['planning']['colorName'] ? item['planning']['colorName'] : '-' }}</td>
                        <td>{{ item['woSide'] }}</td>
                        <td>{{ item['woGab'] }}</td>
                        <td>{{ item['planning']['plBlockAmount'] ? item['planning']['plBlockAmount'] : '-' }}</td>
                        <td>{{ item['planning']['plSpeedMin'] ? item['planning']['plSpeedMin'] : '-' }}</td>
                        <td>{{ item['woRemark'] ? item['woRemark'] : '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1
                  ถึง 10 ของ
                  {{ allShow }}
                  ทั้งหมด
                </div>
              </div>
              <div class="col-sm-12 col-md-7 ">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination">
                    <li class="paginate_button page-item previous">
                      <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                        ก่อนหน้า
                      </button>
                    </li>
                    <li class="paginate_button page-item" :class="{ active: page === currentPage }" v-for="page in pages"
                      :key="page">
                      <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                    </li>
                    <li class="paginate_button page-item previous">
                      <button @click="nextPage()" :disabled="currentPage >= allPage[8]" class="page-link">ถัดไป
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import ProductionPlanListViewModal from "@/components/productionPlanList/ProductionPlanListViewModal.vue";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import productionListStore from "@/store/productionListStore";
import moment from "moment/moment";
import ProductionPlanListModal from "@/components/productionPlanList/ProductionPlanListModal.vue";
import { mistyrose } from "../../public/adminlte/plugins/bootstrap-colorpicker/js/bootstrap-colorpicker";
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";
import customerStore from "@/store/customerStore";
import FullCalender from "@/components/resourceCalendar/FullCalender.vue";
import SearchField from "@/components/boilerplate/SearchField.vue";

export default {
  name: "ProductionPlanList",
  components: { SearchField, FullCalender, Header, Loading, ProductionPlanListModal, ProductionPlanListViewModal, Content },
  data() {
    try {
      return {
        isLoading: false,
        sortTable: { column: null, sort: 'asc', icon: 'up' },
        tblData: null,
        rowOnClick: {},
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        checkedItems: [],
        token: loginStore.getters.token,
        employeeSeq: loginStore.getters.seq,
        role: loginStore.getters.role.roleCode,
        statusDropdown: null,
        searchForm: {
          machine: '',
          sale: '',
          customer: '',
          orderStartDate: '',
          orderFinnishDate: '',
          orderName: '',
          woNumber: ''
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    selectionSum() {
      return this.checkedItems.filter(item => item).length;
    },
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({ length: totalPages }, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  async mounted() {
    try {
      await this.getStatus()
      await this.getData()
    } catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    handleSearchEmit(data) {
     switch (data.emitData) {
       case "sale-field" :
         this.searchForm.sale = data.result
         break;
       case "machine-field":
         this.searchForm.machine = data.result
         break;
       case "customer-field" :
         this.searchForm.customer = data.result
         break;
       default :
         this.searchForm.sale = ""
         this.searchForm.machine = ""
         this.searchForm.customer = ""
         break;
     }
    },
    handleSortData(column) {
      this.sortTable.column = column
      this.sortTable.sort = this.sortTable.sort === 'desc' ? 'asc' : "desc"
      this.sortTable.icon = this.sortTable.sort === 'desc' ? 'down' : "up"
      const data = this.tblData
      const actionData = []
      for (let item of data) {
        if (typeof item[column] === "number") {
          item[column] = item[column].toString()
        }
        if (item[column] === undefined) {
          item[column] = ''
        }
        if (item[column] === null) {
          item[column] = ''
        }
        if (column === 'machineDetail') {
          if (item['planning'] !== null) {
            item[column] = item['planning']['machine'][column]
          } else {
            item[column] = ''
          }
        }
        if (column === 'colorCode' || column === 'plSpeedMin' || column === 'plNormal' || column === 'plProdStart' || column === 'plProdFinish' || column === 'plProdDeliver' || column === 'plPaperWidth' || column === 'plMaterialHeight' || column === 'colorName') {
          if (item['planning'] !== null) {
            item[column] = item['planning'][column]
          } else {
            item[column] = ''
          }
        }
        actionData.push(item)
      }
      if (column === 'woMole' || column === 'woProdAmount' || column === 'woPriceUnit' || column === 'plSpeedMin' || column === 'plNormal' || column === 'plPaperWidth' || column === 'plMaterialHeight' || column === 'woPage') {
        this.tblData = actionData.sort((a, b) => {
          if (this.sortTable.sort === "desc") {
            return a[column] - b[column];
          } else {
            return b[column] - a[column];
          }
        });
      } else {
        this.tblData = actionData.sort((a, b) => {
          if (this.sortTable.sort === "desc") {
            return a[column].localeCompare(b[column]);
          } else {
            return b[column].localeCompare(a[column]);
          }
        })
      }
    },
    async getStatus() {
      try {
        this.statusDropdown = masterStore.getters.statusDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData() {
      try {
        this.isLoading = true
        const payload = {
          token: this.token,
          page: this.currentPage,
          machine: this.searchForm.machine,
          sale: this.searchForm.sale,
          customer: this.searchForm.customer,
          orderStartDate: this.changeDateFormat(this.searchForm.orderStartDate),
          orderFinnishDate: this.changeDateFormat(this.searchForm.orderFinnishDate),
          orderName: this.searchForm.orderName,
          woNumber: this.searchForm.woNumber,
          limit: 10,
          employeeSeq: this.employeeSeq,
          status: 1
        }
        await productionListStore.dispatch('getDataTbl', payload)
        this.tblData = productionListStore.getters.tblData['workOrders']
        this.pageSize = productionListStore.getters.tblData['limit']
        this.allShow = productionListStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getDataById(seq) {
      try {
        this.isLoading = true
        const payload = {
          sequence: seq,
          token: this.token,
          employeeSeq: '',
        }
        if(this.role == '003'){
          payload.employeeSeq = this.employeeSeq
        }
        await productionListStore.dispatch('getDataTbl', payload)
        this.isLoading = false
        return productionListStore.getters.tblData['workOrders']
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async checkPlanning(seq) {
      try {
        const payload = {
          token: this.token,
          seq: seq
        }
        await productionListStore.dispatch('checkPlanning', payload)
        return productionListStore.getters.checkPlanning
      } catch (e) {
        throw new Error(e);
      }
    },
    toExport() {
      try {

      } catch (e) {
        throw new Error(e);
      }
    },
    async handleRowClick(data, type, event) {
      try {
        await this.getData(this.currentPage)
        const dataByID = await this.getDataById(data.seq)
        const dataModal = dataByID[0]
        if (dataByID.length > 0) {
          dataModal.type = type
          dataModal.planningRef = {}
          if (dataModal['woSeqRef']) {
            dataModal['planningRef'] = await this.checkPlanning(dataModal['woSeqRef'])
          }
          this.rowOnClick = dataModal
          if (type === 2) {
            this.$refs.productionPlanListModal.handleModal()
          }
        } else {
          await this.getData(this.currentPage)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    async doDelete(data, event) {
      try {
        event.stopPropagation();
        const payload = {
          token: loginStore.getters.token,
          seq: data.seq
        }
        const resultConfirm = await this.confirmDeleteFunction(payload)
        const result = productionListStore.getters.saveResult
        if (result === true && resultConfirm === true) {
          this.successFunction()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async confirmDeleteFunction(payload) {
      try {
        const result = await this.$swal.fire({
          title: 'ต้องการลบข้อมูลหรือไม่',
          text: 'กดยืนยันเพื่อทำการลบข้อมูล',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })
        if (result.isConfirmed) {
          await productionListStore.dispatch('deleteData', payload)
          return true
        } else {
          return false
        }

      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'ลบข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage)
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    changeDateFormat(dateData) {
      try {
        return dateData ? moment(dateData, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        this.$swal.fire({
          title: 'ดำเนินการไม่สำเร็จ',
          text: 'กรุณาเลือก Work Order',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    getStatusColor(data) {
      try {
        let result = 'planText'
        if (data === '003') {
          result = 'approveText'
        }
        if (data === '004') {
          result = 'planText'
        }
        return result
      } catch (errorFunction) {
        throw new Error(e);
      }
    },
    getButtonColor(data) {
      try {
        let result = ''
        if (data === '003') {
          result = 'planButton'
        }
        if (data === '004') {
          result = 'planEachTaskBunton'
        }
        return result
      } catch (e) {
        throw new Error(e);
      }
    },
  }
}
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.table {
  width: 200%;
}

.table th:first-child,
.table th:nth-child(2),
.table th:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #D8EBFF;
}

.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #F8F9FB;
}
</style>
