<template>
  <FullCalendar
      :options="calendarOptions"
      ref="calendar"
      style="width: 100%;"
  >
  </FullCalendar>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import machineStore from "@/store/machineStore";
import productionListStore from "@/store/productionListStore";
import loginStore from "@/store/loginStore";

export default {
  name: "MachineCalendar",
  components: { FullCalendar },
  props: {
    resourceData: {
      type: Object,
      default: () => ({ resource: [], events: [] }) // Ensure a default structure
    },
  },

  data() {
    return {
      isLoading: false,
      token: loginStore.getters.token,
      calendarOptions: {
        plugins: [dayGridPlugin, resourceTimelinePlugin],
        initialView: 'timelineDay',
        views: {
          resourceTimelineDay: {
            buttonText: 'Day',
            slotLabelInterval: {hours: 1},
            slotLabelFormat: {
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              hour12: false,
            },
            slotMinTime: '00:00:00',
            slotMaxTime: '24:00:00',
          },
          resourceTimelineMonth: {
            buttonText: 'Month',
            slotLabelInterval: {day: 1},
            slotLabelFormat: {
              day: 'numeric',
            },
          },
          resourceTimelineWeek: {
            buttonText: 'Week',
            slotLabelFormat: [
              {
                day: 'numeric',
                weekday: 'long',
              },
              {
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                hour12: false,
              }
            ],
            slotMinTime: '00:00:00',
            slotMaxTime: '24:00:00'
          },
        },
        resources: [],
        events: [],
        resourceAreaHeaderContent: false,
        aspectRatio: 2,
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "timelineDay,timelineWeek",
        },
        editable: false,
        eventColor: [],
        eventClick: function (info) {
          console.log(info.event.extendedProps);
        },
      },
    }
  },
  watch: {
    resourceData: {
      handler(newValue) {
        this.calendarOptions.resources = newValue.resource;
        this.calendarOptions.events = newValue.events;
        this.$nextTick(() => {
          if (this.$refs.calendar) {
            this.$refs.calendar.getApi().render();
          }
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    refreshCalendar() {
      if (this.$refs.calendar) {
        this.$refs.calendar.getApi().render();
      }
    },
  },
};
</script>

<style scoped>
</style>
