import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
export default createStore({
    state: {
        tblData: Object,
        saveResult: Boolean,
    },
    getters: {
        tblData: state => state.tblData,
        saveResult: state => state.saveResult
    },
    mutations: {
        setDataTbl(state, payload) {
            state.tblData = payload
        },
        setSave(state, result) {
            state.saveResult = result
        }
    },
    actions: {
        async getDataTbl({commit}, {token, page, supplier, amount, mtType, createDate, limit}) {
            try {
                if (!limit) {
                    limit = 10
                }
                if (!page) {
                    page = 1
                }
                const result = await axiosInstance.get(`/store/findAllHistory?page=${page}&suplier=${supplier}&limit=${limit}&amount=${amount}&materialType=${mtType}&createDate=${createDate}`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            } catch (error) {
                commit('setUser', false);
            }
        },
    },
    modules: {}
})
