<template>
    <Content>
        <div class="content-header">
            <div class="container-fluid">
                <Loading v-if="isLoading"/>
                <div class="row mb-3">
                  <div class="col-sm-12">
                    <Header/>
                  </div>
                    <div class="col-sm-6">
                        <h1 class="m-0">เตรียมพิมพ์</h1>
                    </div>
                    <div class="col-sm-6">
                        <PrepressModal @form-submitted="getData(currentPage)" ref="prepressModal" :item="rowOnClick" />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <div class="card card-primary">
                            <form>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <div class="form-group">
                                                <label>ประเภทการค้นหา</label>
                                                <select v-model="searchForm.type" class="select form-control">
                                                    <option value="" disabled selected hidden>ประเภทการค้นหา
                                                    </option>
                                                    <option value="1">หมายเลข Work Order</option>
                                                    <option value="2">เซลล์</option>
                                                    <option value="3">ชื่องาน</option>
                                                    <option value="4">ชื่อลูกค้า</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-2 align-self-end">
                                            <button @click.prevent="getData(1)" class="btn btn-primary btn-block"
                                                type="submit">ค้นหา</button>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label>วันที่เริ่มต้น</label>
                                                <input type="date" class="form-control" placeholder="dd/mm/yyyy">
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="form-group">
                                                <label>วันที่สิ้นสุด</label>
                                                <input type="date" class="form-control" placeholder="dd/mm/yyyy">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th style="width: 7%"></th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('woStatusName')">หมายเลข
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'woStatusName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('cgName')">เครื่อง
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'cgName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('status')">สถานะ
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'status' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('ctmName')">ชื่อลูกค้า
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'ctmName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('woNumber')">ชื่องาน
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'woNumber' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('wotName')">ประเภทงาน
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'wotName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('materialName')">กระดาษ
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'materialName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('woMole')">ขนาดงาน
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'woMole' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('empName')">เซลล์
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'empName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('woProdAmount')">จำนวนผลิต
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'woProdAmount' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('woPriceUnit')">ราคา
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'woPriceUnit' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('woOrderDate')">วันที่เปิด/วันที่ส่งงาน
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'woOrderDate' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                                <th class="on-pointer"  @click.prevent="handleSortData('status')">สถานะ
                                                    <i :class="`fa fa-arrow-${sortTable.column === 'status' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="rowHovered" v-for="(item) in tblData" :key="item">
                                                <td>
                                                    <button :class="getButtonColor(item['wosCode'])"
                                                        :disabled="role !== '001' && role !== '005'"
                                                        @click="handleRowClick(item, 0, $event)" class="btn"><i
                                                            class="fa fa-edit"></i>เตรียมพิมพ์
                                                    </button>
                                                </td>
                                                <td>{{ item[''] ? item[''] : '-' }}</td>
                                                <td>{{ item[''] ? item[''] : '-' }}</td>
                                                <td :class="getStatusColor(item['wosCode'])">{{ item['woStatusName'] }}</td>
                                                <td>{{ item['ctmName'] }}</td>
                                                <td>{{ item['woNumber'] }}</td>
                                                <td>{{ item['wotName'] }}</td>
                                                <td>{{ item['materialName'] }}</td>
                                                <td>{{ item['woMole'] }}</td>
                                                <td>{{ item['empName'] }}</td>
                                                <td>{{ item['woProdAmount'] }}</td>
                                                <td>{{ item['woPriceUnit'] }}</td>
                                                <td>เปิด : {{ changeDateFormat(item['woOrderDate']) }} <br> ส่ง :
                                                    {{ changeDateFormat(item['woDeliverDate']) }}
                                                </td>
                                                <td
                                                    :class="item['status'] === '1' ? 'activeTextToggle' : 'inActiveTextToggle'">
                                                    {{ item['status'] === '1' ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1
                                    ถึง 10 ของ
                                    {{ allShow }}
                                    ทั้งหมด
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7 ">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination">
                                        <li class="paginate_button page-item previous">
                                            <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                                                ก่อนหน้า
                                            </button>
                                        </li>
                                        <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                                            v-for="page in pages" :key="page">
                                            <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                                        </li>
                                        <li class="paginate_button page-item previous">
                                            <button @click="nextPage()" :disabled="currentPage >= allPage[8]"
                                                class="page-link">ถัดไป
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import PrepressModal from "@/components/prepress/PrepressModal.vue";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import prepressStore from "@/store/prepressStore";
import moment from "moment/moment";
import { mistyrose } from "../../public/adminlte/plugins/bootstrap-colorpicker/js/bootstrap-colorpicker";
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";

export default {
    name: "Prepress",
    components: {Header, Loading, PrepressModal, Content },
    data() {
        try {
            return {
                sortTable:{column:null,sort:'asc',icon:'up'},
                isLoading: false,
                tblData: null,
                rowOnClick: {},
                pageSize: 0,
                allPage: 0,
                currentPage: 1,
                allShow: 0,
                checkedItems: [],
                token: loginStore.getters.token,
                role: loginStore.getters.role.roleCode,
                statusDropdown: null,
                searchForm: {
                    type: '',
                    search: '',
                    status: '',
                    orderStartDate: '',
                    orderFinnishDate: ''
                }
            }
        } catch (e) {
            throw new Error(e);
        }
    },
    computed: {
        selectionSum() {
            return this.checkedItems.filter(item => item).length;
        },
        pages() {
            try {
                const totalPages = Math.ceil(this.allShow / this.pageSize);
                const maxPagesToShow = 9;
                const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

                if (totalPages <= maxPagesToShow) {
                    const result = Array.from({ length: totalPages }, (_, i) => i + 1);
                    this.allPage = result;
                    return result;
                } else {
                    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
                    let startPage;
                    let endPage;

                    if (currentPage <= halfMaxPagesToShow + 1) {
                        startPage = 1;
                        endPage = maxPagesToShow;
                    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
                        startPage = totalPages - maxPagesToShow + 1;
                        endPage = totalPages;
                    } else {
                        startPage = currentPage - halfMaxPagesToShow;
                        endPage = currentPage + halfMaxPagesToShow;
                    }

                    const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
                    this.allPage = result;
                    return result;
                }
            } catch (e) {
                throw new Error(e);
            }
        },
    },
    async mounted() {
        try {
            this.getStatus()
            await this.getData(1)
        } catch (e) {
            throw new Error(e);
        }
    },
    methods: {
        handleSortData(column) {
            this.sortTable.column = column
            this.sortTable.sort =  this.sortTable.sort === 'desc'? 'asc':"desc"
            this.sortTable.icon = this.sortTable.sort === 'desc'? 'down':"up"
            const data = this.tblData
            const actionData = []
            for(let item of data){
                if(item[column] === null){
                item[column] = ''
                }
                actionData.push(item)
            }
            this.tblData = actionData.sort((a, b) => {
                if (this.sortTable.sort === "desc") {
                return a[column].localeCompare(b[column]);
                } else {
                return b[column].localeCompare(a[column]);
                }
            })
        },
        async getStatus() {
            try {
                this.statusDropdown = masterStore.getters.statusDropdown
            } catch (e) {
                throw new Error(e);
            }
        },
        async getData() {
            try {
                this.isLoading = true
                const payload = {
                    token: this.token,
                    page: this.currentPage,
                    type: this.searchForm.type,
                    search: this.searchForm.search,
                    orderStartDate: '',
                    orderFinnishDate: '',
                    limit: 10
                }
                await prepressStore.dispatch('getDataTbl', payload)
                this.tblData = prepressStore.getters.tblData['workOrders']
                this.pageSize = prepressStore.getters.tblData['limit']
                this.allShow = prepressStore.getters.tblData['count']
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
                throw new Error(e);
            }
        },
        async getDataById(seq) {
            try {
                this.isLoading = true
                const payload = {
                    sequence: seq,
                    token: this.token,
                }
                await prepressStore.dispatch('getDataTbl', payload)
                this.isLoading = false
                return prepressStore.getters.tblData['workOrders']
            } catch (e) {
                this.isLoading = false
                throw new Error(e);
            }
        },
        async handleRowClick(data, type, event) {
            try {
                await this.getData(this.currentPage)
                data['type'] = type
                event.stopPropagation();
                const dataByID = await this.getDataById(data.seq)
                const dataModal = dataByID[0]
                if(dataByID.length > 0){
                    if (type === 0) {
                        data['type'] = type
                        this.rowOnClick = data
                        this.$refs.prepressModal.handleModal()
                    }
                }else{
                    await this.getData(this.currentPage)
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        prevPage() {
            try {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.getData(this.currentPage, 10)
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        nextPage() {
            try {
                if (this.currentPage < this.allPage[8]) {
                    this.currentPage++;
                    this.getData(this.currentPage, 10)
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        goToPage(page) {
            try {
                this.currentPage = page;
                this.getData(this.currentPage, 10)
            } catch (e) {
                throw new Error(e);
            }
        },
        successFunction() {
            try {
                this.$swal.fire({
                    title: 'ลบข้อมูลสำเร็จ',
                    text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
                    icon: 'success',
                    confirmButtonText: 'ยืนยัน'
                }).then(() => {
                    this.getData(this.currentPage)
                })
            } catch (e) {
                throw new Error(e);
            }
        },
        changeDateFormat(dateData) {
            try {
                return dateData ? moment(dateData, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
            } catch (e) {
                throw new Error(e);
            }
        },
        errorFunction() {
            try {
                this.$swal.fire({
                    title: 'ดำเนินการไม่สำเร็จ',
                    text: 'กรุณาเลือก Work Order',
                    icon: 'error',
                    confirmButtonText: 'ยืนยัน'
                })
            } catch (e) {
                throw new Error(e);
            }
        },
        getStatusColor(data) {
            try {
                let result = 'planText'
                if (data === '003') {
                    result = 'approveText'
                }
                if (data === '004') {
                    result = 'planText'
                }
                return result
            } catch (errorFunction) {
                throw new Error(e);
            }
        },
        getButtonColor(data) {
            try {
                let result = ''
                if (data === '003') {
                    result = 'planButton'
                }
                if (data === '004') {
                    result = 'planButton'
                }
                return result = 'planButton'
            } catch (e) {
                throw new Error(e);
            }
        },
    }
}
</script>

<style scoped>
.table-container {
    overflow-x: auto;
}

.table {
    width: 200%;
}

.table th:first-child,
.table th:nth-child(2),
.table th:nth-child(3) {
    position: sticky;
    left: 0;
    background-color: #D8EBFF;
}

.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(3) {
    position: sticky;
    left: 0;
    background-color: #F8F9FB;
}
</style>
