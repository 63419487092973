<template>
  <div class="modal fade" id="modal-default" ref="packingAddModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">รับเข้าสต๊อคสินค้า</h4>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="card card-primary">
              <div class="card-body">
                <div class="row">
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                    <span>{{ item.customerName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่องาน</span><br>
                    <span>{{ item.stockWname }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ขนาดงาน</span><br>
                    <span>{{ item.sizeMole }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-12">
                    <span class="inActiveText">หมายเหตุ</span><br>
                    <span>{{ item.stockRemark }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>จำนวนที่แพ็คกิ้งนับได้</label>
                  <input v-model="formData.count" type="number" class="form-control"
                         placeholder="จำนวนที่แพ็คกิ้งนับได้">
                  <span class="text-danger">{{ validateResult.count }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>จำนวนดวงต่อหน่วย</label>
                  <input v-model="formData.star" type="number" class="form-control"
                         placeholder="จำนวนที่แพ็คกิ้งนับได้">
                  <span class="text-danger">{{ validateResult.star }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>ม้วน</label>
                  <select class="select form-control" v-model="formData.packingType">
                    <option value="" disabled selected hidden>เลือกประเภทแพ็คกิ้ง</option>
                    <option v-for="(item) in selectPackingType" :value="item.seq">
                      {{ item['ptName'] }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.packingType }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Amount</label>
                  <input disabled v-model="formData.amount" type="number" class="form-control" placeholder="จำนวน">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 image-container">
                <img v-if="item.photoOne" :src="getImageSource(item.photoOne)" alt="Uploaded Image" class="set-image"/>
                <img v-if="item.photoTwo" :src="getImageSource(item.photoTwo)" alt="Uploaded Image" class="set-image"/>
                <img v-if="item.photoThree" :src="getImageSource(item.photoThree)" alt="Uploaded Image" class="set-image"/>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="handleCancel()" class="btn btn-default float-right" data-dismiss="modal">
              ยกเลิก
            </button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import packingStore from "@/store/packingStore";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";
import environmentStore from "@/store/environmentStore";

export default {
  name: "PackingAddModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        employeeSeq: loginStore.getters.seq,
        role: loginStore.getters.role.roleCode,
        selectPackingType: [],
        baseurl: environmentStore.getters.upload,
        validateResult: {
          count: null,
          packingType: null,
        },
        formData: {
          count: null,
          packingType: null,
          star: null,
          amount: null,
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        count: Yup.string().required('กรุณาเลือกกรอกจำนวน'),
        packingType: Yup.mixed().oneOf(this.selectPackingType.map(c => c.seq)).required('ประเภทแพ็คกิ้งห้ามเป็นค่าว่าง')
      });
    },
    calculateAmount() {
      let response = 0
      if (this.formData.packingType == 1) {
        response = (this.formData.count * this.item.woStartRoll)
      } else {
        response = this.formData.count
      }
      return response;
    }
  },
  mounted() {
    try {
      this.getPackingType()
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.count = this.item['stiAmount'];
            this.formData.packingType = this.item['ptSeq'];
            this.formData.amount = this.item['stiCountAmount'];
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    },
    calculateAmount(newVal) {
      this.formData.amount = newVal;
    },
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.packingAddModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    getImageSource(image) {
      try {
        console.log(this.baseurl + image)
        if (image.startsWith('/upload')) {
          console.log('this is baseurl img : '+this.baseurl + image);
          return this.baseurl + image;
        } else {
          return image;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    cancel() {
      try {
        this.formData.count = null
        this.formData.packingType = null
        this.formData.amount = null
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.isLoading = true
        this.formSchema.validate(this.formData, {abortEarly: false}).then(async () => {
              const token = this.token
              const payload = {
                stockSeq: this.item.seq,
                ptSeq: this.formData.packingType,
                stockAmoutPacking: this.formData.count,
                stockAmoutStartRowUnit: this.formData.star,
                stockAmout: this.formData.amount,
                employeeSeq: this.employeeSeq
              }
              console.log(payload);
              await packingStore.dispatch('stockIn', {token, payload})
              const result = packingStore.getters.saveResult
              this.isLoading = false
              if (result) {
                this.successFunction()
              } else {
                this.errorFunction()
              }
            }
        ).catch((err) => {
          this.isLoading = false
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })

      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.formData = {
          count: null,
          packingType: null,
          amount: null
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.packingAddModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = packingStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    async getPackingType() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('packingTypeForm', payload)
        this.selectPackingType = masterStore.getters.packingTypeForm
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel() {
      $(this.$refs.packingAddModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>

<style scoped></style>
      