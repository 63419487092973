<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" role="button">
          <i class="fas fa-bars"></i></a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="fa fa-user-circle mr-1"></i> {{user}}
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <button @click="logout" class="dropdown-item">
            <i class="fas fa-sign-out-alt mr-2"></i> ออกจากระบบ
          </button>
        </div>
      </li>

    </ul>
  </nav>
</template>

<script>
import loginStore from "@/store/loginStore";
export default {
  name: "Navbar",
  data(){
    return {
      user:''
    }
  },
  mounted() {
    this.getUser()
  },
  methods:{
    logout(){
      localStorage.removeItem('accessToken');
      loginStore.dispatch('logout')
      this.$router.push({name:'login'})
    },
    getUser(){
      this.user = loginStore.getters.firstName + ' ' + loginStore.getters.lastName
    },
  },

}

</script>

<style scoped>

</style>
