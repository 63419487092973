import { createStore } from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
export default createStore({
    state: {
        healthCheckStatus:'bad'
    },
    getters: {
        healthCheckStatus: state => state.healthCheckStatus,
    },
    mutations: {
        setDataHealthCheck (state,payload){
            state.healthCheckStatus = payload
        },
    },
    actions: {
        async getHealthCheckStatus({commit}){
            try{
                let customURL = `/health`
                const result = await axiosInstance.get(customURL)
                commit('setDataHealthCheck',result.data.status)
            } catch (error:any) {
                commit('setDataHealthCheck','bad')
            }
        },
    },
    modules: {
    }
})
