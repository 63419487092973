<template>
  <li class="nav-item" :class="{'menu-open': isSubmenuOpened}">
    <a class="nav-link" @click="toggleSubmenu()">
      <i class="nav-icon" :class="icon" v-if="icon"></i>
      <p>
        {{name}}
        <i v-if="objectSubMenu" class="right fas fa-angle-left"></i>
      </p>
    </a>
    <ul v-if="isSubmenuOpened" class="nav nav-treeview">
      <li v-for="(subMenuResult) in subMenu" :key="subMenuResult['menuCode']" class="nav-item subSideItem">
        <router-link :to="`${subMenuResult['menuPath']}`" :class="activeSubItem(subMenuResult) ? 'nav-link active': 'nav-link'" @click="openSubmenu">
          <i class="fa fa-circle nav-icon"></i>
          <p>{{subMenuResult['menuName']}}</p>
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "SidebarItem",
  props:{
    icon:{
      type:String,
      default:null
    },
    link : {
      type:String,
      default: null
    },
    name : {
      type:String,
      default:null
    },
    seq:null,
    subMenu : Object,
    default:null
  },
  data () {
    return {
      pathData:this.$route['path'],
      objectSubMenu : null,
      isSubmenuOpened: false
    }
  },
  methods:{
    transformSubMenu(){
      this.objectSubMenu = this.subMenu.length > 0;
    },
    toggleSubmenu() {
      this.isSubmenuOpened = !this.isSubmenuOpened
    },
    openSubmenu() {
      this.isSubmenuOpened = true;
    },
    activeSubItem(data){
      return this.$route['path'] === data['menuPath']
    },
  },
  computed: {
    isActiveNav: function() {
      return this.subMenu.some(item => {
        const submenuPath = item['menuPath'];
        return this.$route['path'].startsWith(submenuPath) && item.menuHead === this.seq;
      });
    },
  },
  watch: {
    '$route.path': function() {
      if (!this.isActiveNav) {
        this.isSubmenuOpened = false;
      }
    }
  },
  mounted() {
    this.transformSubMenu();
    if (this.isActiveNav) {
      this.isSubmenuOpened = true;
    }
  }
}
</script>

<style scoped>

</style>
