<template>
  <div class="modal fade" id="modal-default" ref="employeeModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">เพิ่มพนักงาน</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>รหัสผู้ใช้</label>
                  <input type="text" v-model="formData.employeeCode" class="form-control"
                         placeholder="รหัสผู้ใช้">
                  <span class="text-danger">{{ validateResult.employeeCode }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>สิทธิ์การใช้งาน</label>
                  <select class="select form-control" v-model="formData.roleCode">
                    <option value="" disabled selected hidden>สิทธิ์การใช้งาน</option>
                    <option :value="item['roleCode']" v-for="(item) in role" :key="item['roleCode']">
                      {{ item['roleName'] }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.roleCode }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>รหัสพนักงาน</label>
                  <input type="text" v-model="formData.employeeUsername" class="form-control"
                         placeholder="รหัสพนักงาน">
                  <span class="text-danger">{{ validateResult.employeeUsername }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>รหัสผ่าน</label>
                  <input type="password" v-model="formData.employeePassword" class="form-control">
                  <span class="text-danger">{{ validateResult.employeePassword }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ชื่อ</label>
                  <input type="text" v-model="formData.employeeFirstname" class="form-control"
                         placeholder="ชื่อ">
                  <span class="text-danger">{{ validateResult.employeeFirstname }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>นามสกุล</label>
                  <input type="text" v-model="formData.employeeLastname" class="form-control"
                         placeholder="นามสกุล">
                  <span class="text-danger">{{ validateResult.employeeLastname }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>โทรศัพท์</label>
                  <input type="text" v-model="formData.employeePhone" class="form-control"
                         placeholder="โทรศัพท์">
                  <span class="text-danger">{{ validateResult.employeePhone }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>อีเมล</label>
                  <input type="text" v-model="formData.employeeEmail" class="form-control"
                         placeholder="อีเมล">
                  <span class="text-danger">{{ validateResult.employeeEmail }}</span>
                </div>
              </div>
              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <label>การเข้าถึงเมนูการใช้งาน</label>
              </div>
              <table class="table table-sm">
                <thead>
                <tr>
                  <th width="30%">เมนู</th>
                  <th class="text-center" width="14%">เลือกทั้งหมด</th>
                  <th class="text-center" width="14%">ดู</th>
                  <th class="text-center" width="14%">เพิ่ม</th>
                  <th class="text-center" width="14%">แก้ไข</th>
                  <th class="text-center" width="14%">ลบ</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in this.formData.employeePermissions" :key="item['menuSeq']">
                  <td>{{ item['menuName'] }}</td>
                  <td class="text-center"><input :checked="handleCheckedAll(item)"
                                                 @click="handleSelectAll($event, index)" type="checkbox"/></td>
                  <td class="text-center"><input v-model="item['epView']" type="checkbox"/></td>
                  <td class="text-center"><input v-model="item['epCreate']" type="checkbox"/></td>
                  <td class="text-center"><input v-model="item['epUpdate']" type="checkbox"/></td>
                  <td class="text-center"><input v-model="item['epDelete']" type="checkbox"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="handleCancel()" class="btn btn-default float-right"
            >
              ยกเลิก
            </button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import loginStore from "@/store/loginStore";
import employeeStore from "@/store/employeeStore";
import sidebarMenuStore from "@/store/sidebarMenuStore";
import masterStore from "@/store/MasterStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "EmployeeModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        manu: null,
        token: loginStore.getters.token,
        empForm: [],
        role: [],
        validateResult: {
          roleCode: '',
          employeeCode: '',
          employeeUsername: '',
          employeeFirstname: '',
          employeeLastname: '',
          employeePhone: '',
          employeeEmail: '',
          employeePassword: '',
        },
        formData: {
          type: '',
          roleCode: '',
          employeeCode: '',
          employeeUsername: '',
          employeeFirstname: '',
          employeeLastname: '',
          employeePhone: '',
          employeeEmail: '',
          employeePassword: '',
          employeeStatus: '',
          employeePermissions: [],
          seq: null
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.type = this.item.type
            this.formData.seq = this.item.seq
            this.formData.roleCode = this.item.roleCode
            this.formData.employeeCode = this.item.employeeCode
            this.formData.employeeUsername = this.item.employeeUsername
            this.formData.employeeFirstname = this.item.employeeFirstname
            this.formData.employeeLastname = this.item.employeeLastname
            this.formData.employeePassword = this.item.employeePassword
            this.formData.employeePhone = this.item.employeePhone
            this.formData.employeeEmail = this.item.employeeEmail
            this.formData.employeeStatus = this.item.employeeStatus
            this.formData.employeePermissions = this.permissionSet(this.item.employeePermissions ? this.item.employeePermissions : [])
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  async mounted() {
    try {
      await this.activeMaster()
      await this.getManu()
      await this.getRole()
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      try {
        return Yup.object().shape({
          roleCode: Yup.mixed().oneOf(this.role.map(c => c.roleCode)).required('สิทธิ์การใช้งานห้ามเป็นค่าว่าง'),
          employeeCode: Yup.string().required('รหัสผู้ใช้ไม่สามารถเป็นค่าว่างได้'),
          employeeUsername: Yup.string().required('รหัสพนักงานไม่สามารถเป็นค่าว่างได้'),
          employeeFirstname: Yup.string().required('ชื่อไม่สามารถเป็นค่าว่างได้'),
          employeeLastname: Yup.string().required('นามสกุลไม่สามารถเป็นค่าว่างได้'),
          employeePhone: Yup.string().nullable().matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, 'รูปแบบของเบอร์โทรศัพท์ไม่ถูกต้อง'),
          employeeEmail: Yup.string().nullable().email('รูปแบบของอีเมลไม่ถูกต้อง'),
          employeePassword: Yup.string().required('รหัสผ่านไม่สามารถเป็นค่าว่างได้'),
        });
      } catch (e) {
        throw new Error(e);
      }
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.employeeModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async activeMaster() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('empForm', payload)
        this.empForm = masterStore.getters.empForm
      } catch (e) {
        throw new Error(e);
      }
    },
    async getRole() {
      try {
        this.role = this.empForm.roleCode
      } catch (e) {
        throw new Error(e);
      }
    },
    permissionSet(data) {
      try {
        const menuData = this.empForm['menuLst'].filter(c => c.menuHead !== 0)
        let allMenu = []
        if (data) {
          const dataGetting = data
          allMenu = menuData.map(item => ({menuSeq: item['seq'], menuName: item['menuName']}));
          const permissions = dataGetting.map(obj => ({
            menuSeq: obj.menuSeq,
            epView: obj.epView,
            epUpdate: obj.epUpdate,
            epCreate: obj.epCreate,
            epDelete: obj.epDelete
          }));

          return allMenu.map(menu => {
            const permission = permissions.find(p => p.menuSeq === menu.menuSeq);
            return {
              ...menu,
              epView: permission ? (permission.epView === "1") : false,
              epUpdate: permission ? (permission.epUpdate === "1") : false,
              epCreate: permission ? (permission.epCreate === "1") : false,
              epDelete: permission ? (permission.epDelete === "1") : false
            };
          });


        }
      } catch (e) {
        throw new Error(e);
      }
    },
    handleSelectAll(event, index) {
      try {
        event.stopPropagation();
        const isChecked = event.target.checked;
        this.formData.employeePermissions[index].epView = isChecked
        this.formData.employeePermissions[index].epUpdate = isChecked
        this.formData.employeePermissions[index].epCreate = isChecked
        this.formData.employeePermissions[index].epDelete = isChecked
      } catch (e) {
        throw new Error(e);
      }
    },
    activeStatus() {
      try {
        return this.formData.customerStatus ? 'activeTextToggle ml-2' : 'inActiveTextToggle ml-2';
      } catch (e) {
        throw new Error(e);
      }
    },
    getManu() {
      try {
        this.manu = sidebarMenuStore.getters.allSubMenu
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.isLoading = true;
        const permissionData = this.handlePermissionStatus(this.formData.employeePermissions)
        this.formSchema.validate(this.formData, {abortEarly: false}).then(async () => {
          const token = loginStore.getters.token
          if (this.formData.type === 0) {
            const payload = {
              roleCode: this.formData.roleCode,
              employeeCode: this.formData.employeeCode,
              employeeUsername: this.formData.employeeUsername,
              employeeFirstname: this.formData.employeeFirstname,
              employeeLastname: this.formData.employeeLastname,
              employeePhone: this.formData.employeePhone,
              employeeEmail: this.formData.employeeEmail,
              employeePassword: this.formData.employeePassword,
              employeeStatus: "1",
              employeePermissions: permissionData
            }

            await employeeStore.dispatch('createData', {token, payload})
          }
          if (this.formData.type === 1) {
            const payload = {
              seq: this.formData.seq,
              roleCode: this.formData.roleCode,
              employeeCode: this.formData.employeeCode,
              employeeUsername: this.formData.employeeUsername,
              employeeFirstname: this.formData.employeeFirstname,
              employeeLastname: this.formData.employeeLastname,
              employeePhone: this.formData.employeePhone,
              employeeEmail: this.formData.employeeEmail,
              employeePassword: this.formData.employeePassword,
              employeeStatus: this.formData.employeeStatus,
              employeePermissions: permissionData
            }
            await employeeStore.dispatch('updateData', {token, payload})
          }
          const result = employeeStore.getters.saveResult
          this.isLoading = false;
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
        }).catch((err) => {
          this.isLoading = false
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handlePermissionStatus(data) {
      try {

        const dataWithoutMenuName = data.map(item => {
          const {menuName, ...rest} = item;
          return rest;
        });
        for (let x = 0; x < dataWithoutMenuName.length; x++) {
          dataWithoutMenuName[x]['epView'] = dataWithoutMenuName[x]['epView'] ? "1" : "0"
          dataWithoutMenuName[x]['epCreate'] = dataWithoutMenuName[x]['epCreate'] ? "1" : "0"
          dataWithoutMenuName[x]['epUpdate'] = dataWithoutMenuName[x]['epUpdate'] ? "1" : "0"
          dataWithoutMenuName[x]['epDelete'] = dataWithoutMenuName[x]['epDelete'] ? "1" : "0"
        }
        return dataWithoutMenuName
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCheckedAll(data) {
      try {
        return data.epView && data.epCreate && data.epUpdate && data.epDelete;
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.validateResult = {
          roleCode: '',
          employeeCode: '',
          employeeUsername: '',
          employeeFirstname: '',
          employeeLastname: '',
          employeePhone: '',
          employeeEmail: '',
          employeePassword: '',
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.employeeModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction(result) {
      try {
        const messageError = employeeStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel() {
      this.resetFormData()
      $(this.$refs.employeeModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>

<style scoped></style>
