<template>
  <div class="modal fade" id="modal-default" ref="packingHistoryModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ item.typeStock === 'รับเข้า' ? 'รับเข้าสต๊อคสินค้า' : item.typeStock === 'ส่งออก' ?
            'ส่งออกสต๊อคสินค้า' : 'Reject สต๊อคสินค้า' }}</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="card card-primary">
              <div class="card-body">
                <div class="row">
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">หมายเลข Work Order</span><br>
                    <span>{{ item.woNum }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ชื่องาน</span><br>
                    <span>{{ item.woName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ขนาดงาน</span><br>
                    <span>{{ item.woMole }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                    <span>{{ item.customerName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-12">
                    <span class="inActiveText">หมายเหตุ</span><br>
                    <span>{{ item.woRemark }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">{{ item.typeStock === 'รับเข้า' ? 'จำนวนที่แพ็คกิ้งนับได้' :
                      'จำนวนดวงทั้งหมด' }}</span><br>
                    <span>{{ item.stockAmount +' '+ item.stockPackingType }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6" :hidden="item.typeStock != 'รับเข้า'">
                    <span class="inActiveText">Amount</span><br>
                    <span>{{ item.stockCountAmount }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 image-container">
                <img v-if="item.photoOne" :src="item.photoOne" alt="Image" class="set-image" />
                <img v-if="item.photoTwo" :src="item.photoTwo" alt="Image" class="set-image" />
                <img v-if="item.photoThree" :src="item.photoThree" alt="Image" class="set-image" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ปิด</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
        
<script>
import loginStore from "@/store/loginStore";

export default {
  name: "PackingHistoryModal",
  props: ['item'],
  data() {
    try {
      return {
        token: loginStore.getters.token,
        role: loginStore.getters.role.roleCode,
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.packingHistoryModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel() {
      try {
        $(this.$refs.packingHistoryModal).modal('hide');
        this.$emit('view-submitted')
      } catch (e) {
        throw new Error(e);
      }
      
    }
  }
}
</script>
        
<style scoped></style>
        