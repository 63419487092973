<template>
    <div class="modal fade" id="modal-default" ref="holidayViewModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">ข้อมูลวันหยุด</h4>
                    <button type="button" class="close" @click="handleCancel()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card card-primary">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <b>ทั่วไป</b>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">วันหยุด</span><br>
                                    <span>{{ data.holidayName }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-9">
                                    <span class="inActiveText">วันที่</span><br>
                                    <span>{{ data.holidayDate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "HolidayViewModal",
    props: ['item'],
    data() {
        return {
            data: [],
        }
    },
    watch: {
        item: {
            handler() {
                try {
                    if (this.item) {
                        this.data = this.item
                    }
                } catch (e) {
                    throw new Error(e);
                }
            },
            deep: true
        }
    },
    methods:{
        handleModal() {
            try {
                $(this.$refs.holidayViewModal).modal({
                    backdrop: 'static',
                    keyboard: false
                });
            } catch (e) {
                throw new Error(e);
            }
        },
        handleCancel() {
            $(this.$refs.holidayViewModal).modal('hide');
            this.$emit('view-submitted')
        }
    }
}
</script>

<style scoped>

</style>