<template>
  <div class="row mb-3">
    <div class="col-sm-12">
      <Header/>
    </div>
    <div class="col-sm-6">
      <h1 class="m-0">{{headerName}}</h1>
    </div>
  </div>
</template>
<script >
import Header from "@/components/header/Header.vue";
export default {
  name: "HeaderSection",
  components :{Header},
  props:['item'],
  data(){
    try {
      return {
        headerName:'',
      }
    }catch (e) {
      throw new Error(e)
    }
  },
  mounted() {
    this.handleHeaderName()
  },
  methods:{
    handleHeaderName(){
      this.headerName = this.item['headerName']
    }
  }
}
</script>

<style scoped>

</style>