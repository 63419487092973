<template>
  <section class="content">
    <div class="error-page">
      <h2 class="headline text-warning">404</h2>
      <div class="error-content">
        <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
        <p>ไม่พบ Page ที่กำลังค้นหา</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name:"error404",
}

</script>
<style scoped>

</style>