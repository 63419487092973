<template>
  <div :class="classCondition" v-if="sale && otherCondition">
    <div class="form-group">
      <label>เซลล์</label>
      <select v-model="selectResult" class="select form-control" @change="handleChangeSelect()">
        <option value="" disabled selected hidden>เซลล์</option>
        <option v-for="(item) in saleDropdown" :key="item['seq']" :value="item['seq']">{{
            item['employeeFirstname'] + ' ' + item['employeeLastname']
          }}
        </option>
      </select>
    </div>
  </div>
  <div :class="classCondition" v-if="customer && otherCondition">
    <div class="form-group">
      <label>ชื่อลูกค้า</label>
      <select v-model="selectResult" class="select form-control" @change="handleChangeSelect()">
        <option value="" disabled selected hidden>ชื่อลูกค้า</option>
        <option v-for="(item) in customerDropdown" :key="item['seq']" :value="item['seq']">{{
            item['customerName']
          }}
        </option>
      </select>
    </div>
  </div>
  <div :class="classCondition" v-if="machine && otherCondition">
    <div class="form-group">
      <label>ชื่อเครื่อง</label>
      <select v-model="selectResult" class="select form-control" @change="handleChangeSelect()">
        <option value="" disabled selected hidden>ชื่อเครื่อง</option>
        <option v-for="(item) in machineDropdown" :key="item['seq']" :value="item['seq']">{{
            item['machineDetail']
          }}
        </option>
      </select>
    </div>
  </div>
  <div :class="classCondition" v-if="findAll && otherCondition">
    <div class="form-group">
      <label>ค้นหา</label>
      <input type="text" v-model="selectResult" class="form-control"
             placeholder="ค้นหาหมายเลข Work Order / เซลล์ / ชื่องาน / ชื่อลูกค้า"  @keyup="handleChangeSelect()">
    </div>
  </div>
  <div :class="classCondition" v-if="dateStart && otherCondition">
    <div class="form-group">
      <label>วันที่เริ่มต้น</label>
      <input type="date" v-model="selectResult" class="form-control"
             placeholder="วว/ดด/ปปปป"  @change="handleChangeSelect()">
    </div>
  </div>
  <div :class="classCondition" v-if="dateFinish && otherCondition">
    <div class="form-group">
      <label>วันที่สิ้นสุด</label>
      <input type="date" v-model="selectResult" class="form-control"
             placeholder="วว/ดด/ปปปป"  @change="handleChangeSelect()">
    </div>
  </div>
</template>

<script>

import masterStore from "@/store/MasterStore";
import loginStore from "@/store/loginStore";

export default {
  name: "SearchField",
  components: {},
  props: ['item'],
  data() {
    try {
      return {
        token: loginStore.getters.token,
        currentEmpRole: loginStore.getters.role,
        sale: false,
        machine: false,
        customer: false,
        findAll:false,
        dateStart:false,
        dateFinish:false,
        saleDropdown: null,
        customerDropdown: null,
        machineDropdown: null,
        selectResult: '',
        otherCondition: true,
        classCondition:''
      }
    } catch (e) {
      throw new Error(e)
    }
  },
  mounted() {
    try {
      this.getSale()
      this.getCustomer()
      this.getMachine()
      this.handleSelect()
      this.handleCondition()
      this.handleClassCondition()
    } catch (e) {
      throw new Error(e);
    }
  },
  emits: [
    'handle-emit-field',
  ],
  methods: {
    handleSelect() {
      switch (this.item.selected) {
        case "sale":
          this.sale = true
          break;
        case "customer":
          this.customer = true
          break;
        case "machine":
          this.machine = true
          break;
        case "findAll":
          this.findAll = true
          break;
        case "dateStart":
          this.dateStart = true
          break;
        case "dateFinish":
          this.dateFinish = true
          break;
        default :
          this.sale = false
          this.customer = false
          this.machine = false
          this.findAll = false
          this.dateStart = false
          this.dateFinish = false
          break;
      }
    },
    handleCondition(){
      switch (this.item.other.condition){
        case 'role':
          if(this.item.other.expect.compareCondition === 'equal') {
            this.otherCondition = this.currentEmpRole === this.item.other.expect.data
          }
          if(this.item.other.expect.compareCondition === 'not equal') {
            this.otherCondition = this.currentEmpRole !== this.item.other.expect.data
          }
      }
    },
    handleClassCondition(){
      this.classCondition = this.item.classCondition
    },
    handleChangeSelect() {
      this.$emit("handle-emit-field",{emitData:this.item.emitData,result:this.selectResult} )
    },
    async getSale() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('saleData', payload)
        this.saleDropdown = masterStore.getters.saleDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    async getCustomer() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('customerData', payload)
        this.customerDropdown = masterStore.getters.customerDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    async getMachine() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('machineData', payload)
        this.machineDropdown = masterStore.getters.machineDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
  }
}
</script>

<style scoped>

</style>