<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <Loading v-if="isLoading" />
        <div class="row mb-3">
          <div class="col-sm-12">
            <Header />
          </div>
          <div class="col-sm-10">
            <h1 class="m-0">สต๊อคสินค้า</h1>
          </div>
          <div class="col-sm-2">
            <button :disabled="permission.epCreate === '0'" type="button" @click="handleRowClick({}, 0, $event)"
              class="btn btn-success float-right">
              สร้างสต๊อคสินค้า
            </button>
            <packingCreate-Modal @form-submitted="getData(currentPage)" ref="packingCreateModal" :item="rowOnClick" />
            <uploadPicture-Modal @form-submitted="getData(currentPage)" ref="uploadPictureModal" :item="rowOnClick" />
            <packingAdd-Modal @form-submitted="getData(currentPage)" ref="packingAddModal" :item="rowOnClick" />
            <packingOut-Modal @form-submitted="getData(currentPage)" ref="packingOutModal" :item="rowOnClick" />
            <packingReject-Modal @form-submitted="getData(currentPage)" ref="packingRejectModal" :item="rowOnClick" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>ลูกค้า</label>
                            <select class="select form-control" v-model="formData.woCustomerName">
                              <option value="">เลือกลูกค้า</option>
                              <option v-for="(item) in selectCustomer" :value="item.seq">
                                {{ item['customerName'] }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>ชื่องาน</label>
                            <input v-model="formData.woName" type="text" class="form-control" placeholder="ค้นหาชื่องาน">
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>ขนาด</label>
                            <select class="select form-control" v-model="formData.woMole">
                              <option value="">เลือกขนาด</option>
                              <option v-for="(item) in selectMole">
                                {{ item['sizeMole'] }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-sm-2" style="padding-top: 3%">
                      <button @click.prevent="getData(1, 10)" class="btn btn-primary btn-block" type="submit">ค้นหา
                      </button>
                      <button @click.prevent="toExport()" class="btn btn-outline-primary btn-block" type="submit">
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th style="width: 30%"></th>
                      <th class="on-pointer" style="width: 20%" @click.prevent="handleSortData('customerName')">ลูกค้า
                        <i
                          :class="`fa fa-arrow-${sortTable.column === 'customerName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                      </th>
                      <th class="on-pointer" style="width: 20%" @click.prevent="handleSortData('woName')">ชื่องาน
                        <i
                          :class="`fa fa-arrow-${sortTable.column === 'woName' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                      </th>
                      <th class="on-pointer" style="width: 20%" @click.prevent="handleSortData('woMole')">ขนาด
                        <i
                          :class="`fa fa-arrow-${sortTable.column === 'woMole' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                      </th>
                      <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('stockAmount')">จำนวน
                        <i
                          :class="`fa fa-arrow-${sortTable.column === 'stockAmount' ? this.sortTable.icon : 'up'} arrow-fix-size`" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="rowHovered tr-Adjust" v-for="(item) in tblData">
                      <td>
                        <button :disabled="permission.epCreate === '0'" @click="handleRowClick(item, 1, $event)"
                          class="btn planEachTaskBunton edit-border mr-1"><i class="fa fa-plus" /> รายละเอียด
                        </button>
                        <button :disabled="permission.epCreate === '0'" @click="handleRowClick(item, 2, $event)"
                          class="btn planEachTaskBunton mr-1">รับเข้า
                        </button>
                        <button :disabled="permission.epCreate === '0'" @click="handleRowClick(item, 3, $event)"
                          class="btn planButton mr-1">จ่ายออก
                        </button>
                        <button :disabled="permission.epCreate === '0'" @click="handleRowClick(item, 4, $event)"
                          class="btn rejectButton">Reject
                        </button>
                      </td>
                      <td>{{ item['customerName'] }}</td>
                      <td>{{ item['stockWname'] }}</td>
                      <td>{{ item['sizeMole'] }}</td>
                      <td>{{ item['stockTotal'] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1 ถึง {{
                  allShow <= 10 ? allShow : 10 }} ของ {{ allShow }} ทั้งหมด </div>
                </div>
                <div class="col-sm-12 col-md-7 ">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination">
                      <li class="paginate_button page-item previous">
                        <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                          ก่อนหน้า
                        </button>
                      </li>
                      <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                        v-for="page in pages" :key="page">
                        <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                      </li>
                      <li class="paginate_button page-item previous">
                        <button @click="nextPage()" :disabled="currentPage >= allPage[8]" class="page-link">ถัดไป
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Content>
</template>


<script>
import Content from "@/components/Content.vue";
import packingStore from "@/store/packingStore";
import loginStore from "@/store/loginStore";
import UploadPictureModal from "@/components/packing/UploadPictureModal.vue";
import PackingCreateModal from "@/components/packing/PackingCreateModal.vue";
import PackingAddModal from "@/components/packing/PackingAddModal.vue";
import PackingOutModal from "@/components/packing/PackingOutModal.vue";
import PackingRejectModal from "@/components/packing/PackingRejectModal.vue";
import masterStore from "@/store/MasterStore";
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";
import sidebarMenuStore from "@/store/sidebarMenuStore";

export default {
  name: "Packing",
  components: { Header, Loading, UploadPictureModal, PackingCreateModal, PackingAddModal, PackingOutModal, PackingRejectModal, Content },
  data() {
    try {
      return {
        sortTable: { column: null, sort: 'asc', icon: 'up' },
        isLoading: false,
        tblData: null,
        selectCustomer: null,
        selectMole: null,
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        showModal: false,
        permission: loginStore.getters.pagePermission,
        rowOnClick: {},
        selectMaterial: null,
        token: null,
        menuData: '',
        formData: {
          woCustomerName: '',
          woName: '',
          woMole: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  async mounted() {
    try {
      this.token = loginStore.getters.token
      await this.getData(1, 10)
      this.getCustomer()
      this.getMole()
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({ length: totalPages }, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    currentPath() {
      try {
        const currentPath = this.$route['path']
        const allSub = sidebarMenuStore.getters.allSubMenu
        const menuIs = allSub.filter(item => item['menuPath'] === currentPath)
        if (menuIs.length > 0) {
          const getMenu = menuIs[0]['menuHead']
          const mainMenu = sidebarMenuStore.getters.mainMenu
          const getHeadMenu = mainMenu.filter(item => item['seq'] === getMenu)
          this.menuData = getHeadMenu[0]['seq']
        }
        return this.menuData;
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  methods: {
    handleSortData(column) {
      this.sortTable.column = column
      this.sortTable.sort = this.sortTable.sort === 'desc' ? 'asc' : "desc"
      this.sortTable.icon = this.sortTable.sort === 'desc' ? 'down' : "up"
      const data = this.tblData
      const actionData = []
      for (let item of data) {
        if (item[column] === null) {
          item[column] = ''
        }
        actionData.push(item)
      }
      this.tblData = actionData.sort((a, b) => {
        if (this.sortTable.sort === "desc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      })
    },
    async handleRowClick(data, type, event) {
      try {
        event.stopPropagation();
        await this.getData(this.currentPage)
        data['type'] = type
        if (this.permission.epCreate === '1' && type === 0) {
          this.$refs.packingCreateModal.handleModal();
        } else {
          const dataByID = await this.getDataById(data.seq)
          const dataModal = dataByID[0]
          if (dataByID.length > 0) {
            dataModal.type = type
            this.rowOnClick = dataModal
            if (this.permission.epCreate === '1' && type === 1) {
              this.$refs.uploadPictureModal.handleModal();
            } else if (this.permission.epCreate === '1' && type === 2) {
              this.$refs.packingAddModal.handleModal();
            } else if (this.permission.epCreate === '1' && type === 3) {
              this.$refs.packingOutModal.handleModal();
            } else if (this.permission.epCreate === '1' && type === 4) {
              this.$refs.packingRejectModal.handleModal();
            }
          } else {
            await this.getData(this.currentPage)
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData(page) {
      try {
        this.isLoading = true
        this.menuData = this.currentPath
        const payload = {
          token: this.token,
          page: page,
          menuSeq: this.menuData,
          customerSeq: this.formData.woCustomerName,
          stockWName: this.formData.woName,
          sizeMole: this.formData.woMole
        }
        await packingStore.dispatch('getDataTbl', payload)
        this.tblData = packingStore.getters.tblData['stockLst']
        this.pageSize = packingStore.getters.tblData['limit']
        this.allShow = packingStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getDataById(seq) {
      try {
        this.isLoading = true
        const payload = {
          sequence: seq,
          token: this.token,
        }
        await packingStore.dispatch('getDataTbl', payload)
        this.isLoading = false
        return packingStore.getters.tblData['stockLst']
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'ลบข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage)
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    async getCustomer() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('packingSearch', payload)
        this.selectCustomer = masterStore.getters.packingCustomerSearch
      } catch (e) {
        throw new Error(e);
      }
    },
    async getMole() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('packingSearch', payload)
        this.selectMole = masterStore.getters.packingMoleSearch
      } catch (e) {
        throw new Error(e);
      }
    },
  }
}
</script>

<style scoped>
.edit-border {
  border-color: #D8EBFF;
}
</style>