import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";
import {string} from "yup";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
export default createStore({
    state: {
        tblData: Object,
        saveResult: Boolean,
        errorMessage:string,
    },
    getters: {
        tblData: state => state.tblData,
        saveResult: state => state.saveResult,
        errorMessage:state => state.errorMessage
    },
    mutations: {
        setDataTbl(state, payload) {
            state.tblData = payload
        },
        setSave(state, result) {
            state.saveResult = result
        },
        setError(state,error){
            state.errorMessage = error
        }
    },
    actions: {
        async getDataTbl({commit}, {token,sequence,sale,customer,type,search,orderStartDate,orderFinnishDate, orderName, woNumber, page, status, limit}) {
            try {
                if (!limit) {
                    limit = 10
                }
                let customURL = `planning/planningSelect?page=${page}&limit=${limit}&woDate=${orderStartDate}&woDeliver=${orderFinnishDate}&status=1&customerSeq=${customer}&employeeSeq=${sale}&woWCode=${orderName}&woNumber=${woNumber}`
                if(sequence !== undefined){
                    customURL = `planning/planningSelect?seq=${sequence}&limit=10&status=${status}`
                }
                const result = await axiosInstance.get(customURL, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            } catch (error: any) {
                commit('setSave', false);
            }
        },
        async selectData({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`planning/selectJob`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                }
            } catch (error) {
                commit('setSave', false);
            }
        },
    },
    modules: {}
})
