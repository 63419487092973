<template>
  <div class="modal fade" id="modal-default" ref="roleStoreModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">จ่ายออก</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="card card-primary">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <b>ทั่วไป</b>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">หมายเลข Work Order</span><br>
                    <span>{{ item.woNumber }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่องาน</span><br>
                    <span>{{ item.woWcode }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                    <span>{{ item.woCustomerName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">ประเภทงาน</span><br>
                    <span>{{ item.wotName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">กำหนดส่งงาน</span><br>
                    <span>{{ item.woDeliverDate }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">พนักงานขาย</span><br>
                    <span>{{ item.woEmployeeName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">หมายเหตุ</span><br>
                    <span>{{ item.woNote === null ? '-' : item.woNote }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">จำนวนกระดาษที่จ่าย</span><br>
                    <span>{{ item.planning ? item.woPaperAmount : '-' }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">ขนาดงาน</span><br>
                    <span>{{ item.planning ? item.woMole : '-' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>Suplier</label>
                  <input type="text" v-model="formData.suplier" class="form-control" placeholder="Suplier">
                  <span class="text-danger">{{ validateResult.suplier }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>รหัสสินค้า</label>
                  <input type="text" v-model="formData.mtCode" class="form-control" placeholder="รหัสสินค้า">
                  <span class="text-danger">{{ validateResult.mtCode }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>หน้ากว้าง</label>
                  <input type="text" v-model="formData.width" class="form-control" placeholder="หน้ากว้าง">
                  <span class="text-danger">{{ validateResult.width }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>ความยาวม้วน</label>
                  <input type="text" v-model="formData.length" class="form-control" placeholder="ความยาวม้วน">
                  <span class="text-danger">{{ validateResult.length }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>จำนวนม้วน</label>
                  <input type="text" v-model="formData.rollAmount" class="form-control" placeholder="จำนวนม้วน">
                  <span class="text-danger">{{ validateResult.rollAmount }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>Amount</label>
                  <input type="text" v-model="formData.amount" class="form-control" placeholder="Amount" disabled>
                  <span class="text-danger">{{ validateResult.amount }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>ความยาวม้วน(เศษ)</label>
                  <input type="text" v-model="formData.lengthScrap" class="form-control" placeholder="เศษ">
                  <span class="text-danger">{{ validateResult.lengthScrap }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>จำนวนม้วน(เศษ)</label>
                  <input type="text" v-model="formData.amountScrap" class="form-control" placeholder="เศษ">
                  <span class="text-danger">{{ validateResult.amountScrap }}</span>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary centered-button">เพิ่มกระดาษ</button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" data-dismiss="modal">ยกเลิก</button>
            <button type="submit" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
      
<script>
import prepressStore from "@/store/prepressStore";
import loginStore from "@/store/loginStore";
import moment from 'moment'
import masterStore from "@/store/MasterStore";

export default {
  name: "RoleStoreModal",
  props: ['item'],
  data() {
    try {
      return {
        token: loginStore.getters.token,
        role: loginStore.getters.role.roleCode,
        selectColor: [],
        formData: {
          suplier: '',
          mtCode: '',
          width: '',
          length: '',
          rollAmount: '',
          amount: '',
          lengthScrap: '',
          amountScrap: ''
        },
        validateResult: {
          suplier: '',
          mtCode: '',
          width: '',
          length: '',
          rollAmount: '',
          amount: '',
          lengthScrap: '',
          amountScrap: ''
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    try {
      this.getSelectColor()
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.digitalProve = this.item['woppDp'];
            this.formData.standardProve = this.item['woppSp'];
            this.formData.exJob = this.item['woppEx'];
            this.formData.plateColor = this.item['woppPc'];
            this.formData.ink = this.item['woppInk'];
            this.formData.color = this.item['colorCode'];
            this.formData.plateVax = this.item['woppPv'];
            this.formData.blade = this.item['woppBlade'];
            this.formData.bladeDetail = this.item['woppBladeDetail'];
            this.formData.other = this.item['woppOther'];
            this.formData.otherDetail = this.item['woppOtherDetail'];
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    },
    'formData.ink': {
      handler(newVal) {
        if (!newVal) {
          this.formData.color = null;
        }
      }
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.roleStoreModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        const token = this.token
        const payload = {
          plCode: this.item.planning.plCode,
          woNumber: this.item.woNumber,
          woppDp: this.formData.digitalProve === true ? '1' : '0',
          woppSp: this.formData.standardProve === true ? '1' : '0',
          woppEx: this.formData.exJob === true ? '1' : '0',
          woppPc: this.formData.plateColor === true ? '1' : '0',
          woppInk: this.formData.ink === true ? '1' : '0',
          colorCode: this.formData.color,
          woppPv: this.formData.plateVax === true ? '1' : '0',
          woppBlade: this.formData.blade === true ? '1' : '0',
          woppBladeDetail: this.formData.bladeDetail,
          woppOther: this.formData.other === true ? '1' : '0',
          woppOtherDetail: this.formData.otherDetail,
          status: this.item.status,
          woSeq: this.item.seq
        }
        await prepressStore.dispatch('createData', { token, payload })
        const result = prepressStore.getters.saveResult
        if (result) {
          this.successFunction()
        } else {
          this.errorFunction()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.formData = {
          digitalProve: '0',
          standardProve: '0',
          exJob: '0',
          plateColor: '',
          ink: '0',
          color: null,
          plateVax: '0',
          blade: '0',
          bladeDetail: '',
          other: '0',
          otherDetail: '',
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.roleStoreModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = prepressStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    convertDateFormat(item) {
      try {
        if (item) {
          return moment(item, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        return null;
      } catch (e) {
        throw new Error(e);
      }
    },
    async getSelectColor() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('colorDropdown', payload)
        this.selectColor = masterStore.getters.colorDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
  },
}
</script>
      
<style scoped></style>

      