<template>
  <div class="modal fade" id="modal-default" ref="holidayModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ formData.type === 0 ? 'เพิ่มวันหยุดประจำปี' : 'แก้ไขวันหยุดประจำปี' }}</h4>
          <button type="button" class="close" @click="handleCancel()"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-7">
                <div class="form-group">
                  <label>ชื่อวันหยุด</label>
                  <input type="text" v-model="formData.holidayName" class="form-control" placeholder="ชื่อวันหยุด">
                  <span class="text-danger">{{ validateResult.holidayName }}</span>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <label>วันที่</label>
                  <input type="date" v-model="formData.holidayDate" class="form-control" placeholder="dd/mm/yyyy">
                  <span class="text-danger">{{ validateResult.holidayDate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script>
import holidayStore from "@/store/holidayStore";
import loginStore from "@/store/loginStore";
import moment from 'moment';
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "HolidayModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        validateResult: {
          holidayName: '',
          holidayDate: '',
        },
        formData: {
          type: '',
          holidayName: '',
          holidayDate: '',
          status: '1',
          seq: null
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        holidayName: Yup.string().required('ชื่อวันหยุดห้ามเป็นค่าว่าง'),
        holidayDate: Yup.string().required('วันที่ห้ามเป็นค่าว่าง'),
      });
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            const dateConvert = this.convertDateFormat(this.item['holidayDate']);
            this.formData.type = this.item['type'];
            this.formData.seq = this.item['seq'];
            this.formData.holidayName = this.item['holidayName'];
            this.formData.holidayDate = dateConvert;
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.holidayModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.formSchema.validate(this.formData, { abortEarly: false }).then(async () => {
          this.isLoading = true;
          const token = this.token
          if (this.formData.type === 0) {
            const payload = {
              holidayName: this.formData.holidayName,
              holidayDate: this.formData.holidayDate,
              status: this.formData.status
            }
            await holidayStore.dispatch('createData', { token, payload })
          }
          if (this.formData.type === 1) {
            const payload = {
              seq: this.formData.seq,
              holidayName: this.formData.holidayName,
              holidayDate: this.formData.holidayDate,
              status: this.formData.status
            }
            await holidayStore.dispatch('updateData', { token, payload })
          }
          const result = holidayStore.getters.saveResult
          this.isLoading = false;
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
        }
        ).catch((err) => {
          this.isLoading = false
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.validateResult = {
          holidayName: '',
          holidayDate: '',
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.holidayModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = holidayStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    convertDateFormat(item) {
      try {
        if (item) {
          return moment(item, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        return null;
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel(){
      $(this.$refs.holidayModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>
  
<style scoped></style>
  