<template>
    <div class="modal fade" id="modal-default" ref="storeMaterialRejectModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">จ่ายสินค้า(จ่ายออก)</h4>
                    <button type="button" class="close" @click="handleCancel()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <Loading v-if="isLoading"/>
                <form @submit.prevent="submitForm">
                    <div class="modal-body">
                        <div class="card card-primary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label>เลือก Work Order</label>
                                            <select class="select form-control" v-model="formData.wo">
                                                <option value="">Select Work Order</option>
                                                <option v-for="(item) in selectWorkOrder" :value="item.seq">{{
                                                    item['woNumber'] }}</option>
                                            </select>
                                            <span class="text-danger">{{ validateResult.wo }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-primary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <b>Work Order : {{ getOptionData(formData.wo).woNumber }}</b>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ชื่องาน</span><br>
                                        <span>{{ getOptionData(formData.wo).woWcode }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ชื่อลูกค้า</span><br>
                                        <span>{{ getOptionData(formData.wo).customerName }}</span>
                                    </div>
                                    <div style="padding-left: 3%" class="col-sm-4">
                                        <span class="inActiveText">ขนาดงาน</span><br>
                                        <span>{{ getOptionData(formData.wo).woMole }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Supplier</label>
                                    <select class="select form-control" v-model="formData.supplier"
                                        @change="selectsupplier">
                                        <option value="">Select Supplier</option>
                                        <option v-for="(item) in selectSupplier">{{ item }}</option>
                                    </select>
                                    <span class="text-danger">{{ validateResult.supplier }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>รหัสสินค้า</label>
                                    <select class="select form-control" v-model="formData.mtcode"
                                        :disabled="!formData.supplier" @change="selectmaterial">
                                        <option value="">รหัสสินค้า</option>
                                        <option v-for="(item) in selectMtcode" :value="item.seq">{{ item['materialName'] }}
                                        </option>
                                    </select>
                                    <span class="text-danger">{{ validateResult.mtcode }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>หน้ากว้าง</label>
                                    <select class="select form-control" v-model="formData.width"
                                        :disabled="!formData.mtcode">
                                        <option value="">หน้ากว้าง</option>
                                        <option v-for="(item) in selectWidth">{{ item['width'] }}</option>
                                    </select>
                                    <span class="text-danger">{{ validateResult.width }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>ความยาวม้วน</label>
                                    <input
                                        type="text"
                                        v-model="formData.length"
                                        class="form-control"
                                        placeholder="ความยาวม้วน"
                                        @input="handleInput($event,'length')"
                                    >
                                    <span class="text-danger">{{ validateResult.length }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>จำนวนม้วน</label>
                                    <input
                                        type="text"
                                        v-model="formData.amount"
                                        class="form-control"
                                        placeholder="จำนวนม้วน"
                                        @input="handleInput($event,'amount')"
                                    >
                                    <span class="text-danger">{{ validateResult.amount }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <input disabled type="text" v-model="formData.totalAmount" class="form-control">
                                    <span class="text-danger">{{ validateResult.totalAmount }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>ความยาวม้วน(เศษ)</label>
                                    <input
                                        type="text"
                                        v-model="formData.lengthScrap"
                                        class="form-control"
                                        placeholder="ความยาวม้วน(เศษ)"
                                        @input="handleInput($event,'lengthScrap')"
                                    >
                                    <span class="text-danger">{{ validateResult.lengthScrap }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>จำนวนม้วน(เศษ)</label>
                                    <input
                                        type="text"
                                        v-model="formData.amountScrap"
                                        class="form-control"
                                        placeholder="จำนวนม้วน(เศษ)"
                                        @input="handleInput($event,'amountScrap')"
                                    >
                                    <span class="text-danger">{{ validateResult.amountScrap }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
                        <button type="submit" class="btn btn-danger float-right">จ่ายออก</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
    
<script>
import loginStore from "@/store/loginStore";
import * as Yup from "yup";
import masterStore from "@/store/MasterStore";
import storeMaterialStore from "@/store/storeMaterialStore";
import Loading from "@/components/loading/Loading.vue";

export default {
    name: "StoreMaterialRejectModal",
    components: {Loading},
    props: ['item'],
    data() {
        try {
            return {
                isLoading: false,
                token: loginStore.getters.token,
                selectHolidayYear: null,
                selectWorkOrder: null,
                selectSupplier: null,
                selectMtcode: null,
                selectWidth: null,
                validateResult: {
                    wo: '',
                    supplier: '',
                    mtcode: '',
                    width: '',
                    length: null,
                    amount: null,
                    totalAmount: null,
                    lengthScrap: null,
                    amountScrap: null,
                },
                formData: {
                    type: '',
                    wo: '',
                    supplier: '',
                    mtcode: '',
                    width: '',
                    length: null,
                    amount: null,
                    totalAmount: null,
                    lengthScrap: null,
                    amountScrap: null,
                    seq: null,
                }
            }
        } catch (e) {
            throw new Error(e);
        }
    },
    computed: {
        formSchema() {
            return Yup.object().shape({
                wo: Yup.string().required('กรุณาเลือก Work Order'),
                supplier: Yup.string().required('กรุณาเลือก Supplier'),
                mtcode: Yup.string().required('กรุณาเลือกรหัสสินค้า'),
                width: Yup.string().required('กรุณาเลือกหน้ากว้าง'),
                //length: Yup.string().required('ความยาวม้วนห้ามเป็นค่าว่าง'),
                //amount: Yup.string().required('จำนวนม้วนห้ามเป็นค่าว่าง'),
            });
        },
        calculateAmount() {
            let response = 0
            let lengthScrapTemp = 0
            let amountScrapTemp = 0
            if (this.formData.lengthScrap != null && "" != this.formData.lengthScrap) {
                lengthScrapTemp = parseFloat(this.formData.lengthScrap)
            }
            if (this.formData.amountScrap != null && "" != this.formData.amountScrap) {
                amountScrapTemp = parseFloat(this.formData.amountScrap)
            }
            response = (this.formData.length * this.formData.amount) + (lengthScrapTemp * amountScrapTemp);
            return response.toFixed(2);
        }
    },
    watch: {
        item: {
            handler() {
                try {
                    this.resetFormData()
                    if (this.item) {
                        this.formData.type = this.item['type'];
                        this.formData.seq = this.item['seq'];
                        this.formData.wo = this.item['wo'];
                        this.formData.supplier = this.item['supplier'];
                        this.formData.mtcode = this.item['mtcode'];
                        this.formData.width = this.item['width'];
                        this.formData.length === null ? 0 : this.item['length'];
                        this.formData.amount === null ? 0 : this.item['amount'];
                        this.formData.totalAmount === null ? 0 : this.item['totalAmount'];
                        this.formData.lengthScrap === null ? 0 : this.item['lengthScrap'];
                        this.formData.amountScrap === null ? 0 : this.item['amountScrap'];
                    }
                } catch (e) {
                    throw new Error(e);
                }
            },
            deep: true
        },
        calculateAmount(newVal) {
            this.formData.totalAmount = newVal;
        },
    },
    mounted() {
        try {
            this.token = loginStore.getters.token
            this.getSelectWorkOrder()
            this.getSelectSupplier()
            this.getSelectMtCode()
            this.getSelectWidth()
        } catch (e) {
            throw new Error(e);
        }
    },
    methods: {
        handleInput(event,formData) {
            const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
            const match = sanitizedValue.match(/(\d*\.\d{0,2}|\d*)/);
            this.formData[formData] = match ? match[0] : '';
        },
        handleModal() {
            try {
                $(this.$refs.storeMaterialRejectModal).modal({
                    backdrop: 'static',
                    keyboard: false
                });
            } catch (e) {
                throw new Error(e);
            }
        },
        selectsupplier() {
            this.getSelectMtCode(this.formData.supplier)
        },
        selectmaterial() {
            this.getSelectWidth(this.formData.mtcode)
        },
        async submitForm() {
            try {
                this.formSchema.validate(this.formData, { abortEarly: false }).then(async () => {
                    this.isLoading = true;
                    const token = this.token
                    const payload = {
                        woSeq: this.formData.wo,
                        soSupplier: this.formData.supplier,
                        soMaterialSeq: this.formData.mtcode,
                        soWidth: Number(this.formData.width),
                        soRoleLength: parseFloat(this.formData.length),
                        soRole: parseFloat(this.formData.amount),
                        soAmount: this.formData.totalAmount,
                        soRoleLengthSpacial: parseFloat(this.formData.lengthScrap === undefined || this.formData.lengthScrap === null || this.formData.lengthScrap === "") ? 0 : parseFloat(this.formData.lengthScrap),
                        soRoleSpacial: parseFloat(this.formData.amountScrap === undefined || this.formData.amountScrap === null || this.formData.amountScrap === "") ? 0 : parseFloat(this.formData.amountScrap),
                        status: '1'
                    }
                    await storeMaterialStore.dispatch('storeOut', { token, payload })
                    const result = storeMaterialStore.getters.saveResult
                    this.isLoading = false;
                    if (result) {
                        this.successFunction()
                    } else {
                        this.errorFunction()
                    }
                }
                ).catch((err) => {
                    this.isLoading = false;
                    this.validateResult = {};
                    err.inner.forEach((error) => {
                        this.alertFunction()
                        this.validateResult[error.path] = error.message;
                    })
                })
            } catch (e) {
                throw new Error(e);
            }
        },
        getOptionData(seq) {
            try {
                if (seq) {
                    return this.selectWorkOrder.find(item => item.seq === seq);
                }
                return { woNumber: '', woWcode: '', customerName: '', woMole: '' }
            } catch (e) {
                throw new Error(e);
            }
        },
        async getSelectWorkOrder() {
            try {
                const payload = {
                    token: this.token
                }
                await masterStore.dispatch('storeOut', payload)
                this.selectWorkOrder = masterStore.getters.workOrderList
            } catch (e) {
                throw new Error(e);
            }
        },
        async getSelectSupplier() {
            try {
                const payload = {
                    token: this.token
                }
                await masterStore.dispatch('storeOut', payload)
                this.selectSupplier = masterStore.getters.supplierFormOut
            } catch (e) {
                throw new Error(e);
            }
        },
        async getSelectMtCode(sup) {
            try {
                const payload = {
                    token: this.token
                }
                await masterStore.dispatch('storeOut', payload)
                this.selectMtcode = []
                const results = masterStore.getters.materialCodeFormOut
                results.forEach((item, index) => {
                    if (item.supplier === sup) {
                        item.materialLst.forEach((item, index) => {
                            this.selectMtcode.push({ seq: item.seq, materialName: item.materialName })
                        })
                    }
                })

            } catch (e) {
                throw new Error(e);
            }
        },
        async getSelectWidth(mtseq) {
            try {
                const payload = {
                    token: this.token
                }
                await masterStore.dispatch('storeOut', payload)
                this.selectWidth = []
                const results = masterStore.getters.mtWidth
                results.forEach((item, index) => {
                    if (item.materialSeq === mtseq) {
                        item.widthLst.forEach((itemWidth, index) => {
                            this.selectWidth.push({ width: itemWidth })
                        })
                    }
                })
            } catch (e) {
                throw new Error(e);
            }
        },
        resetFormData() {
            try {
                this.validateResult = {
                    wo: '',
                    supplier: '',
                    mtcode: '',
                    width: '',
                    length: null,
                    amount: null,
                },
                    this.formData = {
                        wo: '',
                        supplier: '',
                        mtcode: '',
                        width: '',
                        length: null,
                        amount: null,
                        totalAmount: null,
                        lengthScrap: null,
                        amountScrap: null,
                    }
            } catch (e) {
                throw new Error(e);
            }
        },
        alertFunction() {
            try {
                this.$swal.fire({
                    title: 'กรุณากรอกข้อมูลให้ครบ',
                    text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
                    icon: 'error',
                    confirmButtonText: 'ยืนยัน'
                })
            } catch (e) {
                throw new Error(e);
            }
        },
        successFunction() {
            try {
                this.$swal.fire({
                    title: 'บันทึกข้อมูลสำเร็จ',
                    text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
                    icon: 'success',
                    confirmButtonText: 'ยืนยัน'
                }).then(() => {
                    $(this.$refs.storeMaterialRejectModal).modal('hide');
                    this.$emit('form-submitted')
                })
            } catch (e) {
                throw new Error(e);
            }
        },
        errorFunction() {
            try {
                const messageError = storeMaterialStore.getters.handleMessage
                this.$swal.fire({
                    title: 'บันทึกข้อมูลล้มเหลว',
                    text: messageError,
                    icon: 'error',
                    confirmButtonText: 'ยืนยัน'
                }).then(() => {
                    this.$emit('form-submitted')
                })
            } catch (e) {
                throw new Error(e);
            }
        },
        handleCancel(){
            $(this.$refs.storeMaterialRejectModal).modal('hide');
            this.$emit('form-submitted')
        }
    },
}
</script>
    
<style scoped></style>
    