<template>
    <div class="modal fade" id="modal-default" ref="machineMaViewModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">ข้อมูลการบำรุงเครื่องจักร</h4>
                    <button type="button" class="close" @click="handleCancel()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card card-primary">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <b>ทั่วไป</b>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-12">
                                    <span class="inActiveText">รหัสเครื่องจักร</span><br>
                                    <span>{{ data.machineCode }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">วันที่เริ่มต้น</span><br>
                                    <span>{{ startDate }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">เวลาเริ่มต้น</span><br>
                                    <span>{{ startTime }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">วันที่สิ้นสุด</span><br>
                                    <span>{{ endDate }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-3">
                                    <span class="inActiveText">เวลาสิ้นสุด</span><br>
                                    <span>{{ endTime }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-12">
                                    <span class="inActiveText">รายละเอียด</span><br>
                                    <span>{{ data.machineMaDetail }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "MachineMaViewModal",
    props: ['item'],
    data() {
        return {
            data: [],
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
        }
    },
    watch: {
        item: {
            handler() {
                try {
                    if (this.item) {
                        this.data = this.item;
                        this.startDate = this.splitDate(this.item['machineMaStart']);
                        this.startTime = this.splitTime(this.item['machineMaStart']);
                        this.endDate = this.splitDate(this.item['machineMaEnd']);
                        this.endTime = this.splitTime(this.item['machineMaEnd']);
                    }
                } catch (e) {
                    throw new Error(e);
                }
            },
            deep: true
        }
    },
    methods:{
        handleModal() {
            try {
                $(this.$refs.machineMaViewModal).modal({
                    backdrop: 'static',
                    keyboard: false
                });
            } catch (e) {
                throw new Error(e);
            }
        },
        splitDate(data) {
            try{
                if (data) {
                const startValueArr = data.split(' ');
                const dayStartVal = startValueArr[0].split('/');
                const dateStartValue = dayStartVal[2] + '-' + dayStartVal[1] + '-' + dayStartVal[0];
                return dateStartValue;
                } else {
                const dateStartValue = null;
                return dateStartValue;
                }
            }catch (e) {
                throw new Error(e);
            } 
        },
        splitTime(data) {
            try{
                if (data) {
                const startValueArr = data.split(' ');
                const timeStartValue = startValueArr[1];
                return timeStartValue;
                } else {
                const timeStartValue = null;
                return timeStartValue;
                }
            }catch (e) {
                throw new Error(e);
            } 
        },
        handleCancel() {
            $(this.$refs.machineMaViewModal).modal('hide');
            this.$emit('view-submitted')
        }
    }
}
</script>

<style scoped>

</style>