<template>
  <content>
    <div class="content-header">
      <div class="container-fluid">
        <FullCalender page="ปฎิทินแผนการผลิต" item="resourceTimelineMonth" option="r"/>
      </div>
    </div>
  </content>
</template>

<script>
import Content from "@/components/Content.vue";

import FullCalender from "@/components/resourceCalendar/FullCalender.vue";

export default {
  name: "CalendarProduction",
  components: {FullCalender, Content},
}
</script>

<style scoped>

</style>