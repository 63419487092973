<template>
  <div class="modal fade" id="modal-default" ref="machineModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ formData.type === 0 ? 'เพิ่มเครื่องจักร' : 'แก้ไขเครื่องจักร' }}</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>รหัสเครื่องจักร</label>
                  <input type="text" v-model="formData.machineCode" class="form-control" placeholder="รหัสเครื่องจักร">
                  <span class="text-danger">{{ validateResult.machineCode }}</span>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group">
                  <label>รายละเอียด</label>
                  <input type="text" v-model="formData.machineDetail" class="form-control" placeholder="รายละเอียด">
                  <span class="text-danger">{{ validateResult.machineDetail }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>สถานะเครื่องจักร</label>
                  <select class="select form-control" v-model="formData.msCode">
                    <option value="" disabled selected hidden>เลือกสถานะ</option>
                    <option v-for="(item) in selectMachineStatus" :value="item.machineStatusCode">
                      {{ item['machineStatusName'] }}</option>
                  </select>
                  <span class="text-danger">{{ validateResult.msCode }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ประเภทเครื่องจักร</label>
                  <select class="select form-control" v-model="formData.mtSeq">
                    <option value="" disabled selected hidden>เลือกเครื่องจักร</option>
                    <option v-for="(item) in selectMachineType" :value="item.seq">
                      {{ item['machineTypeName'] }}</option>
                  </select>
                  <span class="text-danger">{{ validateResult.mtSeq }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12" style="display: inline-flex;">
                <label class="mr-2">สถานะ : </label>
                <input type="checkbox" v-model="formData.machineStatus" id="toggle-switch"
                  class="toggle-switch visually-hidden">
                <label for="toggle-switch" class="toggle-switch-label">
                  <span class="toggle-switch-inner"></span>
                  <span class="toggle-switch-switch"></span>
                </label>
                <span :class="activeStatus()">{{ formData.machineStatus ? "เปิดใช้งาน" : "ปิดใช้งาน" }}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
    
<script>
import machineStore from "@/store/machineStore";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "MachineModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        selectMachineStatus: [],
        selectMachineType: [],
        validateResult: {
          machineCode: '',
          machineDetail: '',
          msCode: '',
          mtSeq: ''
        },
        formData: {
          type: '',
          machineCode: '',
          machineDetail: '',
          machineStatus: '',
          msCode: '',
          mtSeq: '',
          seq: null
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        machineCode: Yup.string().required('รหัสเครื่องจักรห้ามเป็นค่าว่าง'),
        machineDetail: Yup.string().required('รายละเอียดเครื่องจักรห้ามเป็นค่าว่าง'),
        msCode: Yup.mixed().oneOf(this.selectMachineStatus.map(c => c.machineStatusCode)).required('สถานะเครื่องจักรห้ามเป็นค่าว่าง'),
        mtSeq: Yup.mixed().oneOf(this.selectMachineType.map(c => c.seq)).required('เครื่องจักรห้ามเป็นค่าว่าง'),
      });
    }
  },
  mounted() {
    try {
      this.getMachineStatus()
      this.getMachineType()
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.type = this.item['type'];
            this.formData.seq = this.item['seq'];
            this.formData.machineCode = this.item['machineCode'];
            this.formData.machineDetail = this.item['machineDetail'];
            this.formData.machineStatus = this.item['type'] === 0 ? true : this.item['machineStatus'] === "1";
            this.formData.msCode = this.item['msCode'];
            this.formData.mtSeq = this.item['mtSeq'];
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.machineModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    activeStatus() {
      try {
        return this.formData.machineStatus ? 'activeTextToggle ml-2' : 'inActiveTextToggle ml-2';
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.formSchema.validate(this.formData, { abortEarly: false }).then(async () => {
          this.isLoading = true;
          const token = this.token
          if (this.formData.type === 0) {
            const payload = {
              machineCode: this.formData.machineCode,
              machineDetail: this.formData.machineDetail,
              machineStatus: this.formData.machineStatus ? "1" : "0",
              msCode: this.formData.msCode,
              mtSeq: this.formData.mtSeq
            }
            await machineStore.dispatch('createData', { token, payload })
          }
          if (this.formData.type === 1) {
            const payload = {
              seq: this.formData.seq,
              machineCode: this.formData.machineCode,
              machineDetail: this.formData.machineDetail,
              machineStatus: this.formData.machineStatus ? "1" : "0",
              msCode: this.formData.msCode,
              mtSeq: this.formData.mtSeq

            }
            await machineStore.dispatch('updateData', { token, payload })
          }
          const result = machineStore.getters.saveResult
          this.isLoading = false;
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
        }
        ).catch((err) => {
          this.isLoading = false
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })

      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.validateResult = {
          machineCode: '',
          machineDetail: '',
          msCode: '',
          mtSeq: ''
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try{
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่าง',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      }catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.machineModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError =  machineStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    async getMachineStatus() {
      try{
        const payload = {
        token: this.token
      }
      await masterStore.dispatch('machineForm', payload)
      this.selectMachineStatus = masterStore.getters.machineForm
      }catch (e) {
        throw new Error(e);
      }
    },
    async getMachineType() {
      try{
        const payload = {
        token: this.token
      }
      await masterStore.dispatch('machineTypeForm', payload)
      this.selectMachineType = masterStore.getters.machineTypeForm
      }catch (e) {
        throw new Error(e);
      }
    },
    handleCancel(){
      $(this.$refs.machineModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>
    
<style scoped></style>
    