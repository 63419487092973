<template>
  <div class="modal fade" id="modal-default" ref="prepressModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">เตรียมพิมพ์</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="card card-primary">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <b>ทั่วไป</b>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">หมายเลข Work Order</span><br>
                    <span>{{ item.woNumber }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่องาน</span><br>
                    <span>{{ item.woNumber }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                    <span>{{ item.ctmName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">ประเภทงาน</span><br>
                    <span>{{ item.wotName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">กำหนดส่งงาน</span><br>
                    <span>{{ item.woDeliverDate }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">พนักงานขาย</span><br>
                    <span>{{ item.empName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">หมายเหตุ</span><br>
                    <span>{{ item.woNote === null ? '-' : item.woNote }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-3">
                    <span class="inActiveText">จำนวนกระดาษที่จ่าย</span><br>
                    <span>{{ item.planning ? item.planning.plMaterialCost : '-' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mb-2 bg-secondary bg-light">
              <b>ตรวจสอบอุปกรณ์</b>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="checkbox" style="transform: scale(1.3);" :disabled="role !== '001' && role !== '005'"
                    v-model="formData.digitalProve" />
                  <span class="text-danger" style="padding-left: 10px">Digital Prove</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="checkbox" style="transform: scale(1.3);" :disabled="role !== '001' && role !== '005'"
                    v-model="formData.standardProve" />
                  <span class="text-danger" style="padding-left: 10px">Standard Prove</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="checkbox" style="transform: scale(1.3);" :disabled="role !== '001' && role !== '005'"
                    v-model="formData.exJob" />
                  <span class="text-danger" style="padding-left: 10px">ตัวอย่างงาน</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="checkbox" style="transform: scale(1.3);" :disabled="role !== '001' && role !== '005'"
                    v-model="formData.plateColor" />
                  <span class="text-danger" style="padding-left: 10px">เพลทสี</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <input type="checkbox" style="transform: scale(1.3);" :disabled="role !== '001' && role !== '005'"
                    v-model="formData.ink" />
                  <span class="text-danger" style="padding-left: 10px">หมึกพิมพ์</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <select class="select form-control" v-model="formData.color" :disabled="!formData.ink">
                    <option value="" disabled selected hidden>เลือกสี</option>
                    <option v-for="(item) in selectColor" :value="item.colorCode">{{ item['colorName'] }}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <span class="text-danger">สี</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="checkbox" style="transform: scale(1.3);" :disabled="role !== '001' && role !== '005'"
                    v-model="formData.plateVax" />
                  <span class="text-danger" style="padding-left: 10px">เพลทเคลือบ</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <input type="checkbox" style="transform: scale(1.3);" :disabled="role !== '001' && role !== '005'"
                    v-model="formData.blade" />
                  <span class="text-danger" style="padding-left: 10px">ใบมีด</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <input type="text" class="form-control" v-model="formData.bladeDetail" :disabled="!formData.blade" />
                </div>
              </div>
              <div class="col-sm-1">
                <div class="form-group">
                  <input type="checkbox" style="transform: scale(1.3);" :disabled="role !== '001' && role !== '005'"
                    v-model="formData.other" />
                  <span class="text-danger" style="padding-left: 10px">อื่นๆ</span>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <input type="text" class="form-control" v-model="formData.otherDetail" :disabled="!formData.other" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
    
<script>
import prepressStore from "@/store/prepressStore";
import loginStore from "@/store/loginStore";
import moment from 'moment'
import masterStore from "@/store/MasterStore";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "PrepressModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        role: loginStore.getters.role.roleCode,
        selectColor: [],
        formData: {
          type: '',
          digitalProve: '0',
          standardProve: '0',
          exJob: '0',
          plateColor: '',
          ink: false,
          color: null,
          plateVax: '0',
          blade: false,
          bladeDetail: '',
          other: false,
          otherDetail: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    try {
      this.getSelectColor()
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
      item: {
        handler() {
          try{
            this.resetFormData()
            if (this.item) {
              this.formData.digitalProve = this.item['woppDp'];
              this.formData.standardProve = this.item['woppSp'];
              this.formData.exJob = this.item['woppEx'];
              this.formData.plateColor = this.item['woppPc'];
              this.formData.ink = this.item['woppInk'];
              this.formData.color = this.item['colorCode'];
              this.formData.plateVax = this.item['woppPv'];
              this.formData.blade = this.item['woppBlade'];
              this.formData.bladeDetail = this.item['woppBladeDetail'];
              this.formData.other = this.item['woppOther'];
              this.formData.otherDetail = this.item['woppOtherDetail'];
            }
          }catch (e) {
            throw new Error(e);
          }
        },
        deep: true
      },
      'formData.ink': {
      handler(newVal) {
        if (!newVal) {
          this.formData.color = null;
        }
      }
    }
    },
  methods: {
    handleModal() {
      try {
        $(this.$refs.prepressModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    cancel() {
      try{
          this.formData.digitalProve = '0',
          this.formData.standardProve = '0',
          this.formData.exJob = '0',
          this.formData.plateColor = '',
          this.formData.ink = false,
          this.formData.color = null,
          this.formData.plateVax = '0',
          this.formData.blade = false,
          this.formData.bladeDetail = '',
          this.formData.other = false,
          this.formData.otherDetail = ''
      }catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
          this.isLoading = true;
          const token = this.token
          const payload = {
            plCode: this.item.planning.plCode,
            woNumber: this.item.woNumber,
            woppDp: this.formData.digitalProve === true ? '1' : '0',
            woppSp: this.formData.standardProve === true ? '1' : '0',
            woppEx: this.formData.exJob === true ? '1' : '0',
            woppPc: this.formData.plateColor === true ? '1' : '0',
            woppInk: this.formData.ink === true ? '1' : '0',
            colorCode: this.formData.color,
            woppPv: this.formData.plateVax === true ? '1' : '0',
            woppBlade: this.formData.blade === true ? '1' : '0',
            woppBladeDetail: this.formData.bladeDetail,
            woppOther: this.formData.other === true ? '1' : '0',
            woppOtherDetail: this.formData.otherDetail,
            status: this.item.status,
            woSeq: this.item.seq
          }
          await prepressStore.dispatch('createData', { token, payload })
          const result = prepressStore.getters.saveResult
          this.isLoading = false;
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
      } catch (e) {
        this.isLoading = false;
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.formData = {
          digitalProve: '0',
          standardProve: '0',
          exJob: '0',
          plateColor: '',
          ink: '0',
          color: null,
          plateVax: '0',
          blade: '0',
          bladeDetail: '',
          other: '0',
          otherDetail: '',
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.prepressModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError =  prepressStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    convertDateFormat(item) {
      try {
        if (item) {
          return moment(item, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        return null;
      } catch (e) {
        throw new Error(e);
      }
    },
    async getSelectColor() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('colorDropdown', payload)
        this.selectColor = masterStore.getters.colorDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel(){
      $(this.$refs.prepressModal).modal('hide');
        this.$emit('form-submitted')
    }
  },
}
</script>
    
<style scoped></style>
    