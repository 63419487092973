<template>
  <div class="row mb-3">
    <div class="col-sm-12">
      <Header/>
    </div>
    <div class="col-sm-6">
      <h1 class="m-0">{{ page }}</h1>
    </div>
  </div>
  <div v-if="option === 'r'" class="row mb-2">
    <div class="col-sm-12">
      <div class="card card-primary">
        <form>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>ค้นหา</label>
                      <input type="text" v-model="searchForm.workOrder" class="form-control" placeholder="ค้นหา"/>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>ชื่อเครื่อง</label>
                      <select v-model="searchForm.machineSeq" class="select form-control">
                        <option value="" disabled selected hidden>สถานะ</option>
                        <option v-for="(item) in machineDropdown" :key="item.seq">
                          {{ item.machineDetail }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>วันเริ่มต้นสั่งงาน</label>
                      <input type="date" v-model="searchForm.orderStartDate" class="form-control"
                             placeholder="วว/ดด/ปปปป"/>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>วันสิ้นสุดสั่งงาน</label>
                      <input type="date" v-model="searchForm.orderFinnishDate" class="form-control"
                             placeholder="วว/ดด/ปปปป"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-2" style="padding-top: 2%">
                <button @click.prevent="doDispatch" class="btn btn-primary btn-block" type="submit">ค้นหา</button>
                <button @click.prevent="toExport" class="btn btn-outline-primary btn-block" type="submit">Export
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <production-plan-list-modal @form-submitted="doDispatch" ref="productionPlanListModal" :item="rowOnClick"/>
  <div class="row">
    <Loading v-if="isLoading"/>
    <FullCalendar :options="calendarOptions" style="width: 100%" ref="calendar"/>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import machineStore from "@/store/machineStore";
import loginStore from "@/store/loginStore";
import productionListStore from "@/store/productionListStore";
import Loading from "@/components/loading/Loading.vue";
import ProductionPlanListModal from "@/components/productionPlanList/ProductionPlanListModal.vue";
import RoleStoreModal from "@/components/roleStore/RoleStoreModal.vue";
import Header from "@/components/header/Header.vue";

export default {
  name: "FullCalender",
  components: {
    Header,
    RoleStoreModal,
    ProductionPlanListModal,
    Loading,
    FullCalendar,
    interactionPlugin,
  },
  props: {
    item: {
      type: String,
    },
    option: {
      type: String,
    },
    page: {
      type: String,
    },
  },
  data() {
    return {
      rowOnClick: {},
      machineDropdown: [],
      searchForm: {
        workOrder: "",
        machineSeq: null,
        orderStartDate: "",
        orderFinnishDate: "",
      },
      currentEmpRole: loginStore.getters.role,
      currentEmpSeq: loginStore.getters.seq,
      isLoading: false,
      token: loginStore.getters.token,
      calendarOptions: {
        plugins: [dayGridPlugin, resourceTimelinePlugin, interactionPlugin],
        initialView: this.item,
        views: {
          resourceTimelineDay: {
            buttonText: "Day",
            slotLabelInterval: {hours: 1},
            slotLabelFormat: {
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: false,
              hour12: false,
            },
            slotMinTime: "00:00:00",
            slotMaxTime: "24:00:00",
          },
          resourceTimelineMonth: {
            buttonText: "Month",
            slotLabelInterval: {day: 1},
            slotLabelFormat: {
              day: "numeric",
            },
          },
          resourceTimelineWeek: {
            buttonText: "Week",
            slotLabelFormat: [
              {
                day: "numeric",
                weekday: "long",
              },
              {
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
                hour12: false,
              },
            ],
            slotMinTime: "00:00:00",
            slotMaxTime: "24:00:00",
          },
        },
        resources: [],
        events: [],
        resourceAreaHeaderContent: "เครื่อง",
        aspectRatio: 2,
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        },
        resourceGroupField: "MachineType",
        editable: true,
        eventColor: [], // Initialize as an empty array
        eventClick: function (info) {
          console.log(info.event.extendedProps);
        },
        eventDrop: this.handleEventDrop,
      },
    };
  },
  async mounted() {
    await this.doDispatch();
    await this.getMachine();
    await this.getEvent();
  },
  methods: {
    async doDispatch() {
      try {
        this.isLoading = true;
        const machinePayload = {
          token: this.token,
          page: 1,
          machineCode: "",
          machineStatus: "",
          msCode: "",
          mtSeq: "",
          limit: 1000,
        };
        const resourceViewPayload = {
          token: this.token,
          employeeSeq: this.currentEmpRole.roleCode === "003" ? this.currentEmpSeq : "",
        };
        await machineStore.dispatch("getDataTbl", machinePayload);
        await productionListStore.dispatch("getResourceView", resourceViewPayload);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error("Error loading machine data:", error);
      }
    },
    async getMachine() {
      try {
        const machineData = machineStore.getters.tblData;
        this.machineDropdown = machineData.machines;
        this.calendarOptions.resources = machineData.machines.map((item) => ({
          id: item.seq,
          title: item.machineDetail,
          MachineType: item.mtName,
        }));
      } catch (error) {
        console.error("Error loading machine data:", error);
      }
    },
    async getEvent() {
      try {
        const planningData = productionListStore.getters.getResourceData;

        this.calendarOptions.events = planningData.map((item) => {
          return {
            title: item.woWCode,
            woSeq: item.woSeq,
            detail: `${item.woWCode}\nเริ่ม : ${item.startDate}\nสิ้นสุด : ${item.finishDate}`,
            start: new Date(item.startDate),
            end: new Date(item.finishDate),
            resourceId: item.machineSeq,
            woNumber: item.woNumber,
            plSeq: item.plSeq,
            color: item.print !== undefined ? "#007BFF" : "#FF991F",
            isPrint: item.print === 'y' ? 1 : 0,
          }
        });
      } catch (error) {
        console.error("Error loading machine data:", error);
      }
    },
    async handleEventDrop(info) {
      this.isLoading = true;
      const token = this.token;
      const startDateSet = new Date(info.event.start);
      const endDateSet = new Date(info.event.end);
      const startDate = this.handleChangeDateformat(startDateSet);
      const endDate = this.handleChangeDateformat(endDateSet);
      const eventProps = info.event.extendedProps;
      console.log(eventProps.isPrint);
      const machineSeq = info.event._def.resourceIds[0];
      const payload = {
        woSeq: eventProps.woSeq,
        woNumber: eventProps.woNumber,
        machineSeq: machineSeq,
        plSeq: eventProps.plSeq,
        timeStart: startDate,
        timeFinish: endDate,
        isPrint: eventProps.isPrint
      }
      await productionListStore.dispatch('updateByResourceView', {token, payload})
      const result = productionListStore.getters.saveResult
      if (result) {
        this.isLoading = false;
        this.successFunction()
      } else {
        this.isLoading = false;
        this.errorFunction()
      }
    },
    handleChangeDateformat(date) {
      return date.toISOString().slice(0, 19).replace('T', ' ');
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน',
          backdrop: false,
          allowOutsideClick: false
        }).then(async () => {
          await this.doDispatch();
          await this.getMachine();
          await this.getEvent();
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = productionListStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน',
          backdrop: false,
          allowOutsideClick: false
        }).then(async () => {
          await this.doDispatch();
          await this.getMachine();
          await this.getEvent();
        })
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
