<template>
  <div class="modal fade" id="modal-default" ref="materialModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ formData.type === 0 ? 'เพิ่มวัตถุดิบ' : 'แก้ไขวัตถุดิบ' }}</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <label>รหัสวัตถุดิบ</label>
                  <input type="text" v-model="formData.materialCode" class="form-control" placeholder="รหัสวัตถุดิบ">
                  <span class="text-danger">{{ validateResult.materialCode }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>ชื่อวัตถุดิบ</label>
                  <input type="text" v-model="formData.materialName" class="form-control" placeholder="ชื่อวัตถุดิบ">
                  <span class="text-danger">{{ validateResult.materialName }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Supplier</label>
                  <input type="text" v-model="formData.supplier" class="form-control" placeholder="supplier">
                  <span class="text-danger">{{ validateResult.supplier }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-7">
                <div class="form-group">
                  <label>ประเภท</label>
                  <select class="select form-control" v-model="formData.materialType">
                    <option value="" disabled selected hidden>เลือกประเภทวัตถุดิบ</option>
                    <option v-for="(item) in selectMaterialType" :value="item.mtCode">{{ item['mtName'] }}</option>
                  </select>
                  <span class="text-danger">{{ validateResult.materialType }}</span>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group">
                  <label>ราคาต่อหน่วย</label>
                  <input
                      type="text"
                      v-model="formData.materialPrice"
                      class="form-control"
                      placeholder="ราคาวัตถุดิบ"
                      @input="handleInput($event,'materialPrice')"
                  >
                  <span class="text-danger">{{ validateResult.materialPrice }}</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label>ข้อมูลเพิ่มเติม</label>
                <textarea type="text" v-model="formData.materialDetail" class="form-control"
                  placeholder="ข้อมูลเพิ่มเติม"></textarea>
                <span class="text-danger">{{ validateResult.materialDetail }}</span>
              </div>
            </div>
            <div class="col-sm-12" style="display: inline-flex;">
              <label class="mr-2">สถานะ : </label>
              <input type="checkbox" v-model="formData.materialStatus" id="toggle-switch"
                class="toggle-switch visually-hidden">
              <label for="toggle-switch" class="toggle-switch-label">
                <span class="toggle-switch-inner"></span>
                <span class="toggle-switch-switch"></span>
              </label>
              <span :class="activeStatus()">{{ formData.materialStatus ? "เปิดใช้งาน" : "ปิดใช้งาน" }}</span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
    
<script>
import materialStore from "@/store/materialStore";
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "MaterialModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        selectMaterialType: [],
        validateResult: {
          materialCode: '',
          materialType: '',
          materialName: '',
          supplier: '',
          materialPrice: '',
          materialDetail: '',
        },
        formData: {
          type: '',
          materialCode: '',
          materialType: '',
          materialName: '',
          supplier: '',
          materialPrice: '',
          materialDetail: '',
          materialStatus: '',
          seq: null
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    try {
      this.getSelectMaterialType()
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        materialCode: Yup.string().required('รหัสวัตถุดิบห้ามเป็นค่าว่าง'),
        materialType: Yup.mixed().oneOf(this.selectMaterialType.map(c => c.mtCode)).required('ประเภทวัตถุดิบห้ามเป็นค่าว่าง'),
        materialName: Yup.string().required('ชื่อวัตถุดิบห้ามเป็นค่าว่าง'),
        supplier: Yup.string().required('supplier ห้ามเป็นค่าว่าง'),
        materialPrice: Yup.string().required('ราคาวัตถุดิบห้ามเป็นค่าว่าง'),
      });
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.type = this.item['type'];
            this.formData.seq = this.item['seq'];
            this.formData.materialCode = this.item['materialCode'];
            this.formData.materialType = this.item['mtCode'];
            this.formData.materialName = this.item['materialName'];
            this.formData.supplier = this.item['supplier'];
            this.formData.materialPrice = this.item['materialPrice'];
            this.formData.materialDetail = this.item['materialDetail'];
            this.formData.materialStatus = this.item['type'] === 0 ? true : this.item['materialStatus'] === "1";
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  methods: {
    handleInput(event,formData) {
      const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
      const match = sanitizedValue.match(/(\d*\.\d{0,2}|\d*)/);
      this.formData[formData] = match ? match[0] : '';
    },
    handleModal() {
      try {
        $(this.$refs.materialModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    activeStatus() {
      try {
        return this.formData.materialStatus ? 'activeTextToggle ml-2' : 'inActiveTextToggle ml-2';
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.formSchema.validate(this.formData, { abortEarly: false }).then(async () => {
          this.isLoading = true;
          const token = this.token
          if (this.formData.type === 0) {
            const payload = {
              materialCode: this.formData.materialCode,
              mtCode: this.formData.materialType,
              materialName: this.formData.materialName,
              supplier: this.formData.supplier,
              materialPrice: this.formData.materialPrice,
              materialDetail: this.formData.materialDetail,
              materialStatus: this.formData.materialStatus ? "1" : "0"
            }
            await materialStore.dispatch('createData', { token, payload })
          }
          if (this.formData.type === 1) {
            const payload = {
              seq: this.formData.seq,
              materialCode: this.formData.materialCode,
              mtCode: this.formData.materialType,
              materialName: this.formData.materialName,
              supplier: this.formData.supplier,
              materialPrice: this.formData.materialPrice,
              materialDetail: this.formData.materialDetail,
              materialStatus: this.formData.materialStatus ? "1" : "0",
            }
            await materialStore.dispatch('updateData', { token, payload })
          }
          const result = materialStore.getters.saveResult
          this.isLoading = false;
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }
        }
        ).catch((err) => {
          this.isLoading = false;
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try{
        this.validateResult = {
          materialCode: '',
          materialType: '',
          materialName: '',
          supplier: '',
          materialPrice: '',
          materialDetail: '',
        }
      }catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try{
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่าง',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      }catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.materialModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError =  materialStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    async getSelectMaterialType() {
      try{
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('materialForm', payload)
        this.selectMaterialType = masterStore.getters.materialForm
      }catch (e) {
        throw new Error(e);
      }
    },
    handleCancel(){
      $(this.$refs.materialModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>
    
<style scoped></style>
    