<template>
  <content>
    <div class="content-header">
      <div class="container-fluid">
        <a @click="handleBack" class="navigationText rowHovered">{{ `< กลับ` }}</a>
            <h1 class="m-0">บันทึกเวลาการสลีต {{}}</h1>
            <saveSleet-modal @form-submitted="getData(currentPage, 10)" ref="saveSleetModal"/>
            <div class="card card-primary mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <b>ทั่วไป</b>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ชื่องาน</span><br>
                    <span class="text-bold front-color-custom">{{ this.item[0].woWcode }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">หมายเลข Work Order</span><br>
                    <span class="text-bold">{{ this.item[0].woNumber }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                    <span class="text-bold">{{ this.item[0].ctmName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">ประเภทงาน</span><br>
                    <span class="text-bold">{{ this.item[0].wotName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">พนักงานขาย</span><br>
                    <span class="text-bold">{{ this.item[0].empName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-6">
                    <span class="inActiveText">กำหนดส่งงาน</span><br>
                    <span class="text-bold">{{ this.item[0]['planning'].plProdDeliver }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-12">
                    <span class="inActiveText">หมายเหตุ</span><br>
                    <span class="text-bold">{{ this.item[0].woRemark }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-primary mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6 mt-2 float-left">
                    <b>ตารางผลิต</b>
                  </div>
                  <div class="col-sm-6 text-right">
                    <button class="btn btn-primary" @click.prevent="addTime()">เพิ่มช่วงเวลา</button>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>

  </content>
</template>


<script>
import Content from "@/components/Content.vue";
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";
import HeaderSection from "@/components/boilerplate/headerSection.vue";
import SaveSleetModal from "@/components/saveSleet/SaveSleetModal.vue";

export default {
  name: "SaveSleetContent",
  components: { HeaderSection, Header, Loading, Content, SaveSleetModal },
  props: ['item'],
  emits: [
    'saveSleet-content-view',
  ],
  data() {
    try {
      return {
        itemInfo: null,
        emitProgress: {
          progress: '',
          result: ''
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },

  methods: {
    handleBack() {
      this.emitProgress.progress = 'back'
      this.handleChange()
    },
    handleChange() {
      this.$emit("saveSleet-content-view", { emitData: this.emitProgress.progress })
    },
    async addTime() {
      try {
        this.$refs.saveSleetModal.handleModal(this.item[0])
      } catch (e) {
        throw new Error(e);
      }
    },
  }
}
</script>


<style scoped></style>