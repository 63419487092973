<template>
  <div class="row mb-2">
    <div class="col-sm-12">
      <div class="card card-primary">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-10">
              <div class="row">
                <template v-for="(field) in fieldRequire">
                  <SearchField
                      :item="{ selected: field.selected, other: field.other, classCondition: field.classCondition, emitData: field.emitData }"
                      @handle-emit-field="handleEmitData"
                  />
                </template>
              </div>
            </div>
            <div class="form-group col-sm-2" style="padding-top: 2%">
              <button class="btn btn-primary btn-block" @click="handleSubmitData">ค้นหา
              </button>
              <button v-if="exportRequire" class="btn btn-outline-primary btn-block" type="submit">
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header/Header.vue";
import SearchField from "@/components/boilerplate/SearchField.vue";

export default {
  name: "SearchSection",
  components: {SearchField, Header},
  props: ['item'],
  data() {
    try {
      return {
        fieldRequire: [],
        exportRequire: false,
        searchPrompt : {}
      }
    } catch (e) {
      throw new Error(e)
    }
  },
  mounted() {
    this.handleField()
    this.handleExport()
  },
  emits: [
    'search-from',
  ],
  methods: {
    handleField() {
      this.fieldRequire = this.item.fieldRequire
    },
    handleExport() {
      this.exportRequire = this.item.exportRequire
    },
    handleEmitData(data) {
      for (let x = 0; x < this.fieldRequire.length; x++) {
          if(this.fieldRequire[x].emitData === data.emitData){
              this.searchPrompt[this.handleKeyWord(data.emitData)] = data.result
          }
      }
    },
    handleKeyWord(data){
      return  data.split("-")[0];

    },
    handleSubmitData() {
      this.$emit("search-from",this.searchPrompt )
    }
  }
}
</script>

<style scoped>

</style>