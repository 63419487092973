import { createStore } from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";
const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
export default createStore({
    state: {
        seq:null,
        firstName:'',
        lastName:'',
        role:Object,
        roleName:'',
        result:false,
        token:'',
        permissions:[],
        pagePermission:{}
    },
    getters: {
        seq: state => state.seq,
        firstName: state => state.firstName,
        lastName: state => state.lastName,
        role : state => state.role,
        result : state => state.result,
        token : state => state.token,
        permissions: state => state.permissions,
        pagePermission: state => state.pagePermission
    },
    mutations: {
        setUser (state,payload){
                state.seq = payload.seq || null;
                state.firstName = payload.employeeFirstname || '';
                state.lastName = payload.employeeLastname || '';
                state.role = payload.role || '';
                state.result = !!payload;
                state.token = localStorage.getItem('accessToken') || '';
                state.permissions = payload.permissions || [];

        },
        setPagePermission(state,payload){
            state.pagePermission = payload || {}
        }
    },
    actions: {
        async logout({commit}){
            const payload = {}
            commit('setUser',payload);
        },
        async login({commit},payload){
            try {
                const result = await axiosInstance.post(`auth/login`,{
                    username: payload.username,
                    password: payload.password
                })
                if(result.status === 200){
                    localStorage.setItem('accessToken',result.data.accessToken);
                    commit('setUser',result.data.accessToken);
                }
            }catch (error){
                commit('setUser', false);
            }
        },
        async authUser({commit},token){
            try {
                const result = await axiosInstance.get('auth/profile',{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('setUser', result.data);
                }
            }catch (error){
                commit('setUser', false);
            }
        },
        async pagePermission({commit,state},page){
            try {
                const permission = state.permissions.find(m => m['menuPath'] === page)
                commit('setPagePermission', permission);
            }catch (error){
                commit('setPagePermission', false);
            }
        },

    },

    modules: {
    }
})
