<template>
  <div class="modal fade" id="modal-default" ref="customerViewModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">ข้อมูลลูกค้า</h4>
          <button type="button" class="close" @click="handleCancel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card card-primary">
            <div class="card-body">
              <div class="row">
                <div style="padding-left: 3%" class="col-sm-3">
                  <span class="inActiveText">รหัส</span><br>
                  <span>{{ data.customerCode }}</span>
                </div>
                <div style="padding-left: 3%" class="col-sm-9">
                  <span class="inActiveText">ชื่อกลุ่มลูกค้า</span><br>
                  <span>{{ data.customerName }}</span>
                </div>
                <div style="padding-left: 3%" class="col-sm-4">
                  <span class="inActiveText">เครดิต</span><br>
                  <span>{{ data.creditName ? data.creditName : '-' }}</span>
                </div>
                <div style="padding-left: 3%" class="col-sm-4">
                  <span class="inActiveText">อีเมล</span><br>
                  <span>{{ data.customerEmail ? data.customerEmail : '-' }}</span>
                </div>
                <div style="padding-left: 3%" class="col-sm-4">
                  <span class="inActiveText">เบอร์โทรศัพท์</span><br>
                  <span>{{ data.customerPhone ? data.customerPhone : '-' }}</span>
                </div>
                <div style="padding-left: 3%" class="col-sm-6">
                  <span class="inActiveText">เซลล์</span><br>
                  <span>{{ data.employeeFirstname ? `${data.employeeFirstname} ${data.employeeLastname}` : '-' }}</span>
                </div>
                <div style="padding-left: 3%" class="col-sm-6">
                  <span class="inActiveText">กลุ่มลูกค้า</span><br>
                  <span>{{ data.cgName ? data.cgName : '-' }}</span>
                </div>
                <div style="padding-left: 3%" class="col-sm-12">
                  <span class="inActiveText">ที่อยู่</span><br>
                  <span>{{ data.customerAddress ? data.customerAddress : '-' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomerViewModal",
  props: ['item'],
  data() {
    return {
      data: [],
    }
  },
  watch: {
    item: {
      handler() {
        try {
          if (this.item) {
            this.data = this.item
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.customerViewModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel() {
      $(this.$refs.customerViewModal).modal('hide');
      this.$emit('view-submitted')
    }
  }
}
</script>

<style scoped>

</style>