import {createStore} from 'vuex'
import axios from "axios";
import environmentStore from "@/store/environmentStore";

const axiosInstance = axios.create({
    baseURL: environmentStore.getters.environment,
    headers: {
        'Content-Type': 'application/json',
    }
});
axiosInstance.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 499;
};
export default createStore({
    state: {
        tblData: Object,
        saveResult: Boolean,
        handleMessage:String,
    },
    getters: {
        tblData: state => state.tblData,
        saveResult: state => state.saveResult,
        errorMessage:state => state.handleMessage
    },
    mutations: {
        setDataTbl(state, payload) {
            state.tblData = payload
        },
        setSave(state, result) {
            state.saveResult = result
        },
        setError(state,error){
            state.handleMessage = error
        }
    },
    actions: {
        async getDataTbl({commit}, {sequence,token, status, page, woWCode, woNumber, machineSeq}) {
            try {
                let customURL = `production/findAll/sleet?limit=10&page=${page}`
                if(sequence !== undefined){
                    customURL = `production/findAll/sleet?seq=${sequence}&limit=10&status=${status}`
                }
                const result = await axiosInstance.get(customURL, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            } catch (error: any) {
                commit('setSave', false);
            }
        },
        async getDataTblProduct({commit}, {token, limit, woSeq, plSeq, prepressSeq}) {
            try {
                if (!limit) {
                    limit = 10
                }
                let customURL = `production/tableProduction/sleet?woSeq=${woSeq}&plSeq=${plSeq}&prepressSeq=${prepressSeq}`
                const result = await axiosInstance.get(customURL, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setDataTbl', result.data)
                }
            } catch (error: any) {
                commit('setSave', false);
            }
        },
        async createData({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`production/create/sleet`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 201) {
                    commit('setSave', result)
                } else {
                    commit('setSave', false)
                    commit('setError', result.data['msg']);
                }
            } catch (error: any) {
                commit('setSave', false);
                commit('setError', error.response.data['msg']);
            }
        },
        async deleteData({commit}, {token, seq}) {
            try {
                const result = await axiosInstance.delete(`production/tableProduction/sleet/delete?seq=${seq}`, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if (result.status === 200) {
                    commit('setSave', true)
                } else {
                    commit('setSave', false)
                    commit('setError', result.data['msg']);
                }
            } catch (error :any) {
                commit('setSave', false);
                commit('setError', error.response.data['msg']);
            }
        },
        async accept({commit}, {token,seq}) {
            try {
                let customURL = `production/accept`
                const result = await axiosInstance.put(customURL, {woSeq:seq},{
                    headers: {
                        'Authorization': `Bearer ${token} `
                    }
                })
                if(result.status === 200){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave', false);
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
        async close({commit}, {token, payload}) {
            try {
                const result = await axiosInstance.post(`/production/closing/sleet`, payload, {
                    headers: {
                        'Authorization': `Bearer ${token} `
                    },
                })
                if(result.status === 201){
                    commit('setSave',true)
                }else{
                    commit('setSave',false)
                    commit('setHandleMessage', result.data['msg']);
                }
            } catch (error:any) {
                commit('setSave',false)
                commit('setHandleMessage', error.response.data['msg']);
            }
        },
    },
    modules: {}
})
