<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <Loading v-if="isLoading"/>
        <div class="row mb-3">
          <div class="col-sm-12">
            <Header/>
          </div>
          <div class="col-sm-6">
            <h1 class="m-0">พนักงาน</h1>
          </div>
          <div class="col-sm-6">
            <button :disabled="permission.epCreate === '0'" type="button" @click="handleRowClick({}, 0)"
                    class="btn btn-success float-right">
              เพิ่มพนักงาน
            </button>
            <employee-modal @form-submitted="getData(currentPage)" ref="employeeModal" :item="rowOnClick"/>
            <employee-view-modal @view-submitted="getData(currentPage)" ref="employeeViewModal" :item="rowOnClick"/>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>รหัสผู้ใช้</label>
                        <input type="text" v-model="searchForm.userCode" class="form-control"
                               placeholder="รหัสผู้ใช้">
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>ชื่อ</label>
                        <input type="text" v-model="searchForm.firstname" class="form-control"
                               placeholder="ชื่อ">
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>นามสกุล</label>
                        <input type="text" v-model="searchForm.lastname" class="form-control"
                               placeholder="นามสกุล">
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>สถานะ</label>
                        <select v-model="searchForm.status" class="select form-control">
                          <option value="" disabled selected hidden>สถานะ</option>
                          <option v-for="(statusItem) in statusDropdown" :key="statusItem.value"
                                  :value="statusItem.value">
                            {{ statusItem.text }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-sm-1 align-self-end">
                      <button @click.prevent="getData(1)" class="btn btn-primary btn-block"
                              type="submit">ค้นหา
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table class="table table-sm">
                    <thead>
                    <tr>
                      <th style="width: 10%"></th>
                      <th class="on-pointer" @click.prevent="handleSortData('employeeCode')">รหัสผู้ใช้
                        <i :class="`fa fa-arrow-${sortTable.column === 'employeeCode' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('employeeUsername')">รหัสพนักงาน
                        <i :class="`fa fa-arrow-${sortTable.column === 'employeeUsername' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('employeeFirstname')">ชื่อ
                        <i :class="`fa fa-arrow-${sortTable.column === 'employeeFirstname' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('employeeLastname')">นามสกุล
                        <i :class="`fa fa-arrow-${sortTable.column === 'employeeLastname' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('employeePhone')">เบอร์โทรศัพท์
                        <i :class="`fa fa-arrow-${sortTable.column === 'employeePhone' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('employeeEmail')">อีเมล
                        <i :class="`fa fa-arrow-${sortTable.column === 'employeeEmail' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('roleName')">สิทธิ์การใช้งาน
                        <i :class="`fa fa-arrow-${sortTable.column === 'roleName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" @click.prevent="handleSortData('employeeStatus')">สถานะ
                        <i :class="`fa fa-arrow-${sortTable.column === 'employeeStatus' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="rowHovered" v-for="(item) in tblData" @click="handleRowClick(item, 1)">
                      <td>
                        <button :disabled="permission.epDelete === '0'"
                                @click="doDelete(item, $event)" class="btn btn-danger"><i
                            class="fa fa-trash"></i></button>
                      </td>
                      <td>{{ item['employeeCode'] }}</td>
                      <td>{{ item['employeeUsername'] }}</td>
                      <td>{{ item['employeeFirstname'] }}</td>
                      <td>{{ item['employeeLastname'] }}</td>
                      <td>{{ item['employeePhone'] }}</td>
                      <td>{{ item['employeeEmail'] }}</td>
                      <td>{{ item['roleName'] }}</td>
                      <td
                          :class="item['employeeStatus'] === '1' ? 'activeTextToggle' : 'inActiveTextToggle'">
                        {{ item['employeeStatus'] === '1' ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1
                  ถึง 10 ของ
                  {{ allShow }}
                  ทั้งหมด
                </div>
              </div>
              <div class="col-sm-12 col-md-7 ">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination">
                    <li class="paginate_button page-item previous">
                      <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                        ก่อนหน้า
                      </button>
                    </li>
                    <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                        v-for="page in pages" :key="page">
                      <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                    </li>
                    <li class="paginate_button page-item previous">
                      <button @click="nextPage()" :disabled="currentPage >= allPage[8]"
                              class="page-link">ถัดไป
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>

<script>
import EmployeeModal from "@/components/employee/EmployeeModal.vue";
import loginStore from "@/store/loginStore";
import employeeStore from "@/store/employeeStore";
import customerGroupStore from "@/store/customerGroupStore";
import MasterStore from "@/store/MasterStore";
import EmployeeViewModal from "@/components/employee/EmployeeViewModal.vue";
import Loading from "@/components/loading/Loading.vue";
import customerStore from "@/store/customerStore";
import {isNull} from "../../public/adminlte/plugins/pdfmake/pdfmake";
import Header from "@/components/header/Header.vue";

export default {
  name: "Employee",
  components: {Header, Loading, EmployeeViewModal, EmployeeModal},
  data() {
    try {
      return {
        sortTable:{column:null,sort:'asc',icon:'up'},
        isLoading: false,
        token: loginStore.getters.token,
        permission: loginStore.getters.pagePermission,
        tblData: null,
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        showModal: false,
        rowOnClick: {},
        statusDropdown: null,
        searchForm: {
          userCode: '',
          firstname: '',
          lastname: '',
          status: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    try {
      this.getData(1, 10)
      this.getStatus()
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({length: totalPages}, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  methods: {
    handleSortData(column) {
      this.sortTable.column = column
      this.sortTable.sort =  this.sortTable.sort === 'desc'? 'asc':"desc"
      this.sortTable.icon = this.sortTable.sort === 'desc'? 'down':"up"
      const data = this.tblData
      const actionData = []
      for(let item of data){
        if(item[column] === null){
          item[column] = ''
        }
        actionData.push(item)
      }
      this.tblData = actionData.sort((a, b) => {
        if (this.sortTable.sort === "desc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      })
    },
    async handleRowClick(data, type) {
      try {
        await this.getData(this.currentPage)
        data['type'] = type
        if (type === 1) {
          const dataByID = await this.getDataById(data.seq)
          const dataModal = dataByID[0]
          if (dataByID.length > 0) {
            dataModal.type = type
            this.rowOnClick = dataModal
            if (this.permission.epUpdate === '1' && type === 1) {
              this.$refs.employeeModal.handleModal()
            } else {
              this.$refs.employeeViewModal.handleModal()
            }
          } else {
            await this.getData(this.currentPage)
          }
        } else {
          this.rowOnClick = data
          if (this.permission.epCreate === '1' && type === 0) {
            this.$refs.employeeModal.handleModal()
          } else {
            await this.getData(this.currentPage)
          }
        }

      } catch (e) {
        throw new Error(e);
      }
    },
    async getData(page) {
      try {
        this.isLoading = true
        const payload = {
          token: this.token,
          page: page,
          empCode: this.searchForm.userCode,
          empName: this.searchForm.firstname,
          empSurName: this.searchForm.lastname,
          status: this.searchForm.status || "1",
        }
        await employeeStore.dispatch('getDataTbl', payload)
        this.tblData = employeeStore.getters.tblData['employees']
        this.pageSize = employeeStore.getters.tblData['limit']
        this.allShow = employeeStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getDataById(seq) {
      try {
        this.isLoading = true
        const payload = {
          sequence: seq,
          token: this.token,
          status: this.searchForm.status || "1"
        }
        await employeeStore.dispatch('getDataTbl', payload)
        this.isLoading = false
        return employeeStore.getters.tblData['employees']
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    async doDelete(data, event) {
      try {
        event.stopPropagation();
        const payload = {
          token: loginStore.getters.token,
          seq: data.seq
        }
        const resultConfirm = await this.confirmDeleteFunction(payload)
        const result = employeeStore.getters.saveResult
        if (result === true && resultConfirm === true) {
          this.successFunction()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async confirmDeleteFunction(payload) {
      try {
        const result = await this.$swal.fire({
          title: 'ต้องการลบข้อมูลหรือไม่',
          text: 'กดยืนยันเพื่อทำการลบข้อมูล',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })
        if (result.isConfirmed) {
          await employeeStore.dispatch('deleteData', payload)
          return true
        } else {
          return false
        }

      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'ลบข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage);
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    getStatus() {
      try {
        this.statusDropdown = MasterStore.getters.statusDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
  }
}
</script>

<style scoped>

.table {
  width: 200%;
}

.table th:first-child,
.table th:nth-child(2),
.table th:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #D8EBFF;
}

.table td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #F8F9FB;
}
</style>
