<template>
  <div class="modal fade" id="modal-default" ref="customerModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ formData.type === 0 ? 'เพิ่ม' : 'แก้ไข' }}ข้อมูลลูกค้า</h4>
          <button type="button" class="close" @click="handleCancel()"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label>รหัส</label>
                  <input type="text" v-model="formData.customerCode" class="form-control"
                         placeholder="รหัส">
                  <span class="text-danger">{{ validateResult.customerCode }}</span>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group">
                  <label>ชื่อลูกค้า</label>
                  <input type="text" v-model="formData.customerName" class="form-control"
                         placeholder="ชื่อลูกค้า">
                  <span class="text-danger">{{ validateResult.customerName }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>เครดิต</label>
                  <select class="select form-control" v-model="formData.creditCode">
                    <option value="" disabled selected hidden>เครดิต</option>
                    <option :value="item['creditCode']" v-for="(item) in creditDropdown"
                            :key="item['creditCode']">
                      {{ item['creditName'] }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.creditCode }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>อีเมล</label>
                  <input type="text" v-model="formData.customerEmail" class="form-control"
                         placeholder="อีเมล">
                  <span class="text-danger">{{ validateResult.customerEmail }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label>เบอร์โทรศัพท์</label>
                  <input type="text" v-model="formData.customerPhone" class="form-control"
                         placeholder="เบอร์โทรศัพท์">
                  <span class="text-danger">{{ validateResult.customerPhone }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>เซลล์</label>
                  <select class="select form-control" v-model="formData.employeeSeq">
                    <option value="" disabled selected hidden>เซลล์</option>
                    <option :value="item['seq']" v-for="(item) in empManuDropdown"
                            :key="item['seq']">
                      {{ item['employeeFirstname'] + " " + item['employeeLastname'] }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.employeeSeq }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>กลุ่มลูกค้า</label>
                  <select class="select form-control" v-model="formData.cgSeq">
                    <option value="" disabled selected hidden>กลุ่มลูกค้า</option>
                    <option :value="item['seq']" v-for="(item) in customerGroupDropdown"
                            :key="item['seq']">
                      {{ item['cgName'] }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.cgSeq }}</span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label>ที่อยู่</label>
                  <textarea type="text" v-model="formData.customerAddress" class="form-control"
                            placeholder="ที่อยู่"></textarea>
                  <span class="text-danger">{{ validateResult.cgSeq }}</span>
                </div>
              </div>
              <div class="col-sm-12" style="display: inline-flex;">
                <label class="mr-2">สถานะ : </label>
                <input type="checkbox" v-model="formData.customerStatus" id="toggle-switch"
                       class="toggle-switch visually-hidden">
                <label for="toggle-switch" class="toggle-switch-label">
                  <span class="toggle-switch-inner"></span>
                  <span class="toggle-switch-switch"></span>
                </label>
                <span :class="activeStatus()">{{
                    formData.customerStatus ? "เปิดใช้งาน" : "ปิดใช้งาน"
                  }}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
            <button type="submit" :disabled="isLoading" class="btn btn-primary float-right">บันทึก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import customerStore from "@/store/customerStore";
import * as Yup from "yup";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: "CustomerModal",
  components: {Loading},
  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        empManuDropdown: [],
        customerGroupDropdown: [],
        creditDropdown: [],
        validateResult: {
          cgSeq: '',
          cgName: '',
          creditCode: '',
          customerCode: '',
          customerName: '',
          employeeSeq: '',
          customerPhone: '',
          customerEmail: '',
          customerAddress: '',
        },
        formData: {
          type: '',
          cgSeq: '',
          cgName: '',
          creditCode: '',
          creditName: '',
          customerAddress: '',
          customerCode: '',
          customerDescript: '',
          customerEmail: '',
          customerName: '',
          customerPhone: '',
          customerStatus: '',
          employeeSeq: '',
          seq: null
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    formSchema() {
      return Yup.object().shape({
        cgSeq: Yup.mixed().nullable().oneOf(this.customerGroupDropdown.map(c => c.seq)),
        customerName: Yup.string().required('ชื่อลูกค้าห้ามเป็นค่าว่าง'),
        creditCode: Yup.mixed().nullable().oneOf(this.creditDropdown.map(c => c.creditCode)),
        customerCode: Yup.string().required('รหัสห้ามเป็นค่าว่าง'),
        employeeSeq: Yup.mixed().nullable().oneOf(this.empManuDropdown.map(c => c.seq)),
        customerPhone: Yup.string().nullable().matches(/^(\+?\d{1,3}[- ]?)?\d{10}$/, 'รูปแบบของเบอร์โทรศัพท์ไม่ถูกต้อง'),
        customerEmail: Yup.string().nullable().email('รูปแบบของอีเมลไม่ถูกต้อง'),
        customerAddress: Yup.string().nullable(),
      });
    }
  },
  watch: {
    item: {
      handler() {
        try {
          this.resetFormData()
          if (this.item) {
            this.formData.type = this.item['type'];
            this.formData.seq = this.item['seq'];
            this.formData.cgSeq = this.item['cgSeq'];
            this.formData.cgName = this.item['cgName'];
            this.formData.creditCode = this.item['creditCode'];
            this.formData.creditName = this.item['creditName'];
            this.formData.customerAddress = this.item['customerAddress'];
            this.formData.customerCode = this.item['customerCode'];
            this.formData.customerDescript = this.item['customerDescript'];
            this.formData.customerEmail = this.item['customerEmail'];
            this.formData.customerName = this.item['customerName'];
            this.formData.customerPhone = this.item['customerPhone'];
            this.formData.customerStatus = this.item['type'] === 0 ? true : this.item['customerStatus'] === "1";
            this.formData.employeeSeq = this.item['employeeSeq'];
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  async mounted() {
    try {
      const payload = {
        token: this.token
      }
      await masterStore.dispatch('customer', payload)
      await this.getEmployee()
      await this.getCustomerGroupCredit()
    } catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    handleModal() {
      try {
        $(this.$refs.customerModal).modal({
          backdrop: 'static',
          keyboard: false
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async getEmployee() {
      try {
        this.empManuDropdown = masterStore.getters.employeeForm
      } catch (e) {
        throw new Error(e);
      }
    },
    async getCustomerGroupCredit() {
      try {
        this.customerGroupDropdown = masterStore.getters.customerGroupFrom
        this.creditDropdown = masterStore.getters.creditForm
      } catch (e) {
        throw new Error(e);
      }
    },
    activeStatus() {
      try {
        return this.formData.customerStatus ? 'activeTextToggle ml-2' : 'inActiveTextToggle ml-2';
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        this.formSchema.validate(this.formData, {abortEarly: false}).then(async () => {
              this.isLoading = true;
              const token = this.token
              if (this.formData.type === 0) {
                const payload = {
                  cgSeq: this.formData.cgSeq,
                  creditCode: this.formData.creditCode,
                  customerCode: this.formData.customerCode,
                  customerName: this.formData.customerName,
                  customerPhone: this.formData.customerPhone,
                  customerEmail: this.formData.customerEmail,
                  customerStatus: this.formData.customerStatus ? "1" : "0",
                  customerDescript: this.formData.customerDescript ? this.formData.customerDescript : "",
                  employeeSeq: this.formData.employeeSeq,
                  customerAddress: this.formData.customerAddress
                }
                await customerStore.dispatch('createData', {token, payload})
              }
              if (this.formData.type === 1) {
                const payload = {
                  seq: this.formData.seq,
                  cgSeq: this.formData.cgSeq,
                  creditCode: this.formData.creditCode,
                  customerCode: this.formData.customerCode,
                  customerName: this.formData.customerName,
                  customerPhone: this.formData.customerPhone,
                  customerEmail: this.formData.customerEmail,
                  customerStatus: this.formData.customerStatus ? "1" : "0",
                  customerDescript: this.formData.customerDescript ? this.formData.customerDescript : "",
                  employeeSeq: this.formData.employeeSeq,
                  customerAddress: this.formData.customerAddress
                }
                await customerStore.dispatch('updateData', {token, payload})
              }
              const result = customerStore.getters.saveResult
              this.isLoading = false;
              if (result) {
                this.successFunction()
              } else {
                this.errorFunction()
              }
            }
        ).catch((err) => {
          this.isLoading = false
          this.validateResult = {};
          err.inner.forEach((error) => {
            this.alertFunction()
            this.validateResult[error.path] = error.message;
          })
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    resetFormData() {
      try {
        this.validateResult = {
          cgSeq: '',
          cgName: '',
          creditCode: '',
          customerCode: '',
          customerName: '',
          employeeSeq: '',
          customerPhone: '',
          customerEmail: '',
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.customerModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = customerStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    handleCancel(){
      $(this.$refs.customerModal).modal('hide');
      this.$emit('form-submitted')
    }
  },
}
</script>

<style scoped>

</style>
