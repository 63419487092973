<template>
    <div class="modal fade" id="modal-default" ref="employeeViewModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">ข้อมูลลูกค้า</h4>
                    <button type="button" class="close" @click="handleCancel()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card card-primary">
                        <div class="card-body">
                            <div class="row">
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">รหัสผู้ใช้</span><br>
                                    <span>{{ data.employeeCode }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">สิทธิ์การใช้งาน</span><br>
                                    <span>{{ data.roleName }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">รหัสพนักงาน</span><br>
                                    <span>{{ data.employeeUsername ? data.employeeUsername:'-' }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">ชื่อ</span><br>
                                    <span>{{ data.employeeFirstname ? data.employeeFirstname:'-' }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">นามสกุล</span><br>
                                    <span>{{ data.employeeLastname ? `${ data.employeeLastname}`:'-' }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-6">
                                    <span class="inActiveText">โทรศัพท์</span><br>
                                    <span>{{ data.employeePhone ? data.employeePhone:'-'  }}</span>
                                </div>
                                <div style="padding-left: 3%" class="col-sm-12">
                                    <span class="inActiveText">อีเมล</span><br>
                                    <span>{{ data.employeeEmail ? data.employeeEmail:'-' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-2 bg-secondary bg-light">
                        <label>การเข้าถึงเมนูการใช้งาน</label>
                    </div>
                    <table class="table table-sm">
                        <thead>
                        <tr>
                            <th width="30%">เมนู</th>
                            <th class="text-center" width="14%">ดู</th>
                            <th class="text-center" width="14%">เพิ่ม</th>
                            <th class="text-center" width="14%">แก้ไข</th>
                            <th class="text-center" width="14%">ลบ</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,index) in permissionData" :key="item['menuSeq']">
                            <td>{{ item['menuName'] }}</td>
                            <td class="text-center"><i :class="handleTrueResult(item['epView'])"></i></td>
                            <td class="text-center"><i :class="handleTrueResult(item['epCreate'])"></i></td>
                            <td class="text-center"><i :class="handleTrueResult(item['epUpdate'])"></i></td>
                            <td class="text-center"><i :class="handleTrueResult(item['epDelete'])"></i></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default float-right" @click="handleCancel()">ยกเลิก</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import masterStore from "@/store/MasterStore";
import loginStore from "@/store/loginStore";

export default {
    name: "EmployeeViewModal",
    props: ['item'],
    data() {
        return {
            token:loginStore.getters.token,
            empForm: [],
            permissionData:[],
            data: [],
        }
    },
    watch: {
        item: {
            handler() {
                try {
                    if (this.item) {
                        this.data = this.item
                        this.permissionData = this.permissionSet(this.item.employeePermissions ? this.item.employeePermissions : [])
                    }
                } catch (e) {
                    throw new Error(e);
                }
            },
            deep: true
        }
    },
    async mounted() {
        try {
            await this.activeMaster()
        } catch (e) {
            throw new Error(e);
        }
    },
    methods:{
        handleTrueResult (data){
            return data ? 'fa fa-check approveText': 'fa fa-times rejectText'
        },
        async activeMaster() {
            try {
                const payload = {
                    token: this.token
                }
                await masterStore.dispatch('empForm', payload)
                this.empForm = masterStore.getters.empForm
            } catch (e) {
                throw new Error(e);
            }
        },
        permissionSet(data) {
            try {
                const menuData = this.empForm['menuLst'].filter(c => c.menuHead !== 0)
                let allMenu = []
                if (data) {
                    const dataGetting = data
                    allMenu = menuData.map(item => ({menuSeq: item['seq'], menuName: item['menuName']}));
                    const permissions = dataGetting.map(obj => ({
                        menuSeq: obj.menuSeq,
                        epView: obj.epView,
                        epUpdate: obj.epUpdate,
                        epCreate: obj.epCreate,
                        epDelete: obj.epDelete
                    }));

                    return allMenu.map(menu => {
                        const permission = permissions.find(p => p.menuSeq === menu.menuSeq);
                        return {
                            ...menu,
                            epView: permission ? (permission.epView === "1") : false,
                            epUpdate: permission ? (permission.epUpdate === "1") : false,
                            epCreate: permission ? (permission.epCreate === "1") : false,
                            epDelete: permission ? (permission.epDelete === "1") : false
                        };
                    })
                }
            } catch (e) {
                throw new Error(e);
            }
        },
        handleCheckedAll(data) {
            try {
                return data.epView && data.epCreate && data.epUpdate && data.epDelete;
            } catch (e) {
                throw new Error(e);
            }
        },
        handleModal() {
            try {
                $(this.$refs.employeeViewModal).modal('show');
            } catch (e) {
                throw new Error(e);
            }
        },
      handleCancel() {
        $(this.$refs.employeeViewModal).modal('hide');
        this.$emit('view-submitted')
      }
    }
}
</script>

<style scoped>

</style>