<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <Loading v-if="isLoading" />
        <div class="row mb-3">
          <div class="col-sm-12">
            <Header/>
          </div>
          <div class="col-sm-6">
            <h1 class="m-0">ข้อมูลวัตถุดิบ</h1>
          </div>
          <div class="col-sm-6">
            <button :disabled="permission.epCreate === '0'" type="button" @click="handleRowClick({}, 0)"
              class="btn btn-success float-right">
              เพิ่มวัตถุดิบ
            </button>
            <material-modal @form-submitted="getData(currentPage)" ref="materialModal" :item="rowOnClick" />
            <material-view-modal @form-submitted="getData(currentPage)" ref="materialViewModal" :item="rowOnClick" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>ชื่อวัตถุดิบ</label>
                        <input v-model="formData.materialName" type="text" class="form-control" placeholder="วัตถุดิบ">
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>Supplier</label>
                        <input v-model="formData.supplier" type="text" class="form-control" placeholder="Supplier">
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>ประเภท</label>
                        <select class="select form-control" v-model="formData.materialType">
                          <option value="">ประเภทวัตถุดิบ</option>
                          <option v-for="(item) in selectMaterialType" :value="item.mtCode">{{ item['mtName'] }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>สถานะ</label>
                        <select class="select form-control" v-model="formData.materialStatus">
                          <option value="" disabled selected hidden>เลือกสถานะ</option>
                          <option v-for="(item) in selectStatus" :value="item.value">{{ item['text'] }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-sm-2 align-self-end">
                      <button @click.prevent="getData(1)" class="btn btn-primary btn-block" type="submit">ค้นหา</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th style="width: 10%"></th>
                      <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('materialCode')">รหัสวัตถุดิบ
                        <i :class="`fa fa-arrow-${sortTable.column === 'materialCode' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" style="width: 30%" @click.prevent="handleSortData('materialName')">ชื่อวัตถุดิบ
                        <i :class="`fa fa-arrow-${sortTable.column === 'materialName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('supplier')">Supplier
                        <i :class="`fa fa-arrow-${sortTable.column === 'supplier' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('mtName')">ประเภท
                        <i :class="`fa fa-arrow-${sortTable.column === 'mtName' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('materialPrice')">ราคาต่อหน่วย
                        <i :class="`fa fa-arrow-${sortTable.column === 'materialPrice' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('materialStatus')">สถานะ
                        <i :class="`fa fa-arrow-${sortTable.column === 'materialStatus' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                      <th class="on-pointer" style="width: 10%" @click.prevent="handleSortData('materialDetail')">ข้อมูลเพิ่มเติม
                        <i :class="`fa fa-arrow-${sortTable.column === 'materialDetail' ? this.sortTable.icon:'up'} arrow-fix-size`"/>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="rowHovered" v-for="(item) in tblData" @click="handleRowClick(item, 1)">
                      <td>
                        <button :disabled="permission.epDelete === '0'" type="button" @click="doDelete(item, $event)"
                          class="btn btn-danger">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{{ item['materialCode'] }}</td>
                      <td>{{ item['materialName'] }}</td>
                      <td>{{ item['supplier'] }}</td>
                      <td>{{ item['mtName'] }}</td>
                      <td>{{ item['materialPrice'] }}</td>
                      <td :class="item['materialStatus'] === '1' ? 'activeTextToggle' : 'inActiveTextToggle'">{{
                        item['materialStatus'] === '1' ? "เปิดใช้งาน" : "ปิดใช้งาน" }}</td>
                      <td>{{ item['materialDetail'] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1 ถึง 10 ของ
                  {{ allShow }}
                  ทั้งหมด
                </div>
              </div>
              <div class="col-sm-12 col-md-7 ">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination">
                    <li class="paginate_button page-item previous">
                      <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                        ก่อนหน้า
                      </button>
                    </li>
                    <li class="paginate_button page-item" :class="{ active: page === currentPage }" v-for="page in pages"
                      :key="page">
                      <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                    </li>
                    <li class="paginate_button page-item previous">
                      <button @click="nextPage()" :disabled="currentPage >= allPage[8]" class="page-link">ถัดไป
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>

<script>
import Content from "@/components/Content.vue";
import loginStore from "@/store/loginStore";
import materialStore from "@/store/materialStore";
import MaterialModal from "@/components/material/MaterialModal.vue";
import masterStore from "@/store/MasterStore";
import MaterialViewModal from "@/components/material/MaterialViewModal.vue";
import Loading from "@/components/loading/Loading.vue";
import Header from "@/components/header/Header.vue";

export default {
  name: "Material",
  components: {Header, Loading, MaterialViewModal, MaterialModal, Content },
  data() {
    try {
      return {
        sortTable:{column:null,sort:'asc',icon:'up'},
        isLoading: false,
        tblData: null,
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        showModal: false,
        rowOnClick: {},
        selectMaterialType: null,
        selectStatus: null,
        token: null,
        permission: loginStore.getters.pagePermission,
        formData: {
          materialName: '',
          supplier: '',
          materialType: '',
          materialStatus: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  async mounted() {
    try {
      this.token = loginStore.getters.token
      await this.getData(1)
      this.getSelectMaterialType()
      this.getStatus()
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage; // เพิ่มบรรทัดนี้ เพื่อรับค่าหน้าปัจจุบัน

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({ length: totalPages }, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  methods: {
    handleSortData(column) {
      this.sortTable.column = column
      this.sortTable.sort =  this.sortTable.sort === 'desc'? 'asc':"desc"
      this.sortTable.icon = this.sortTable.sort === 'desc'? 'down':"up"
      const data = this.tblData
      const actionData = []
      for(let item of data){
        if(typeof item[column] === "number"){
          item[column] = item[column].toString()
        }
        if(item[column] === null){
          item[column] = ''
        }
        actionData.push(item)
      }
      this.tblData = actionData.sort((a, b) => {
        if (this.sortTable.sort === "desc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      })
    },
    async handleRowClick(data, type) {
      try {
        await this.getData(this.currentPage)
        data['type'] = type
        if (type === 1) {
          const dataByID = await this.getDataById(data.seq)
          const dataModal = dataByID[0]
          if (dataByID.length > 0) {
            dataModal.type = type
            this.rowOnClick = dataModal
            if (this.permission.epUpdate === '1' && type === 1) {
              this.$refs.materialModal.handleModal();
            } else {
              this.$refs.materialViewModal.handleModal();
            }
          } else {
            await this.getData(this.currentPage)
          }
        } else {
          this.rowOnClick = data
          if (this.permission.epCreate === '1' && type === 0) {
            this.$refs.materialModal.handleModal();
          } else {
            await this.getData(this.currentPage)
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData(page) {
      try {
        this.isLoading = true
        const payload = {
          token: this.token,
          page: page,
          materialName: this.formData.materialName,
          supplier: this.formData.supplier,
          materialType: this.formData.materialType,
          materialStatus: this.formData.materialStatus || "1",
        }
        await materialStore.dispatch('getDataTbl', payload)
        this.tblData = materialStore.getters.tblData['materials']
        this.pageSize = materialStore.getters.tblData['limit']
        this.allShow = materialStore.getters.tblData['count']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getDataById(seq) {
      try {
        this.isLoading = true
        const payload = {
          sequence: seq,
          token: this.token,
          materialStatus: this.formData.materialStatus || "1",
        }
        await materialStore.dispatch('getDataTbl', payload)
        this.isLoading = false
        return materialStore.getters.tblData['materials']
      } catch (e) {
        this.isLoading = false
        throw new Error(e);
      }
    },
    async getSelectMaterialType() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('materialSearch', payload)
        this.selectMaterialType = masterStore.getters.materialSearch
      } catch (e) {
        throw new Error(e);
      }
    },
    async getStatus() {
      try {
        this.selectStatus = masterStore.getters.statusDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    async doDelete(data, event) {
      try {
        event.stopPropagation();
        const payload = {
          token: loginStore.getters.token,
          seq: data.seq
        }
        const resultConfirm = await this.confirmDeleteFunction(payload)
        const result = materialStore.getters.saveResult
        if (result === true && resultConfirm === true) {
          this.successFunction()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async confirmDeleteFunction(payload) {
      try {
        const result = await this.$swal.fire({
          title: 'ต้องการลบข้อมูลหรือไม่',
          text: 'กดยืนยันเพื่อทำการลบข้อมูล',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })
        if (result.isConfirmed) {
          await materialStore.dispatch('deleteData', payload)
          return true
        } else {
          return false
        }

      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'ลบข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage);
        })
      } catch (e) {
        throw new Error(e);
      }
    },
  }
}
</script>
<style scoped></style>
