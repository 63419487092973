<template>
  <Content>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-3">
          <div class="col-sm-12">
            <Header/>
          </div>
          <div class="col-sm-6">
            <h1 class="m-0">งานรอจ่าย</h1>
          </div>
          <div class="col-sm-6">
            <roleStore-modal @form-submitted="getData(currentPage, 10)" ref="roleStoreModal" :item="rowOnClick" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card card-primary">
              <form>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>หมายเลข</label>
                        <input v-model="formData.woNum" type="text" class="form-control" placeholder="ค้นหาหมายเลข">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>วันที่เริ่มต้นสั่งงาน</label>
                        <input v-model="formData.startDate" type="date" class="form-control" placeholder="dd/mm/yyyy">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>วันที่สิ้นสุดงาน</label>
                        <input v-model="formData.endDate" type="date" class="form-control" placeholder="dd/mm/yyyy">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>ชื่อเซลล์</label>
                        <input v-model="formData.empName" type="text" class="form-control" placeholder="ค้นหาชื่อเซลล์">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>ชื่องาน</label>
                        <input v-model="formData.woName" type="text" class="form-control" placeholder="ค้นหาชื่องาน">
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>ชื่อลูกค้า</label>
                        <input v-model="formData.cusName" type="text" class="form-control" placeholder="ค้นหาชื่อลูกค้า">
                      </div>
                    </div>
                    <div class="form-group col-sm-1 align-self-end">
                      <button @click.prevent="getData(1, 10)" class="btn btn-primary btn-block"
                        type="submit">ค้นหา</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body p-0">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th style="width: 10%"></th>
                      <th>เครื่อง</th>
                      <th>หมายเลข</th>
                      <th>สถานะ</th>
                      <th>ชื่อลูกค้า</th>
                      <th>ชื่องาน</th>
                      <th>ขนาดงาน</th>
                      <th>กระดาษ</th>
                      <th>วัตถุดิบ</th>
                      <th>วัตถุดิบจริง</th>
                      <th>เซลล์</th>
                      <th>วันที่เปิด</th>
                      <th>จำนวน</th>
                      <th>เริ่ม(ประมาณ)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="rowHovered" v-for="(item) in tblData" @click="handleRowClick(item, 1)">
                      <td>
                        <button :disabled="permission.epDelete === '0'" type="button" @click="handleRowClick(item, 0, $event)"
                          class="btn planEachTaskBunton mr-1">
                          <i class="fa fa-edit">จ่ายออก</i>
                        </button>
                      </td>
                      <td>{{ item['machineCode'] }}</td>
                      <td>{{ item['woPrnumber'] }}</td>
                      <td>{{ item['workOrderStatus'] }}</td>
                      <td>{{ item['woCustomerName'] }}</td>
                      <td>{{ item['woWcode'] }}</td>
                      <td>{{ item['woMole'] }}</td>
                      <td>{{ item['mtSeq'] }}</td>
                      <td>{{ item['mtSeq'] }}</td>
                      <td>{{ item['mtSeq'] }}</td>
                      <td>{{ item['woEmployeeName'] }}</td>
                      <td>{{ item['createDate'] }}</td>
                      <td>{{ item['createDate'] }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">แสดง 1 ถึง {{ allShow <=
                  10 ? allShow : 10 }} ของ {{ allShow }} ทั้งหมด </div>
                </div>
                <div class="col-sm-12 col-md-7 ">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination">
                      <li class="paginate_button page-item previous">
                        <button @click="prevPage()" :disabled="currentPage <= 1" class="page-link">
                          ก่อนหน้า
                        </button>
                      </li>
                      <li class="paginate_button page-item" :class="{ active: page === currentPage }"
                        v-for="page in pages" :key="page">
                        <button @click="goToPage(page)" class="page-link">{{ page }}</button>
                      </li>
                      <li class="paginate_button page-item previous">
                        <button @click="nextPage()" :disabled="currentPage >= allPage[8]" class="page-link">ถัดไป
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Content>
</template>
    

<script>
import Content from "@/components/Content.vue";
import roleStore from "@/store/roleStore";
import loginStore from "@/store/loginStore";
import RoleStoreModal from "@/components/roleStore/RoleStoreModal.vue";
import masterStore from "@/store/MasterStore";
import moment from 'moment'
import Header from "@/components/header/Header.vue";

export default {
  name: "RoleStore",
  components: {Header, RoleStoreModal, Content },
  data() {
    try {
      return {
        tblData: null,
        pageSize: 0,
        allPage: 0,
        currentPage: 1,
        allShow: 0,
        showModal: false,
        permission: loginStore.getters.pagePermission,
        rowOnClick: {},
        selectHolidayYear: null,
        selectStatus: null,
        token: null,
        formData: {
          woNum: '',
          startDate: '',
          endDate: '',
          empName: '',
          woName: '',
          cusName: '',
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    try {
      this.token = loginStore.getters.token
      this.getData(1, 10)
      this.getSelectHolidayYear()
      this.getStatus()
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    pages() {
      try {
        const totalPages = Math.ceil(this.allShow / this.pageSize);
        const maxPagesToShow = 9;
        const currentPage = this.currentPage;

        if (totalPages <= maxPagesToShow) {
          const result = Array.from({ length: totalPages }, (_, i) => i + 1);
          this.allPage = result;
          return result;
        } else {
          const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
          let startPage;
          let endPage;

          if (currentPage <= halfMaxPagesToShow + 1) {
            startPage = 1;
            endPage = maxPagesToShow;
          } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
          } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
          }

          const result = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
          this.allPage = result;
          return result;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  methods: {
    handleRowClick(data, type, event) {
      try {
        event.stopPropagation();
        data['type'] = type
        console.log(data);
        this.rowOnClick = data
        if (this.permission.epCreate === '1' && type === 0) {
          this.$refs.roleStoreModal.handleModal();
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async getData(page) {
      try {
        const payload = {
          token: this.token,
          page: page,
          holidayName: this.formData.holidayName,
          holidayDate: this.convertDateFormat(this.formData.holidayDate),
          holidayYear: this.formData.holidayYear === '' ? this.getYear() : this.formData.holidayYear,
          holidayStatus: this.formData.holidayStatus
        }
        await roleStore.dispatch('getDataTbl', payload)
        this.tblData = roleStore.getters.tblData['storeLst']
        this.pageSize = roleStore.getters.tblData['limit']
        this.allShow = roleStore.getters.tblData['count']
        this.getSelectHolidayYear();
      } catch (e) {
        throw new Error(e);
      }
    },
    async getSelectHolidayYear() {
      try {
        const payload = {
          token: this.token
        }
        await masterStore.dispatch('holidaySearch', payload)
        this.selectHolidayYear = masterStore.getters.holidaySearch
      } catch (e) {
        throw new Error(e);
      }
    },
    async getStatus() {
      try {
        this.selectStatus = masterStore.getters.statusDropdown
      } catch (e) {
        throw new Error(e);
      }
    },
    prevPage() {
      try {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    nextPage() {
      try {
        if (this.currentPage < this.allPage[8]) {
          this.currentPage++;
          this.getData(this.currentPage, 10)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    goToPage(page) {
      try {
        this.currentPage = page;
        this.getData(this.currentPage, 10)
      } catch (e) {
        throw new Error(e);
      }
    },
    async doDelete(data, event) {
      try {
        event.stopPropagation();
        const payload = {
          token: loginStore.getters.token,
          seq: data.seq
        }
        await this.confirmDeleteFunction(payload)
        const result = holidayStore.getters.saveResult
        if (result) {
          this.successFunction()
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async confirmDeleteFunction(payload) {
      try {
        const result = await this.$swal.fire({
          title: 'ต้องการลบข้อมูลหรือไม่',
          text: 'กดยืนยันเพื่อทำการลบข้อมูล',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก'
        })
        if (result.isConfirmed) {
          await holidayStore.dispatch('deleteData', payload)
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'ลบข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกลบเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.getData(this.currentPage)
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    convertDateFormat(item) {
      try {
        if (item) {
          return moment(item, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        return '';
      } catch (e) {
        throw new Error(e);
      }
    },
    getYear() {
      try {
        const now = new Date();
        const year = now.getFullYear();
        return year;
      } catch (e) {
        throw new Error(e);
      }
    }
  }
}
</script>

<style scoped></style>