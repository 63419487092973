import {createApp, Plugin} from 'vue'
import App from './App.vue'
import router from './router'
import sidebarMenuStore from './store/sidebarMenuStore'
import loginStore from './store/loginStore'
import VueSweetalert2 from 'vue-sweetalert2';
import Content from "@/components/Content.vue";
import FullCalendar from '@fullcalendar/vue3'

// Set the license key globally
const fullCalendarOptions = {
    licenseKey: 'GPL-My-Project-Is-Open-Source'
};

const store = {
    login: loginStore,
    sidebarMenu: sidebarMenuStore
}

const plugin: Plugin = {
    install(app) {
        app.config.globalProperties.$store = store
    }
}
const app = createApp(App)
app.component('Content',Content)
    .use(plugin)
    .use(router)
    .use(VueSweetalert2)
    .component('FullCalendar', FullCalendar)
    .provide('FullCalendar', fullCalendarOptions)
    .mount('#app')

